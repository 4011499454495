import { useEffect, useMemo, useState } from "react";
import Loader from "../../elements/Loader";
import DocumentGallery from "../DocsUpload/DocumentGallery";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Formsy from "formsy-react";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF, getSavedDocuments } from "../../../store/action/allAction";
import { useApolloClient } from "@apollo/client";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from "../../../config/constants";
import LeadRemarkHistory from "../lead/component/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { executeGraphQLMutation } from "../../../services/refinance/executeGraphQLQuery";
import { toast } from "react-toastify";
import { SAVE_INCENTIVE_STATUS } from "../../../services/refinance/refinance-computation.gql";

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
let maxTime = now.setHours(currhour, currmin, 0);

const RefinanceIncentivePaid = (prop) => {
    let { lead_id } = useParams();
    const navigate = useNavigate();
    const { leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        lead,
        leadDetail: lead?.ucrfLeadDetail || {},
        remarksHistory: lead.remarksHistory || [],
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userEditAccess, setUserEditAccess] = useState(true);
    const [disableSave, setDisableSave] = useState(true);
    const [incentivePaid, setIncentivePaid] = useState({});
    const [basicDetails, setBasicDetails] = useState({});
    const [showremarkform, setShowremarkform] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [isDateDisabled, setIsDateDisabled] = useState(true);
    const [dataFilled, setDataFilled] = useState(false);

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    const navigateToNext = () => {
        navigate(`/refinance/lead-detail/incentive-received/${lead_id}`);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            let lead_id = leadDetail?.lead_id;
            let variables = {
                incentiveStatusData: { created_date: incentivePaid.created_date, lead_id: lead_id, incentive_type: "incentive_paid" },
            };
            const response = await executeGraphQLMutation(SAVE_INCENTIVE_STATUS, variables, client);
            if (response?.data) {
                toast.success(response.data.saveIncentiveDataStatus.message);
                dispatch(getUCRFLeadDetail(lead_id, client));
                dispatch(getUCRFFormFillStatus({ lead_id }, client));
                dispatch(getSavedDocuments(lead_id, client));
                navigateToNext();
            }
        } catch (error) {
            toast.error(error.toString());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setUserEditAccess(IS_USER_ACCESS_PAGE("refinanceIncentivePaid", "ucrf-incentive-paid")?.is_edit_access);
        if (
            leadDetail?.computation_details?.pass_through?.dealer_incentive_amount === "yes" ||
            ["individual"].includes(leadDetail?.basic_details?.source_type)
        ) {
            setIsDateDisabled(false);
            setDisableSave(false);
        } else {
            setIsDateDisabled(true);
        }
        setDataFilled(leadDetail?.incentive_paid?.data_filled === 1);
        if (leadDetail?.incentive_paid?.lead_id) {
            setIncentivePaid(leadDetail.incentive_paid);
            setDisableSave(false);
        }
        let basicDetails = { ...leadDetail.basic_details };
        setBasicDetails(basicDetails);
        dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: "WEB" }, client));
    }, [leadDetail]);

    // const isSubmitDisabled = useMemo(() => {
    //     return basicDetails.refinance_sub_status === "16" ? true : false;
    // }, [basicDetails]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setIncentivePaid((currentValue) => ({
                ...currentValue,
                created_date: date,
            }));
        }
    };

    const sectionInfo = { section: "Incentive Paid", sub_section: "" };

    return (
        <>
            <div className="image-form-outer">
                {loading ? <Loader /> : ""}
                <div className="image-tab-panel">
                    <DocumentGallery documentCategoryList={documentCategoryList} />
                </div>
                <div className="form-tab-right-panel">
                    <div className="lead-detail-from-outer">
                        <div className={" switch-toggle"}>
                            <div className="lead-form-heading">
                                <h2>Incentive Paid</h2>
                            </div>
                            <div className="soft-approvel-sec">
                                <Formsy className="lead-form-filed radio-btn" autoComplete="off">
                                    <div className="row">
                                        <fieldset class="form-filed col-md-12 ">
                                            <div className="material">
                                                <FormsyDatePicker
                                                    col="col-md-12"
                                                    name={"incentive_paid_date"}
                                                    selectedDate={incentivePaid?.created_date && new Date(incentivePaid?.created_date)}
                                                    value={incentivePaid?.created_date && new Date(incentivePaid?.created_date)}
                                                    placeholder={"Incentive Paid Date"}
                                                    onDateSelect={(e) => handleDateChange(e, "incentive_paid_date")}
                                                    maxDate={new Date()}
                                                    minTime={now.setHours(0, 0, 0)}
                                                    maxTime={maxTime}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    showTimeSelect={true}
                                                    required={true}
                                                    readOnly={isDateDisabled || dataFilled}
                                                    disabled={isDateDisabled || dataFilled}
                                                    hasError={""}
                                                    clearDate={false}
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </Formsy>
                            </div>
                        </div>
                        <div className="btn-save-remarks">
                            {(isDateDisabled || dataFilled) ? (
                                <button
                                    type="button"
                                    disabled={!userEditAccess || CHECK_ASSIGN_USER(leadDetail.assigned_to) || disableSave}
                                    className="btn-primary"
                                    onClick={() => navigateToNext()}
                                >
                                    Next
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    disabled={
                                        !userEditAccess || CHECK_ASSIGN_USER(leadDetail.assigned_to) || disableSave || dataFilled || !incentivePaid.created_date
                                    }
                                    onClick={() => {
                                        handleSave();
                                    }}
                                >
                                    Save
                                </button>
                            )}

                            <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory
                            showremarkform={showremarkform}
                            markAsFreezed={getContext.markAsFreezedNewFlow}
                            accessCondition={userEditAccess}
                            sectionInfo={sectionInfo}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default RefinanceIncentivePaid;
