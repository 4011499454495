import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coBorrowerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_COBORROWER_EMPLOYMENT_DETAILS } from '../../../services/coborrower';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields["EMPLOYMENT_DETAILS"];

const EmploymentDetails = (props) => {
    const [employmentDetails, setEmploymentDetails] = useState({});
    const [orgemploymentDetails, setOrgEmploymentDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead, remarksHistory} = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || []
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const context = useOutletContext();
    const documentCategoryList = context.docList || []
    const viberCoborrowerData = context?.viberData?.get_viber_column?.co_borrower || [];
    const { showAiIcon } = useAiIcon(employmentDetails, viberCoborrowerData);
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "co-borrower-employment-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;
    
    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.co_borrower && lead.leadDetail.co_borrower.employment_details) {
        let originalData = { ...lead.leadDetail.co_borrower.employment_details };
        let updatedData = { ...originalData };

        if (viberCoborrowerData) {
          Object.keys(viberCoborrowerData).forEach(key => {
            if (!updatedData[key] && viberCoborrowerData[key]) {
              updatedData[key] = viberCoborrowerData[key];
            }
          });
        }

        setOrgEmploymentDetails(originalData);
        setEmploymentDetails(updatedData);
      }
    }, [lead]);

    let ALL_OPTIONS = masterdata?.data;
  
    const handleInputChange = event => {
        if (event.target.id) {
            setEmploymentDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setEmploymentDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setEmploymentDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }
    
    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let coborrower_id = lead?.leadDetail?.co_borrower?.co_borrower_id;
      let reqBody = {
        lead_id: lead_id,
        co_borrower_id: coborrower_id,
        cb_employer_name: employmentDetails.cb_employer_name,
        cb_business_address: employmentDetails.cb_business_address,
        cb_company_phone: employmentDetails.cb_company_phone,
        cb_designation: employmentDetails.cb_designation,
        cb_year_with_company: employmentDetails.cb_year_with_company,
        cb_legal_form: employmentDetails.cb_legal_form,
        cb_industry: employmentDetails.cb_industry,
        cb_gross_monthly_income: parseFloat(employmentDetails.cb_gross_monthly_income),
        updated_by: user_id || 0
      }

      Object.keys(reqBody).forEach(key => {
        if (reqBody[key] === "") reqBody[key] = null;
      });

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'co_borrower',
        changed_object: {
          cb_employer_name: employmentDetails.cb_employer_name?.toString() || null,
          cb_business_address: employmentDetails.cb_business_address?.toString() || null,
          cb_company_phone: employmentDetails.cb_company_phone?.toString() || null,
          cb_designation: employmentDetails.cb_designation?.toString() || null,
          cb_year_with_company: employmentDetails.cb_year_with_company?.toString() || null,
          cb_legal_form: employmentDetails.cb_legal_form?.toString() || null,
          cb_industry: employmentDetails.cb_industry?.toString() || null,
          cb_gross_monthly_income: employmentDetails.cb_gross_monthly_income?.toString() || null
        }
      };

      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      let mutation = SAVE_COBORROWER_EMPLOYMENT_DETAILS, 
          variables = { cb_empDetailInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_cb_employment_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext()
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/co-borrower-details/bank-details/${lead_id}`);
    }


    let EmploymentDetailsForm = FORM_FIELDS;

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = {section : "Co-borrower details" , sub_section : "Employment details"}
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;        
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Co-Borrower Details</span>
              <h2>Employment Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                
                {EmploymentDetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(employmentDetails && employmentDetails[field.name])}
                        placeholder=" "
                        {...(field.type === "number-format") ? {
                          thousandsGroupStyle:"thousand",
                          thousandSeparator: ',',
                          decimalSeparator: '.'
                        }: {}}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        employmentDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        readOnly={field.readOnly || (orgemploymentDetails?.[field.name] && isSubStatus3Marked) ? true : false}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (employmentDetails[field.name])),
                            isDisabled: field.readOnly || (orgemploymentDetails?.[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        value={employmentDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
                {context.markAsFreezedNewFlow || !allowSubmit || isSubmitDisabled || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span> :
                <button aria-label="save next" type="submit" className="btn-primary">
                  Save & Next
                </button>}
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezedNewFlow} accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default EmploymentDetails;