import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { executeGraphQLQuery as  executeGraphQLQueryRefinance} from "../../services/refinance/executeGraphQLQuery";
import { LEADS_REMARK_QUERY, LEAD_DETAIL_QUERY } from "../../services/leads.gql";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { SAVE_REMARKS } from "../../services/leadremarks.gql";
import { GET_ACCESS_FEATURES } from "../../services/role.gql";
import { GET_MASTER_DATA, GET_MASTER_DATA_MMV } from "../../services/masterdata.gql";
import { GET_UCRF_MENU_LIST, GET_UCRF_LEAD_DETAIL, GET_UCRF_FORM_FILL_STATUS, GET_MASTER_DATA_UCRF, GET_SAVED_DOCUMENTS, LEADS_REMARK_QUERY_REFINANCE, SAVE_REMARKS_UCRF, WEB_DOC_CONFIG_LIST_REFINANCE } from "../../services/refinance/leads.gql";

export const getLoanDetail = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQuery(LEAD_DETAIL_QUERY(postData),client).then((response) => {
				// console.log(response)
				if (response && response.data) {
					let data = response.data.get_lead_detail[0];
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success({}));
             })
             .catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_LEAD_DETAIL", payload };
	}
}

export const getLeadRemarks = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQuery(LEADS_REMARK_QUERY(postData.lead_id),client).then((response) => {
				if (response && response.data && response.data.get_remarks) {
					let data = response.data.get_remarks;
					// console.log('data',data)
					if (data) {
						dispatch(success(data));
					}
				} else dispatch(success([]));
             })
             .catch((error) => {
				dispatch(success([]));
             });
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_LEAD_REMARKS_HISTORY", payload };
	}
}

export const saveLeadRemarks = (postData,client) => {
	return async (dispatch) => {
		try {
			const response = await executeGraphQLMutation(SAVE_REMARKS, postData ,client)
			if (response && response.data.save_remark && response.data.save_remark.lead_id ) {
				return {
					status: true,
				};
			} else
				return {
					status: false,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}
export const getMasterData = (client,vehicle_type=0) => {
	return async (dispatch) => {
		try {
			executeGraphQLQuery(GET_MASTER_DATA(vehicle_type), client).then(response => {
				// console.log(response.data.masterdata)
				if(response && response.data && response.data.masterdata) {
					dispatch(success(response.data.masterdata))
				} else dispatch(success({}));
			})
			.catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload) {
		// console.log('=========GET_MASTER_DATA', payload)
		return { type: "GET_MASTER_DATA", payload };
	}
}

export const getUserAccess = (postData, client) =>{
	return async (dispatch) => {
		try {
			let data;
			await executeGraphQLQuery(GET_ACCESS_FEATURES(postData),client).then((response) => {
				// console.log(response)
				if (response && response.data && response.data.get_user_access_features) {
					data = response.data.get_user_access_features;
				}
             })
             .catch((error) => {
				console.log(error);
             });

			if (data && data.length) {
				let formattedUserAccess = [];
				// if feature exist in multiple role for same user (formatting the features array)
				data && data.forEach(item => {
					let exist_index = formattedUserAccess.findIndex(v => (v.product_type === item.product_type && v.action === item.action));
					if (exist_index > -1) {
						// To save '1' if both 1 and 0 are present
						let newAccessType = Math.max(
							Number(item.access_type),
							Number(formattedUserAccess[exist_index]["access_type"])
						);
						formattedUserAccess[exist_index]["access_type"] = newAccessType ? newAccessType.toString() : '0'; 
					} else {
						formattedUserAccess.push(item);
					}
				})
				// console.log(formattedUserAccess)
				localStorage.setItem("userAccess", JSON.stringify(formattedUserAccess));
				return {
					status: true,
					response: "success",
					data:data
				};
			} else
				return {
					status: false,
					response: "success",
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

export const getMasterDataMMV = (client,vehicle_type=0) => {
	return async (dispatch) => {
		try {
			executeGraphQLQuery(GET_MASTER_DATA_MMV(vehicle_type), client).then(response => {
				if(response && response.data && response.data.masterdata) {
					dispatch(success(response.data.masterdata))
				} else dispatch(success({}));
			})
			.catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload) {
		// console.log('=========GET_MASTER_DATA', payload)
		return { type: "GET_MASTER_DATA_MMV", payload };
	}
}

export const getMasterDataMMVComputation = (client,vehicle_type=0) => {
	return async (dispatch) => {
		try {
			executeGraphQLQuery(GET_MASTER_DATA_MMV(vehicle_type), client).then(response => {
				if(response && response.data && response.data.masterdata) {
					dispatch(success(response.data.masterdata))
				} else dispatch(success({}));
			})
			.catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload) {
		return { type: "GET_MASTER_DATA_MMV_COMPUTATION", payload };
	}
}

export const getMasterDataUCRF = (client) => {
	return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(GET_MASTER_DATA_UCRF(), client).then(response => {
				if(response && response.data && response.data.masterConfig) {
					dispatch(success(response.data.masterConfig))
				} else dispatch(success({}));
			})
			.catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload) {
		return { type: "GET_MASTER_DATA_UCRF", payload };
	}
}

export const getUCRFLeadDetail = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(GET_UCRF_LEAD_DETAIL(postData),client).then((response) => {
				// console.log(response)
				if (response && response.data) {
					let data = response?.data?.getLeadDetail;
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success({}));
             })
             .catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_UCRF_LEAD_DETAIL", payload };
	}
}


export const getMenuList = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(GET_UCRF_MENU_LIST(postData),client).then((response) => {
				if (response && response.data) {
					let data = response?.data?.getNavigationMenu;
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success([]));
             })
             .catch((error) => {
				dispatch(success([]));
             });
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_UCRF_MENU_LIST", payload };
	}
}

export const getUCRFFormFillStatus = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(GET_UCRF_FORM_FILL_STATUS(postData),client).then((response) => {
				if (response && response.data) {
					let data = response?.data?.getFormFillStatus;
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success({}));
             })
             .catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_UCRF_FORM_FILL_STATUS", payload };
	}
}

export const getSavedDocuments = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(GET_SAVED_DOCUMENTS(postData),client).then((response) => {
				// console.log(response)
				if (response && response.data) {
					let data = response.data.getSavedDocuments
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success([]));
             })
             .catch((error) => {
				dispatch(success([]));
             });
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_LEAD_DOCS_UCRF", payload };
	}
}

export const getLeadRemarkUCRF = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(LEADS_REMARK_QUERY_REFINANCE(postData.lead_id),client).then((response) => {
				if (response && response.data && response.data.getRemarksList) {
					let data = response.data.getRemarksList;
					if (data) {
						dispatch(success(data));
					}
				} else dispatch(success([]));
             })
             .catch((error) => {
				dispatch(success([]));
             });
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_LEAD_REMARKS_HISTORY_UCRF", payload };
	}
}

export const saveLeadRemarksUCRF = (postData,client) => {
	return async (dispatch) => {
		try {
			const response = await executeGraphQLQueryRefinance(SAVE_REMARKS_UCRF, client,postData)
			if (response && response.data && response.data.saveRemarks && response.data.saveRemarks.message == 'success') {
				return {
					status: true,
				};
			} else
				return {
					status: false,
				};
		} catch (err) {
			return {
				status: false,
				response: err,
			};
		}
	};
}

export const webDocumentConfig = (postData,client) => {
    return async (dispatch) => {
		try {
			executeGraphQLQueryRefinance(WEB_DOC_CONFIG_LIST_REFINANCE(postData),client).then((response) => {
				if (response && response.data) {
					let data = response.data.webDocumentConfig
					if (data) {
						dispatch(success(data));
					}
				}
				else dispatch(success([]));
             })
             .catch((error) => {
				dispatch(success([]));
             });
		} catch (err) {
			dispatch(success([]));
		}
	};
    function success(payload) {
		return { type: "GET_DOCS_CONFIG_UCRF", payload };
	}
}