import React, { useState } from "react";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import DocIcon from "./DocIcon";
import Loader from "../../elements/Loader"
// Data for carousel
function CarouselSlide({ index, activeIndex, slide, document_list, filter_doc_child_list }) {
    let docname = '';
    if (slide.doc_id && document_list.length && filter_doc_child_list && filter_doc_child_list.length) docname = (slide.parent_doc_id) ? document_list.filter(v => v.id === slide.parent_doc_id).map(v => v.doclang.name)[0] + ' | ' + filter_doc_child_list.filter(v => v.id === +slide.doc_id).map(v => v.doclang.name)[0] : document_list.filter(v => v.id === +slide.doc_id).map(v => v.doclang.name)[0];
    return (
        <li className={index === activeIndex ? "carousel__slide carousel__slide--active" : "carousel__slide"}>
            <div className="carousel-slideimg pfd-img-height">
                {/* <span>{docname}</span> */}
                {(slide.ext === 'pdf' || slide.ext === 'PDF')
                    ? <a className="pdf-img" href={slide.doc_path} target="_blank" rel="noopener noreferrer"><i className="ic-pdf-file pdf-icons-normal-gallery"></i></a>
                    : <ReactPanZoom image={slide.doc_path} alt={docname} title={docname} />}
            </div>
        </li>
    );
}

// Carousel wrapper component
function GallaryCarousel({ isLoading, slides, document_list, getFileId, filter_doc_child_list }) {
    const [slider, setSlides] = useState({
        activeSlide: 0,
        filterFiles: []
    });

    const goToPrevSlide = () => {
        let slidesLength = slides.length;
        if (slider.activeSlide < 1) slider.activeSlide = slidesLength;
        --slider.activeSlide;
        setSlides({ ...slider, activeSlide: slider.activeSlide });
        let slideInfo = slides[slider.activeSlide];
        if (slideInfo && slideInfo.id && typeof getFileId === 'function') {
            getFileId(slideInfo.id, slideInfo.doc_id, slideInfo.parent_doc_id)
        }
    }

    const goToNextSlide = () => {
        let slidesLength = slides.length - 1;
        if (slider.activeSlide === slidesLength) slider.activeSlide = -1;
        let activeSlide = +slider.activeSlide + 1;
        setSlides({ ...slider, activeSlide: activeSlide })
        let slideInfo = slides[activeSlide];
        if (slideInfo && slideInfo.id && typeof getFileId === 'function') {
            getFileId(slideInfo.id, slideInfo.doc_id, slideInfo.parent_doc_id)
        }
    }
    return (
        <div className="carousel image-varification-slider">
            {isLoading ? <Loader /> : null}
            <ul className="carousel__slides">
                {slides && slides.map((slide, index) =>
                    <CarouselSlide
                        index={index}
                        slide={slide}
                        key={index}
                        activeIndex={slider.activeSlide}
                        document_list={document_list}
                        filter_doc_child_list={filter_doc_child_list}
                    />
                )}
            </ul>
            {slides && slides.length > 1 ?
                <div className="image-opt">
                    <DocIcon goToPrevSlide={goToPrevSlide} goToNextSlide={goToNextSlide} />
                </div> : ''}
        </div>
    )
}

export default GallaryCarousel;