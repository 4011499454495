import React from 'react';

const MAX_UPLOADS = 5;
const SimpleFileUpload = ({ disabled, financierId, attachments, label, handleChange }) => {
    const renderUploadButton = (disabled = false) => (
        <div className="more-file-upload doc-upload-btn">
            <input
                aria-label="img upload"
                type="file"
                name=""
                accept=".pdf,image/*"
                onChange={(e) => { handleChange(e, financierId, "additional_loan_info", "financier_approval_docs", "file_upload") }}
                disabled={disabled}
                required={false}
            />
            <button
                aria-label="img upload btn"
                type="button"
                className="btn-line"
                disabled={disabled}
            >
                <i className='ic-upload'></i> Upload
            </button>
        </div>
    );

    return (
        <div className="acco-doc-list">
            <ul>
                <li>
                    <div className='doc-count-txt'>
                        {attachments.length === MAX_UPLOADS && (
                            <span className="stepprogress"><i className="sprite ic-check"></i></span>
                        )}
                        {label}
                        <span className="lead-doc-count">{attachments.length} / {MAX_UPLOADS}</span>
                    </div>
                    {renderUploadButton(disabled || attachments.length >= MAX_UPLOADS)}
                </li>
            </ul>
        </div>
    );
};

export default SimpleFileUpload;