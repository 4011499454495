import FormsyInputField from "../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import Formsy from "formsy-react";

const RefinanceCarDetails = ({
    carDetails,
    allOptions,
    handleChange,
    vehicleTypeOptions,
    makeOptions,
    modelOptions,
    versionOptions,
    carDetailFromRef,
    handleSave,
    markAsFreezed,
}) => {
    return (
        <>
            <Formsy className="lead-form-filed" autoComplete="off" aria-label="lead form field personal detail" onSubmit={handleSave} ref={carDetailFromRef}>
                <div className="row">
                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"vehicle_usage"}
                            id={"vehicle_usage"}
                            inputProps={{
                                options: allOptions?.vehicle_usage?.length ? allOptions.vehicle_usage : [],
                                placeholder: "Vehicle Useage",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: allOptions?.vehicle_usage?.length
                                    ? allOptions.vehicle_usage.filter((item) => item.value === carDetails.vehicle_usage)
                                    : [],
                            }}
                            value={carDetails.vehicle_usage}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "vehicle_usage", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>
                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"vehicle_type"}
                            id={"vehicle_type"}
                            inputProps={{
                                options: vehicleTypeOptions?.length ? vehicleTypeOptions : [],
                                placeholder: "Vehicle Type",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: vehicleTypeOptions?.length
                                    ? vehicleTypeOptions.filter((item) => parseInt(item.value) === parseInt(carDetails.vehicle_type))
                                    : [],
                            }}
                            value={carDetails.vehicle_type}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "vehicle_type", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>
                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"make_id"}
                            id={"make_id"}
                            inputProps={{
                                options: makeOptions?.length ? makeOptions : [],
                                placeholder: "Brand",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: makeOptions?.length ? makeOptions.filter((item) => parseInt(item.value) === parseInt(carDetails.make_id)) : [],
                            }}
                            value={carDetails.make_id}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "make_id", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>

                    {parseInt(carDetails?.make_id) === 0 ? (
                        <fieldset class="form-filed col-md-6 ">
                            <FormsyInputField
                                id="make_name"
                                name="make_name"
                                type="text"
                                value={carDetails.make_name}
                                placeholder=""
                                label={"Others"}
                                onChange={(e) => {
                                    handleChange(e, "make_name", "input");
                                }}
                                validationError=""
                                required={true}
                                showAsterisk={true}
                                readOnly={markAsFreezed}
                            />
                        </fieldset>
                    ) : null}

                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"model_id"}
                            id={"model_id"}
                            inputProps={{
                                options: modelOptions?.length ? modelOptions : [],
                                placeholder: "Model",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: modelOptions?.length ? modelOptions.filter((item) => parseInt(item.value) === parseInt(carDetails.model_id)) : [],
                            }}
                            value={carDetails.model_id}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "model_id", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>

                    {parseInt(carDetails?.model_id) === 0 ? (
                        <fieldset class="form-filed col-md-6 ">
                            <FormsyInputField
                                id="model_name"
                                name="model_name"
                                type="text"
                                value={carDetails.model_name}
                                placeholder=""
                                label={"Others"}
                                onChange={(e) => {
                                    handleChange(e, "model_name", "input");
                                }}
                                validationError=""
                                required={true}
                                showAsterisk={true}
                                readOnly={markAsFreezed}
                            />
                        </fieldset>
                    ) : null}

                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"variant_id"}
                            id={"variant_id"}
                            inputProps={{
                                options: versionOptions?.length ? versionOptions : [],
                                placeholder: "Variant",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: versionOptions?.length ? versionOptions.filter((item) => parseInt(item.value) === parseInt(carDetails.variant_id)) : [],
                            }}
                            value={carDetails.variant_id}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "variant_id", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>

                    {parseInt(carDetails?.make_id) === 0 ? (
                        <fieldset class="form-filed col-md-6 ">
                            <FormsyInputField
                                id="variant_name"
                                name="variant_name"
                                type="text"
                                value={carDetails.variant_name}
                                placeholder=""
                                label={"Others"}
                                onChange={(e) => {
                                    handleChange(e, "variant_name", "input");
                                }}
                                validationError=""
                                required={true}
                                showAsterisk={true}
                                readOnly={markAsFreezed}
                            />
                        </fieldset>
                    ) : null}

                    <fieldset class="single-select col-md-6">
                        <FormsySelect
                            name={"make_year"}
                            id={"make_year"}
                            inputProps={{
                                options: allOptions?.make_year?.length ? allOptions.make_year : [],
                                placeholder: "Make Year",
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: allOptions?.make_year?.length
                                    ? allOptions.make_year.filter((item) => parseInt(item.value) === parseInt(carDetails?.make_year))
                                    : [],
                            }}
                            value={carDetails.make_year}
                            required={true}
                            showAsterisk={true}
                            onChange={(e) => {
                                handleChange(e, "make_year", "select");
                            }}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>

                    <fieldset class="form-filed col-md-6 ">
                        <FormsyInputField
                            id="vehicle_plate_number"
                            name="vehicle_plate_number"
                            type="text"
                            value={carDetails.vehicle_plate_number}
                            placeholder=""
                            label={"Plate Number"}
                            onChange={(e) => {
                                handleChange(e, "vehicle_plate_number", "input");
                            }}
                            validationError="Enter a valid plate number"
                            required={true}
                            showAsterisk={true}
                            readOnly={markAsFreezed}
                        />
                    </fieldset>
                </div>
            </Formsy>
        </>
    );
};

export default RefinanceCarDetails;
