import { useEffect, useState } from "react";
import Loader from "../../elements/Loader";
import DocumentGallery from "../DocsUpload/DocumentGallery";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Formsy from "formsy-react";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsyInputField from "../elements/FormsyInputField";
import { getLeadRemarkUCRF } from "../../../store/action/allAction";
import LeadRemarkHistory from "../lead/component/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { useApolloClient } from "@apollo/client";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";

const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
let maxTime = now.setHours(currhour, currmin, 0);
const MAX_DECIMAL = 14;

const RefinanceIncentiveReceived = (prop) => {
    const { leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        lead,
        leadDetail: lead?.ucrfLeadDetail || {},
        remarksHistory: lead.remarksHistory || [],
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [incentiveReceived, setIncentiveReceived] = useState({});
    const [showremarkform, setShowremarkform] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [userEditAccess, setUserEditAccess] = useState(true);

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        setUserEditAccess(IS_USER_ACCESS_PAGE("refinanceIncentivePaymentReceived", "ucrf-incentive-payment-received")?.is_edit_access);
        if (leadDetail?.incentive_received?.lead_id) {
            setIncentiveReceived(leadDetail.incentive_received);
        }
        dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: "WEB" }, client));
    }, [leadDetail]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = { section: "Incentive Received", sub_section: "" };

    return (
        <>
            <div className="image-form-outer">
                {loading ? <Loader /> : ""}
                <div className="image-tab-panel">
                    <DocumentGallery documentCategoryList={documentCategoryList} />
                </div>
                <div className="form-tab-right-panel">
                    <div className="lead-detail-from-outer">
                        <div className={" switch-toggle"}>
                            <div className="lead-form-heading">
                                <h2>Incentive Received </h2>
                            </div>
                            <div className="soft-approvel-sec">
                                <Formsy className="lead-form-filed radio-btn" autoComplete="off" onValid={() => ""} onInvalid={() => ""} onValidSubmit={""}>
                                    <div className="row">
                                        <fieldset class="form-filed col-md-12 ">
                                            <div className="material">
                                                <FormsyDatePicker
                                                    col="col-md-12"
                                                    name={"incentive_received_date"}
                                                    value={incentiveReceived.created_date}
                                                    placeholder={"Incentive Received from Financier Date"}
                                                    maxDate={new Date()}
                                                    minTime={now.setHours(0, 0, 0)}
                                                    maxTime={maxTime}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    showTimeSelect={true}
                                                    required={false}
                                                    readOnly={true}
                                                    disabled={true}
                                                    hasError={""}
                                                    clearDate={false}
                                                />
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-filed col-md-12 ">
                                            <FormsyInputField
                                                id="incentive_received_amount"
                                                name="incentive_received_amount"
                                                type="number-format"
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator=","
                                                decimalSeparator="."
                                                decimalScale={MAX_DECIMAL}
                                                value={incentiveReceived.amount}
                                                placeholder=" "
                                                label={"Incentive Received Amount"}
                                                validations={"ucrfMaxAmountLength"}
                                                validationError="Enter Valid Loan Amount"
                                                readOnly={true}
                                                required={false}
                                                showAsterisk={false}
                                                disabled={true}
                                            />
                                        </fieldset>
                                    </div>
                                </Formsy>
                            </div>
                        </div>
                        <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>
                            Remarks
                            <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                        </button>
                    </div>
                </div>
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory
                            showremarkform={showremarkform}
                            markAsFreezed={getContext.markAsFreezedNewFlow}
                            accessCondition={userEditAccess}
                            sectionInfo={sectionInfo}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default RefinanceIncentiveReceived;
