import { gql } from '@apollo/client';
export const LEAD_DOCS_REMARKS_QUERY = (lead_id, doc_id) => {
    return gql`{
        doc_remark_history(lead_id: ${lead_id}, doc_id: ${doc_id}, api_called_by: web){
        remark
        action_type
        doc_details {
          doclang {
            name
          }
        }
        parent_doc_details {
          doclang {
            name
          }
        }
        user_details {
          added_by_name
        }
        created_date
      }
    }
  `};
