export function populateMap(data, keyField) {
  const newMap = new Map();
  for (const item of data || []) {
    const key = String(item[keyField]);
    if (!newMap.has(key)) {
      newMap.set(key, []);
    }
    newMap.get(key).push(item);
  }
  for (const items of newMap.values()) {
    items.push({ id: "0", value: "0", label: "Other" });
  }
  return newMap;
}

// export const DEPENDENCIES = {
//     vehicle_usage: ["vehicle_type", "make_id", "model_id", "variant_id", "make_year", "vehicle_plate_number"],
//     vehicle_type: ["make_id", "model_id", "variant_id", "make_year", "vehicle_plate_number"],
//     make_id: ["model_id", "variant_id", "make_year", "vehicle_plate_number"],
//     model_id: ["variant_id", "make_year", "vehicle_plate_number"],
//     variant_id: ["make_year", "vehicle_plate_number"],
//     make_year: ["vehicle_plate_number"],
// };

export const DEPENDENCIES = {
  vehicle_usage: [
    "vehicle_type",
    "make_id",
    "model_id",
    "variant_id",
    "make_year",
    "vehicle_plate_number",
  ],
  vehicle_type: [
    "make_id",
    "model_id",
    "variant_id",
    "make_year",
    "vehicle_plate_number",
  ],
  make_id: ["model_id", "variant_id", "make_year", "vehicle_plate_number"],
  model_id: ["variant_id", "make_year", "vehicle_plate_number"],
  variant_id: ["make_year", "vehicle_plate_number"],
  make_year: ["vehicle_plate_number"],
};
export const MAX_UPLOADS = 5;

/**
 * Checks if all required car details are filled
 * @param {Object} carDetails - The car details object
 * @returns {boolean} - True if all required fields are filled, false otherwise
 */
export const isCarDetailsFilled = (carDetails) => {
  // console.log("====carDetails====", carDetails)
  const requiredFields = [
    "make_id",
    "model_id",
    "variant_id",
    "make_year",
    "tenure_in_month",
    "car_fmv",
    "vehicle_type",
    "vehicle_category"
  ];

  return requiredFields.every((field) => {
    const value = carDetails[field];
    return value !== null && value !== undefined && value !== "";
  });
};

export const ALL_OPTIONS = [];

/**
 * Recursively removes '__typename' key from all objects and arrays
 * @param {*} data - The input data (object, array, or primitive)
 * @returns {*} - The data with all '__typename' keys removed
 */
export const removeTypename = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => removeTypename(item));
  } else if (data !== null && typeof data === "object") {
    const newObj = {};
    for (const [key, value] of Object.entries(data)) {
      if (key !== "__typename") {
        newObj[key] = removeTypename(value);
      }
    }
    return newObj;
  }
  return data;
};

export const getInterestAndChattelAge = (data, financierId) => {
  const result = data
    .filter((item) => item.financier_id === financierId)
    .map((item) => {
      const payment = item.payment_list[0];
      return {
        interest_rate: parseFloat(payment.interest_rate_list[0].value),
        chattel_fee_age: parseFloat(payment.chattel_fee_age_list[0].value),
      };
    })[0];

  return result;
};

export const getInterestAndChattelAge2 = (data, financierId, index) => {
  const result = data
    .filter((item) => item.financier_id === financierId)
    .map((item) => {
      const payment = item.payment_list[index];
      return {
        interest_rate: parseFloat(payment.interest_rate),
        chattel_fee_age: parseFloat(payment.chattel_fee_age),
      };
    })[0];

  console.log("====getInterestAndChattelAge====", result);
  return result;
};

// Function to merge the new data into the original data
export const mergeData = (originalData, newData) => {
  newData.data.financier_quote_re_calculation.forEach((newFinancier) => {
    // Find the corresponding financier in the original data
    const financier = originalData.find(
      (f) => f.financier_id === newFinancier.financier_id
    );

    if (financier) {
      newFinancier.payment_list.forEach((newPayment) => {
        // Check if the quote_id exists in the existing payment_list
        const existingPaymentIndex = financier.payment_list.findIndex(
          (p) => p.quote_id === newPayment.quote_id
        );

        if (existingPaymentIndex > -1) {
          // Replace the existing payment if quote_id exists
          financier.payment_list[existingPaymentIndex] = newPayment;
        } else {
          // Append the new payment if quote_id does not exist
          financier.payment_list.push(newPayment);
        }
      });
    }
  });

  return originalData;
};

export const prepareSaveQuoteData = (data) => {
  console.log("===== prepareSaveQuoteData data=======", data);
  return data.map((financier) => ({
    financier_id: financier?.financier_id,
    financier_name: financier?.financier_name,
    min_percent_age: "20",
    max_percent_age: "100",
    max_fmv_in_car: financier?.max_fmv_in_car,
    percent_age: financier?.percent_age ?? "",
    financier_amount: financier?.financier_amount ?? "0",
    financier_variable_select: financier?.financier_variable_select ?? "0",
    payment_list: financier.payment_list.map((payment, index) => ({
      quote_id: payment?.quote_id,
      amount_financed: payment?.amount_financed,
      tenure: payment?.tenure,
      interest_rate: payment?.interest_rate,
      instalment_amount: payment?.instalment_amount,
      fmv_of_car: payment?.fmv_of_car,
      chattel_fee: payment?.chattel_fee,
      chattel_fee_age: payment?.chattel_fee_age,
      carmudi_incentive_age: payment?.carmudi_incentive_age,
      carmudi_incentive_amount: payment?.carmudi_incentive_amount,
      carmudi_top_up_age: payment?.carmudi_top_up_age,
      carmudi_top_up_amount: payment?.carmudi_top_up_amount,
      total_carmudi_incentive_age: payment?.total_carmudi_incentive_age,
      carmudi_incentive_tax_age: payment?.carmudi_incentive_tax_age,
      total_carmudi_incentive_net_of_tax: payment?.total_carmudi_incentive_net_of_tax,
        interest_rate_list: JSON.stringify(payment.interest_rate_list.map(rate => ({
          id: rate.id,
          label: `${rate.value}%`,
          value: rate.value
        }))),
        chattel_fee_age_list: JSON.stringify(payment.chattel_fee_age_list.map(fee => ({
          id: fee.id,
          label: `${fee.value}%`,
          value: fee.value
        }))),
      is_selected: payment?.is_selected,
    })),
  }));
};


export const prepareSaveQuoteData2 = (data) => {
  console.log("===== prepareSaveQuoteData data=======", data);
  return data.map((financier) => ({
    financier_id: financier?.financier_id,
    financier_name: financier?.financier_name,
    min_percent_age: "20",
    max_percent_age: "100",
    max_fmv_in_car: financier?.max_fmv_in_car,
    percent_age: financier?.percent_age ?? "",
    financier_amount: financier?.financier_amount ?? "0",
    financier_variable_select: financier?.financier_variable_select ?? "0",
    payment_list: financier.payment_list
      .filter((payment) => payment?.is_selected === 1) // Only include selected payments
      .map((payment) => ({
        quote_id: payment?.quote_id,
        amount_financed: payment?.amount_financed,
        tenure: payment?.tenure,
        interest_rate: payment?.interest_rate,
        instalment_amount: payment?.instalment_amount,
        fmv_of_car: payment?.fmv_of_car,
        chattel_fee: payment?.chattel_fee,
        chattel_fee_age: payment?.chattel_fee_age,
        carmudi_incentive_age: payment?.carmudi_incentive_age,
        carmudi_incentive_amount: payment?.carmudi_incentive_amount,
        carmudi_top_up_age: payment?.carmudi_top_up_age,
        carmudi_top_up_amount: payment?.carmudi_top_up_amount,
        total_carmudi_incentive_age: payment?.total_carmudi_incentive_age,
        carmudi_incentive_tax_age: payment?.carmudi_incentive_tax_age,
        total_carmudi_incentive_net_of_tax: payment?.total_carmudi_incentive_net_of_tax,
        interest_rate_list: JSON.stringify(payment.interest_rate_list.map(rate => ({
          id: rate.id,
          label: `${rate.value}%`,
          value: rate.value
        }))),
        chattel_fee_age_list: JSON.stringify(payment.chattel_fee_age_list.map(fee => ({
          id: fee.id,
          label: `${fee.value}%`,
          value: fee.value
        }))),
        is_selected: payment?.is_selected,
      })),
  }));
};


export function updateSelectionRadio(data, financier_id, selectedIndex) {
  return data.map((financier) => {
    if (financier.financier_id === financier_id) {
      return {
        ...financier,
        payment_list: financier.payment_list.map((payment, index) => ({
          ...payment,
          is_selected: index === selectedIndex ? 1 : 0,
        })),
      };
    }
    return financier;
  });
}

export function selectFirstPaymentList(arr) {
  arr.forEach(obj => {
      if (obj.payment_list && obj.payment_list.length > 0) {
          obj.payment_list[0].is_selected = 1;
      }
  });
  return arr;
}

export function updateFirstPaymentListQuote1(arr) {
  arr.forEach(obj => {
      if (obj.payment_list && obj.payment_list.length > 0) {
          obj.payment_list[0].quote_id = "1";
      }
  });
  return arr;
}

//   console.log(JSON.stringify(transformedData, null, 2));



export function getIsSelected(data, financierId, quoteId) {
  const financierData = data.find(financier => financier.financier_id === financierId);
  console.log("======financierData====", financierData);
  if (!financierData) {
    return 0; // Financier not found
  }

  const paymentData = financierData.payment_list.find(payment => payment.quote_id === quoteId);
  console.log("======paymentData====", paymentData);
  if (!paymentData) {
    return 0; // Payment data not found
  }

  return paymentData.is_selected;
}


export function transformDataAfterSave(data) {
  return data.map(financier => ({
      financier_id: financier.financier_id,
      financier_name: financier.financier_name,
      max_percent_age: financier.max_percent_age,
      min_percent_age: financier.min_percent_age,
      max_fmv_in_car: financier.max_fmv_in_car,
      percent_age: financier.percent_age,
      financier_amount: financier.financier_amount,
      financier_variable_select: financier.financier_variable_select,
      payment_list: financier.payment_list.map(payment => ({
          quote_id: payment.quote_id,
          is_selected: payment.is_selected,
          amount_financed: payment.amount_financed,
          tenure: payment.tenure,
          interest_rate: payment.interest_rate,
          instalment_amount: payment.instalment_amount,
          fmv_of_car: payment.fmv_of_car,
          chattel_fee: payment.chattel_fee,
          chattel_fee_age: payment.chattel_fee_age,
          carmudi_incentive_age: payment.carmudi_incentive_age,
          carmudi_incentive_amount: payment.carmudi_incentive_amount,
          carmudi_top_up_age: payment.carmudi_top_up_age,
          carmudi_top_up_amount: payment.carmudi_top_up_amount,
          total_carmudi_incentive_age: payment.total_carmudi_incentive_age,
          carmudi_incentive_tax_age: payment.carmudi_incentive_tax_age,
          total_carmudi_incentive_net_of_tax: payment.total_carmudi_incentive_net_of_tax,
          interest_rate_list: JSON.parse(payment.interest_rate_list).map(rate => ({
              ...rate,
              __typename: "Interest_Rate_List"
          })),
          chattel_fee_age_list: JSON.parse(payment.chattel_fee_age_list).map(fee => ({
              ...fee,
              __typename: "Interest_Rate_List"
          })),
          __typename: "Quote_Financier_List_Payment_List"
      })),
      __typename: "Quote_Financier_List"
  }));
}



