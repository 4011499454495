import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import LoanExpectedVehicalDetails from "./LoanExpectedVehicalDetails";
import LoanExpectedQuoteGeneration from "./LoanQuoteGeneration";
import LoanQuoteSelection from "./LoanQuoteSelection";

const LoanQuoteTabs = (props) => {
  // console.log("=========LoanExpected props=========", props.currentTab);
  const [defaultActiveKey, setDefaultActiveKey] = useState(props.currentTab);
  // console.log("=========LoanExpected defaultActiveKey=========", defaultActiveKey);
  useEffect(() => {
    setDefaultActiveKey(props.currentTab);
  }, [props.currentTab]);

  // console.log("======props.carDetails=====", props.carDetails);

  return (
    <div className="">
      <div className="doument-gallery-tabs">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          activeKey={defaultActiveKey}
        >
          <Nav variant="pills" className="flex-column">
            <div className="tab-list">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  onClick={(e) => {
                    props.handleTabChange(e, "first");
                  }}
                >
                  Vehicle Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={(e) => {
                    props.handleTabChange(e, "second");
                  }}
                >
                  Quote Generation
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  onClick={(e) => {
                    props.handleTabChange(e, "third");
                  }}
                >
                  Quote Selection
                </Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <LoanExpectedVehicalDetails
                carDetails={props.carDetails}
                allOptions={props.allOptions}
                handleChange={props.handleChange}
                makeOptions={props.makeOptions}
                modelOptions={props.modelOptions}
                versionOptions={props.versionOptions}
                tenure={props.tenure}
                handleSave={props.handleSave}
                allCarDetailsFilled={props.allCarDetailsFilled}
                vehicleTypeOptions={props.vehicleTypeOptions}
                currentFmv={props.currentFmv}
                olderFMV={props.olderFMV}
                fetchNewQuote={props.fetchNewQuote}
                // editQuote={props.setEditQuote}
                editQuote={props.editQuote}
                selectionquotes={props.selectionquotes}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="financer-quote-outer">
                <LoanExpectedQuoteGeneration
                  carDetails={props.carDetails}
                  lead_id={props.lead_id}
                  handleTabChange={props.handleTabChange}
                  activeTab={defaultActiveKey}
                  fetchNewQuote={props.fetchNewQuote}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <LoanQuoteSelection
                lead_id={props.lead_id}
                activeTab={defaultActiveKey}
                fetchNewQuote={props.fetchNewQuote}
                editQuote={props.editQuote}
                editQuote2={props.editQuote2}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};
export default LoanQuoteTabs;
