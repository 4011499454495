import React from "react";
import { withFormsy } from 'formsy-react';
const defaultProps = {
  error: {
    status: true,
    message: ""
  },
  popupCls: ""
};
class FormsyCheckBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      id: props.id || "",
      name: props.name || "",
      type: props.type || "type",
      error: props.error || "",
      label: props.label || "",
      lclname: props.lclname || "gs_control gs_checkbox",
      checked: props.checked || "",
      disabled: props.disabled || false,
      readOnly: props.readOnly || false
    };
  }

  changeValue(event) {
    const value = event.target.value;
    this.setState({ value, error: "" });
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    return ({
      checked: nextProps.checked,
      disabled: nextProps.disabled,
      readOnly: nextProps.readOnly
    });
  }

  render() {
    const { value, label, id, name, disabled, readOnly} = this.state;
    return (
      
        <div className="custom-control custom-checkbox">
            <input
              id={id}
              type="checkbox"
              className={this.props.className ? this.props.className : "custom-control-input"}
              value={value}
              onChange={this.props.onChange}
              checked={this.props.checked}
              name={name}
              disabled={disabled}
              readOnly={readOnly}
              required={(this.props.required) ? this.props.required : false}
              aria-label="sameas cra"
            />
            <label
              data-label="form"
              htmlFor="pa_sameas_cra"
              className="custom-control-label"
              For={id}
            >
              {label} 
            </label>
          </div>
    );
  }
}
FormsyCheckBox.defaultProps = defaultProps;
export default withFormsy(FormsyCheckBox);
