import React from "react";
import { withFormsy } from "formsy-react";
import TextareaAutosize from 'react-textarea-autosize';
import DOMPurify from 'dompurify';
const FormsyTextareaAutosize = (props) => {
    const blurHandler = (event) => {
        const { value } = event.target;
        event.target.value = DOMPurify.sanitize(value);
        props.setValue(event.target.value);
    }
    const changeHandler = (event) => {
        if (typeof props.onChange === "function") {
            props.onChange(event);
        }
        props.setValue(event.target.value);
    }
    return <TextareaAutosize {...props} className="form-input" minRows={1.3} onChange={changeHandler} onBlur={blurHandler}/>
}

export default withFormsy(FormsyTextareaAutosize);