import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Formsy, {addValidationRule} from 'formsy-react';
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/computation.json";
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { COMPUTATION_MASTER_DATA, SAVE_COMPUTATION_DETAILS, UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { empty, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { ADVANCE_DEALER_PAID, CHECK_ASSIGN_USER, FINANCIER_DECIMAL_LIMIT, FINANCIER_ID, FINANCIER_VALUE_LIMIT, IS_USER_ACCESS_PAGE, MAX_ADVANCE_LOAN_VALUE, ROUNDOFF_TO_MAX_LIMIT, SUB_STATUS_CODES, TRUCK_IDS, calculatePercentageValue, isRegeneratedFieldNeedToActive} from '../../../src/config/constants';
import { getLoanDetail, getMasterDataMMV, getMasterDataMMVComputation } from '../../store/action/allAction';
import crypto from '../../config/crypto';
import { TENURE_IN_MONTH } from '../../config/constants';
import resubmitIcn from "../../webroot/images/resubmit.svg";


const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const COMPUTATION_FIELDS = FormFields["COMPUTATION_DETAILS"];

const PASS_THROUGH_DETAILS = FormFields["PASS_THROUGH_DETAILS"];

const PAYMENT_FROM_FINANCIER_DETAILS = FormFields["PAYMENT_FROM_FINANCIER_DETAILS"];

const CARMUDI_PAYMENT_TO_DEALER_DETAILS = FormFields["CARMUDI_PAYMENT_TO_DEALER_DETAILS"];

const EXTRA_DETAILS = FormFields["EXTRA_DETAILS"];

const FINAL_LOAN_DETAILS = FormFields["FINAL_LOAN_DETAILS"];

const IS_ADVANCE_DEALER_PAYOUT = FormFields["IS_ADVANCE_DEALER_PAYOUT"];
const ADVANCE_DEALER_PAYOUT = FormFields["ADVANCE_DEALER_PAYOUT"];

let PAID_THROUGH_OPTIONS = [
    {value: "loan_proceeds", label: "Loan Proceeds"},
    {value: "dealer_incentive", label: "Dealer Incentive"}
]

addValidationRule("percentageValidation", (values, value) => {
    if (value > 100) {
      return "Percentage value must be less than 100";
    }
    else if(value < 0) {
        return "Value should be greater than 0";
    }
    return true;
  })

  addValidationRule("vatValidation", (values, value) => {
    if (value > 50 && values.exclusive_per && values.inclusive_per ) {
        return "Percentage value must be less than or equal to 50";
    }
    else if(value <= 0) {
        return "Value should be greater than 0";
    }
    return true;
  })

  addValidationRule("dpValidation", (values, value) => {
    if (Number(value) >= Number(values.fmv)) {
      return "Amount must be less than FMV";
    }
    else if(value < 0) {
        return "Value should be greater than 0";
    }
    return true;
  })

  addValidationRule("negativeValidation", (values, value) => {
    if(value <= 0) {
        return "Value should be greater than 0";
    }
    return true;
  })

  addValidationRule("negativeValue", (values, value) => {
    if(value < 0) {
        return "Value cannot be negative";
    }
    return true;
  })

  addValidationRule("adp_surcharge", (values, value) => {
    const { min, max } = ADVANCE_DEALER_PAID.ADP_SURCHARGE;
    if(value < min || value > max) {
        return `Value should be between ${min} to ${max}`;
    }
    return true;
  })

  addValidationRule("adp_title_transfer", (values, value) => {
    const { min, max } = ADVANCE_DEALER_PAID.TITLE_TRANSFER_CHARGE;
    if(value < min || value > max) {
        return `Value should be between ${min} to ${max}`;
    }
    return true;
  })

  addValidationRule("adp_max_capped", (values, value) => {
    if(value > MAX_ADVANCE_LOAN_VALUE) {
        return `Max capped value is ${MAX_ADVANCE_LOAN_VALUE}`;
    }
    if(value < 1) {
        return "Value cannot be negative or zero";
    }
    return true;
  })

addValidationRule("adp_percentage_range", (values, value) => {
    if(value > ADVANCE_DEALER_PAID.ADP_PERCENTAGE_RANGE.max) {
        return `Max value is ${ADVANCE_DEALER_PAID.ADP_PERCENTAGE_RANGE.max}`;
    }
    if(value < ADVANCE_DEALER_PAID.ADP_PERCENTAGE_RANGE.min) {
        return `Min value is ${ADVANCE_DEALER_PAID.ADP_PERCENTAGE_RANGE.min}`;
    }
    return true;
  })

  // is Valid total amount paid
  addValidationRule('isValidDSTcharges', function(values, value) {
    return value>=0 && value<= 100000 ? true : 'DST Charges should be in Between 0-100000';
});

const Computation = (props) => {
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    const [selectedTab, setSelectedTab] = useState("first")
    const [computationDetails, setComputationDetails] = useState({});
    const [computationMasterDetails, setComputationMasterDetails] = useState([]);
    const [ongoingPromo, setOngoingPromo] = useState({});
    const [loanAmountPass, setLoanAmountpass] = useState({});
    const [diAmountPass, setDiAmountpass] = useState({});
    const [advanceDiPaid, setAdvanceDiPaid] = useState({});
    const [vatInput, setVatInput] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [computationSubmit, setComputationSubmit] = useState(false);
    const [allOptions, setAllOptions] = useState({});
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [comment, setComment] = useState("");
    const [vehicleType, setVehicleType] = useState(0);
    const [adpPerValue, setAdpPerValue] = useState(false);
    const { masterdata, lead, remarksHistory } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || []
    }));
    let params = useParams();
    let lead_id = crypto.decode(params.lead_id)
    let navigate = useNavigate();
    const client = useApolloClient();
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    let accessCondition = !IS_USER_ACCESS_PAGE("computation", props.current_page)?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    let financierIdArr = lead?.leadDetail?.financier?.filter(data => data.is_proceed === 'yes');
    let financierId = financierIdArr?.[0]?.financier_id;
    let quoteData = lead?.leadDetail?.quote_data?.find(data => data.financier_id === financierId) || {};
    
    const getMasterDataOptions = async() => {
        let options = masterdata ? {...masterdata.data,...masterdata.masterdatammvcomp} : {};
       // let optionsmmv = masterdata ? {...masterdata.masterdatammv} : {};
       //console.log(options.model[0],"ooooooooooooooooooo")
        options["brand"] = options.make?.map(make => ({value: make.id,label: make.make})) ;

        options["model"] = options.model?.map(model => ({value: model.id,label: model.m, mk_id: model.mk_id}));

        options["variant"] = options.version?.map(version =>({value : version.vn_id,label: version.vn, mk_id: version.mk_id, md_id: version.md_id}));
        // options['vehicle_type']=options.vehicle_type;
        
        options["yearofmanufacturing"] = options.make_year;
        options["tenure"] = TENURE_IN_MONTH['0']
        options.brand?.push({value: 0,label:'Other'});
        options.model?.push({value: 0,label:'Other'});
        options.variant?.push({value: 0,label:'Other'});
        options.paid_through = PAID_THROUGH_OPTIONS;
        // options["model"] = !lead?.leadDetail?.lead_computation_details ? options["model"]?.filter(md => md.mk_id == lead?.leadDetail?.lead_details?.make_id) : lead?.leadDetail?.lead_computation_details?.make_id === 9997 ? options["model"]?.filter(md => 9997 == lead?.leadDetail?.lead_computation_details?.make_id) : options["model"]?.filter(md => md.mk_id == lead?.leadDetail?.lead_computation_details?.make_id) ;
        // options["variant"] = !lead?.leadDetail?.lead_computation_details ?  options["variant"]?.filter(v => v.md_id === lead?.leadDetail?.lead_details?.model_id): lead?.leadDetail?.lead_computation_details?.model_id === 9998 ? options["variant"]?.filter(v => 9998 === lead?.leadDetail?.lead_computation_details?.model_id) : options["variant"]?.filter(v => v.md_id === lead?.leadDetail?.lead_computation_details?.model_id);

        setAllOptions(prev=>({ ...prev,...options}));
    }
    const showConfirmation = () => {
        setConfirmationModal(true);
        document.body.classList.add("overflow-hidden");
    }
  
    const hideConfirmation = () => {
        setConfirmationModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const handleInputChange = event => {
        if (event.target.id) {
            setComputationDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    useEffect(() => {
        if (props.current_page === 'computation_regenerated' && [SUB_STATUS_CODES.COMPUTATION_RESET].includes(lead?.leadDetail?.sub_status_id)) {
            document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = (computationDetails?.ongoing_promo === '0' && true) || false;
        }
    }, [computationDetails?.ongoing_promo])

    useEffect(() => {
        if (props.current_page === 'computation_regenerated' && [SUB_STATUS_CODES.COMPUTATION_RESET].includes(lead?.leadDetail?.sub_status_id)) {
            if(computationDetails?.vat_input === 'exclusive') {
                document.getElementById('exclusiveper').readOnly = false;
                document.getElementById('inclusiveper').readOnly = true;
            }
            else if(computationDetails?.vat_input === 'inclusive') {
                document.getElementById('inclusiveper').readOnly = false;
                document.getElementById('exclusiveper').readOnly = true;
            }
        }
    }, [computationDetails?.vat_input])

    const handleRadioChange = event => {  
        if(event.target.name === 'ongoing_promo' && event.target.value === '1') {
            document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = false;
        } else if(event.target.name === 'ongoing_promo' && event.target.value === '0') {
            document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = true;
        } 

        if(event.target.name === 'ongoing_promo') {
            setOngoingPromo({
                [event.target.name]:event.target.id
            })
        } else if(event.target.name === 'loan_amount_pass_through') {
            event.target.value === 'yes' ? setComputationDetails((prev) => ({...prev,loan_amount_paid: computationDetails?.loan_value,total_payment_from_financier_to_carmudi: computationDetails?.di_amount_from_financier_to_carmudi_net_amount,final_loan_value: 0})) : setComputationDetails((prev) => ({...prev,loan_amount_paid: 0,total_payment_from_financier_to_carmudi:computationDetails?.loan_value + computationDetails?.di_amount_from_financier_to_carmudi_net_amount, final_loan_value:computationDetails?.loan_value }))
            setLoanAmountpass({
                [event.target.name]:event.target.id
            })
        }else if(event.target.name === 'di_amount_pass_through') {
            if(event.target.value === 'no') {
                document.getElementById('dealer_amount_from_financier_percent').readOnly = true;
                ongoingPromo?.ongoing_promo === '1' ? document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = false : document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = true;
                // document.getElementById('di_amount_from_financier_to_carmudi_tax_percent').readOnly = false;
                setComputationDetails((prev) => ({...prev,dealer_amount_from_financier_percent: 0,dealer_amount_from_financier_amount:0}))
            }
            else {
                document.getElementById('dealer_amount_from_financier_percent').readOnly = false;
                ongoingPromo?.ongoing_promo === '1' ? document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = false : document.getElementById('di_amount_from_financier_to_carmudi_percent').readOnly = true;
                // document.getElementById('di_amount_from_financier_to_carmudi_tax_percent').readOnly = false;
            }
            setDiAmountpass({
                [event.target.name]:event.target.id
            })
        } else if(event.target.name === 'advance_amount_paid'){
            event.target.value === 'yes' ? setComputationDetails((prev) => ({...prev,advance_amount: computationDetails?.total_fee_by_customer})) : setComputationDetails((prev) => ({...prev,advance_amount: 0, paid_through: null}))
            setAdvanceDiPaid({
                [event.target.name]:event.target.id
            });
        } else if(event.target.name === 'vat_input') {
            setVatInput({
                [event.target.name]:event.target.id
            });
        }
        if(event.target.value === 'exclusive') {
            document.getElementById('exclusiveper').readOnly = false;
            document.getElementById('inclusiveper').readOnly = true;
        }
        else if(event.target.value === 'inclusive') {
            document.getElementById('inclusiveper').readOnly = false;
            document.getElementById('exclusiveper').readOnly = true;
        }

        if (event.target.name === "adp_type") {
            setComputationDetails(currentValue => ({
                ...currentValue,
                [event.target.name]: event.target.value,
                ...( event.target.value === "Yes" && {surcharge_for_adp: ADVANCE_DEALER_PAID.ADP_SURCHARGE.default, title_transfer_charge: ADVANCE_DEALER_PAID.TITLE_TRANSFER_CHARGE.default } || {})
            }))
            event.target.value === "No" && emptyADPFields();
        }

        if(['title_transfer_type','orcr_docs_verification_done'].includes(event.target.name)){
            setComputationDetails(currentValue => ({
                ...currentValue,
                [event.target.name]: event.target.value
            }))
        }
    }


    useEffect(()=>{
        let dealer_incentive_net_of_tax_to_be_paid = +(computationDetails?.["dealer_incentive_net_of_tax"] - (computationDetails?.[`loan_value`] * (computationDetails?.["surcharge_for_adp"]/100)) - computationDetails?.["title_transfer_charge"]) || 0;
        updateComputationFields("dealer_incentive_net_of_tax_to_be_paid", dealer_incentive_net_of_tax_to_be_paid);
    },[computationDetails?.surcharge_for_adp, computationDetails?.title_transfer_charge, computationDetails?.dealer_incentive_net_of_tax, computationDetails?.loan_value])

    useEffect(()=>{
        let computationDetailUpdate = computationDetails;
        if(computationDetails?.final_loan_value === 0) {
            computationDetailUpdate.adp_type = "No";
        }
        else {
            if(computationDetailUpdate?.adp_parameter === "Percentage") {
                computationDetailUpdate.adp_percentage_value = computationDetailUpdate.adp_percentage_value || ADVANCE_DEALER_PAID.ADP_PERCENTAGE_RANGE.default;
                computationDetailUpdate = setAdvanceLoanValueToBePaid(computationDetailUpdate);
                setAdpPerValue(true);
            }
            if (computationDetailUpdate?.adp_parameter === "Absolute") {
                computationDetailUpdate.advance_loan_value_to_be_paid = +(computationDetailUpdate.advance_loan_value_to_be_paid || 0);
                adpFieldEnableDisable("advance_loan_value_to_be_paid", false);
                setAdpPerValue(false);
            }
            computationDetailUpdate.remaining_loan_value_to_be_paid = +calculateADPRemainingAmount(computationDetailUpdate.advance_loan_value_to_be_paid);
        }
        setComputationDetails(currentValue => ({
            ...currentValue,
            ...computationDetailUpdate
        }))
        computationDetailUpdate?.adp_type === "No" && emptyADPFields();
    },[computationDetails?.final_loan_value, computationDetails?.adp_parameter])

    const cappedAdvanceLoanValue = (value) =>{
        return value > MAX_ADVANCE_LOAN_VALUE && MAX_ADVANCE_LOAN_VALUE || value;
    }

    const emptyADPFields = () => {
        setComputationDetails(currentValue => ({
            ...currentValue,
            adp_parameter: null,
            advance_loan_value_to_be_paid: 0,
            remaining_loan_value_to_be_paid: 0,
            surcharge_for_adp: 0,
            title_transfer_charge: 0,
            dealer_incentive_net_of_tax_to_be_paid: 0,
        }))
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setComputationDetails(currentValue => ({
                ...currentValue,
                [field_name]: parseFloat(data.value) || 0
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        let computationMasterObj = computationMasterDetails?.find(master => master.tenure_in_month === parseInt(data.value) && master.chattel_fee === computationDetails?.chattel_percentage);
        field_name === 'tenure_in_months' ? setComputationDetails(currentValue => ({
            ...currentValue,
            di_amount_from_financier_to_carmudi_percent: computationMasterObj?.di_payment_from_financier_gross || 0,
            di_amount_from_financier_to_carmudi_tax_percent: computationMasterObj?.di_payment_from_financier_tax || 0,
            dealer_incentive_percentage_gross: computationMasterObj?.dealer_incentive_gross || 0,
            carmudi_top_up_percentage: computationMasterObj?.carmudi_top_up_gross || 0,
            [field_name]: parseInt(data.value) || 0
          })) : setComputationDetails(currentValue => ({
            ...currentValue,
            [field_name]: field_name === "paid_through" ? data.value : (parseInt(data.value) || 0)
          }))
      }
    }

    const updateComputationFields = (key, value) => {
        setComputationDetails(currentValue => ({
            ...currentValue,
            [key]: value
        }))
    }
    const setAdvanceLoanValueToBePaid = (computationDetailUpdate) => {
        const advanceLoanValue = calculatePercentageValue(computationDetailUpdate.final_loan_value, computationDetailUpdate?.adp_percentage_value);
        computationDetailUpdate.advance_loan_value_to_be_paid = +cappedAdvanceLoanValue(advanceLoanValue);
        computationDetailUpdate.remaining_loan_value_to_be_paid = +calculateADPRemainingAmount(computationDetailUpdate.advance_loan_value_to_be_paid);
        adpFieldEnableDisable("advance_loan_value_to_be_paid", true);
        return computationDetailUpdate;
        
    }
    const handleADPChange = (data, field_name) => {
        if (data && field_name) {
            let computationDetailUpdate = computationDetails;
            computationDetailUpdate[field_name] = data?.value || (data?.target?.value?.charAt(data?.target?.value?.length-1) === "." ? data?.target?.value : parseFloat(data?.target?.value)) || 0;
            if (field_name === "adp_percentage_value") {
                computationDetailUpdate = setAdvanceLoanValueToBePaid(computationDetailUpdate);
            }
            if(field_name === "advance_loan_value_to_be_paid") {
                computationDetailUpdate.advance_loan_value_to_be_paid = +cappedAdvanceLoanValue(computationDetailUpdate.advance_loan_value_to_be_paid);
                computationDetailUpdate.remaining_loan_value_to_be_paid = +calculateADPRemainingAmount(computationDetailUpdate.advance_loan_value_to_be_paid);
            }
            setComputationDetails(currentValue => ({
                ...currentValue,
                ...computationDetailUpdate
            }))
        }
    }

    const calculateADPRemainingAmount = (advance_loan_value_to_be_paid=0) => {
        return  computationDetails["final_loan_value"] && (computationDetails["final_loan_value"] - advance_loan_value_to_be_paid) || 0;
    }

    const adpFieldEnableDisable = (fieldName, action) => {
        ADVANCE_DEALER_PAYOUT.find(field=>field.name == fieldName).readOnly = action;
    }

    const getModel = (make_id) => {
        if(make_id){
            let options = masterdata ? {...masterdata.masterdatammvcomp} : {};
            options["model"] = make_id !== 0 ? options.model?.map(model => ({value: model.id,label: model.m, mk_id: model.mk_id})).filter(md => md.mk_id === make_id) : [{value: 0,label:'Other'}];
            let all_options = {...allOptions};
            all_options["model"] = options["model"];
            all_options["variant"] = [];
            setAllOptions(all_options)
        }
    }

    const getVersion = (model_id) => {
        if(model_id){
            let options = masterdata ? {...masterdata.masterdatammvcomp} : {};
            options["variant"] = model_id !== 0 ? options.version?.map(version =>({value : version.vn_id,label: version.vn, mk_id: version.mk_id, md_id: version.md_id})).filter(v => v.md_id === model_id) : [{value: 0,label:'Other'}];
            let all_options = {...allOptions};
            all_options["variant"] = options["variant"];
            setAllOptions(all_options)
        }
    }

    const getMake = (vehicle_type) => {
        if(vehicle_type && Number(vehicle_type) !== 6){
            get_master_data(vehicle_type);
        }
        else {
            setAllOptions({...allOptions,brand:[{value: 0,label:'Other'}]})
        }
    }

    useEffect(()=>{
        const vehicle_type = masterdata?.masterdatammvcomp?.vehicle_usage?.find(el => +el?.id === +computationDetails?.vehicle_usage)?.vehicle_type || []
        setAllOptions(prev=>({...prev, vehicle_type}))
    }, [computationDetails?.vehicle_usage, masterdata])

    const handleMakeChange = (sname, ovalue) => {
        computationDetails[sname] = ovalue.value;
        if (sname === 'make_id') { 
            getModel(ovalue.value); 
            computationDetails['model_id'] = null; 
            computationDetails['variant_id'] = null;
        } 
        else if (sname === 'model_id') { getVersion(ovalue.value); computationDetails['variant_id'] = null;} 

        else if(sname === 'vehicle_type'){
            getMake(ovalue.value);
            computationDetails[sname] = parseInt(ovalue.value);
            computationDetails['model_id'] = null; 
            computationDetails['make_id'] = null; 
            computationDetails['variant_id'] = null;
        } 
        else if(sname === 'vehicle_usage'){
            setAllOptions(prev=>({...prev, vehicle_type: ovalue?.vehicle_type || [] }))
            computationDetails['vehicle_type']= null;
            computationDetails[sname] = parseInt(ovalue.value);
            computationDetails['model_id'] = null; 
            computationDetails['make_id'] = null; 
            computationDetails['variant_id'] = null;
        } 
        setComputationDetails({ ...computationDetails });
    }

    const handleComputationSubmit = () => {
        if(selectedTab === 'first'){
            setSelectedTab('second');
        }else if(['computation_generated', 'computation_regenerated'].includes(props.current_page)){            
            const decimal_limit = (Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT;
            const variables = { leadComputationDetailsInput: { ...{ lead_id: parseInt(lead_id) }, ...computationDetails, ...ongoingPromo, ...vatInput, ...loanAmountPass, ...advanceDiPaid, ...diAmountPass, ...{ status: "1" } } };
            
            variables.leadComputationDetailsInput['advance_loan_value_to_be_paid'] = +variables?.leadComputationDetailsInput?.advance_loan_value_to_be_paid || 0;
            variables.leadComputationDetailsInput['remaining_loan_value_to_be_paid'] = +variables?.leadComputationDetailsInput?.remaining_loan_value_to_be_paid || 0;
            variables.leadComputationDetailsInput['dealer_incentive_net_of_tax_to_be_paid'] = +variables?.leadComputationDetailsInput?.dealer_incentive_net_of_tax_to_be_paid || 0;    
            variables.leadComputationDetailsInput['title_transfer_charge'] = +variables?.leadComputationDetailsInput?.title_transfer_charge || 0;    
            variables.leadComputationDetailsInput['surcharge_for_adp'] = +variables?.leadComputationDetailsInput?.surcharge_for_adp || 0;      
            variables.leadComputationDetailsInput['adp_percentage_value'] = +variables?.leadComputationDetailsInput?.adp_percentage_value || 0; 
            variables.leadComputationDetailsInput['action_type'] = props.current_page;
            ["final_loan_value", "dealer_incentive_net_of_tax", "payment_to_dealer"].map(key=>{
                variables.leadComputationDetailsInput[key] = +ROUNDOFF_TO_MAX_LIMIT(`${variables.leadComputationDetailsInput?.[key]}`, decimal_limit) || variables.leadComputationDetailsInput?.[key];
            })                   
            delete variables.leadComputationDetailsInput['__typename'];
            executeGraphQLMutation(SAVE_COMPUTATION_DETAILS, variables, client)
            .then((res) => {
                if(res?.data.saveLeadComputationDetails.id) {
                    toast.success("success");
                    // setComputationSubmit(false);
                    dispatch(getLoanDetail(lead_id, client))
                    if (props.current_page !== 'computation_regenerated')
                        navigate(`/lead-detail/computation-verified/${params.lead_id}`);
                }
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:',''));
            })
        } else {
            updatestatus(props.current_page)
        }
    }
    let sub_status_to_check = props.current_page === 'computation_generated' ? 8 : props.current_page === 'computation_regenerated' ? SUB_STATUS_CODES.COMPUTATION_REGENERATED : 31;
    const isLeadHistoryExists = lead?.leadDetail?.lead_status_history.some((data) => data.sub_status_id === sub_status_to_check);
    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        if(props.current_page !== 'computation_regenerated' && (lead?.leadDetail?.tab_status?.payment_verification?.is_completed || isLeadHistoryExists)){
            return true;
        }
        if (props.current_page === 'computation_regenerated') {
            return lead?.leadDetail?.sub_status_id !== SUB_STATUS_CODES.COMPUTATION_RESET;
        }
      return !allowSubmit;
    }, [allowSubmit,lead?.leadDetail,isLeadHistoryExists]);

    const isComputationDisabled = useMemo(() => {
        if (props.current_page !== 'computation_regenerated' && (lead?.leadDetail?.tab_status?.payment_verification?.is_completed || isLeadHistoryExists)) {
            return true;
        }
        if (props.current_page === 'computation_regenerated') {
            return lead?.leadDetail?.sub_status_id !== SUB_STATUS_CODES.COMPUTATION_RESET;
        }
        return !computationSubmit;
      }, [computationSubmit,lead?.leadDetail,isLeadHistoryExists]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    let computationDetailsForm = useMemo(() => {
        if (computationDetails) {
            let filteredFields = FORM_FIELDS;
            if (computationDetails.make_id !== 0) {
              computationDetails.other_brand = '';
              filteredFields = filteredFields.filter(v => v.id !== "other_brand");
            }
            if (computationDetails.model_id !== 0) {
              computationDetails.other_model = '';
              filteredFields = filteredFields.filter(v => v.id !== "other_model");
            }
            if (computationDetails.variant_id !== 0) {
              computationDetails.other_variant = '';
              filteredFields = filteredFields.filter(v => v.id !== "other_variant");
            }
            return filteredFields
        }
        return FORM_FIELDS;
        
      }, [computationDetails]);

    const updatestatus=(action_type)=>{
        let variables = {
          updateleadstatus: {page_type:action_type,user_id:UserInfo?.id,lead_id:lead?.leadDetail?.id,remarks:comment}
        };
        executeGraphQLMutation(UPDATE_LEAD_STATUS, variables, client).then((result) => {
          toast.success("Success");
          dispatch(getLoanDetail(lead_id, client));
          action_type === 'computation_resubmit' && navigate(`/lead-detail/computation-generated/${params.lead_id}`);
          setComment("");
          hideConfirmation();
        }).catch(err => {
            hideConfirmation();
            toast.error(err.toString().replace('ApolloError:',''));
        })
    }
    useEffect(()=>{
        const vehicle_type_options = masterdata?.data?.vehicle_usage?.find(el=>+el.id === lead?.leadDetail?.lead_computation_details?.vehicle_usage)?.vehicle_type || [];
        setVehicleTypeOptions([...vehicle_type_options]);
    },[lead?.leadDetail?.lead_computation_details?.vehicle_usage])
    useEffect(() => {
        if(lead?.leadDetail?.lead_computation_details) { 
            setComputationDetails({...lead?.leadDetail?.lead_computation_details, vehicle_type: lead?.leadDetail?.lead_computation_details?.vehicle_type || quoteData?.vehicle_type,other_vehicle_type: lead?.leadDetail?.lead_computation_details?.other_vehicle_type || quoteData?.other_vehicle_type, plate_number:lead?.leadDetail?.lead_details?.plate_number});
            setOngoingPromo({['ongoing_promo']: lead?.leadDetail?.lead_computation_details?.ongoing_promo})
            setLoanAmountpass({['loan_amount_pass_through']: lead?.leadDetail?.lead_computation_details?.loan_amount_pass_through});
            setDiAmountpass({['di_amount_pass_through']: lead?.leadDetail?.lead_computation_details?.di_amount_pass_through})
            setAdvanceDiPaid({['advance_amount_paid']: lead?.leadDetail?.lead_computation_details?.advance_amount_paid})
            setVatInput({['vat_input']: lead?.leadDetail?.lead_computation_details?.vat_input})
        } else {
            let summaryDetails = {
                tenure:quoteData?.tenure_in_month || 0,
                interest_rate: quoteData?.interest_rate || 0,
                installment_amount: quoteData?.installment_amount || 0,
                fmv: quoteData?.car_fmv || 0,
                downpayment: quoteData?.dp_amount || 0,
                chattel_percentage: quoteData?.chattel_percent || 0,
                loan_amount: quoteData?.loan_amount || 0,
                chattel_fee: quoteData?.chattel_fee || 0,
                dst_charges: quoteData?.dst_charges || 0,
                outoftowncharge: quoteData?.out_of_town_charge || 0,
                dealer_incentive_percent: quoteData?.dealer_incentive_percent || 0,
                carmudi_top_up_percent: quoteData?.carmudi_top_up_percent || 0
            };
            let computationMasterObject = computationMasterDetails?.find(data => data.tenure_in_month === summaryDetails.tenure);
            const totalfeebycustomer = 
              (summaryDetails.chattel_fee ?? 0) + (summaryDetails.dst_charges ?? 0) + (summaryDetails.outoftowncharge ?? 0);
            const totalPaymentByCustomer =
              (summaryDetails.chattel_fee ?? 0) + (summaryDetails.dst_charges ?? 0) + (summaryDetails.outoftowncharge ?? 0) + (summaryDetails.downpayment ?? 0);
            const dealerIncentiveFromFinancer =
              (summaryDetails.loan_amount ?? 0) * (summaryDetails.dealer_incentive_percent/100 ?? 0);
            const dealerIncentiveFromCarmudi =
              (summaryDetails.loan_amount ?? 0) * (summaryDetails.carmudi_top_up_percent/100 ?? 0);
            const totalDealerIncentive = dealerIncentiveFromCarmudi + dealerIncentiveFromFinancer;
            const dealer_incentive_tax_percent = 10;
            const dealer_incentive_financier_net_tax = 
              (dealerIncentiveFromFinancer ?? 0) * (1 - dealer_incentive_tax_percent/100);
            const total_dealer_incentive_net_tax =
              (totalDealerIncentive ?? 0) * (1 - dealer_incentive_tax_percent/100);
            const total_payment_from_financer =
              (summaryDetails.loan_amount ?? 0) + dealer_incentive_financier_net_tax;
            const total_payment_to_dealer =
              (summaryDetails.loan_amount ?? 0) + (total_dealer_incentive_net_tax ?? 0);
            const dealerIncentiveFromFinancerNetofTax = (dealerIncentiveFromFinancer ?? 0) * (1- dealer_incentive_tax_percent/100);
            const di_amount_from_financier_to_carmudi_percent = computationMasterObject?.di_payment_from_financier_gross || 0
            const di_amount_from_financier_to_carmudi_amount = (di_amount_from_financier_to_carmudi_percent ?? 0) * (summaryDetails.loan_amount ?? 0) /100;
            const di_amount_from_financier_to_carmudi_tax_percent =computationMasterObject?.di_payment_from_financier_tax || 0;
            const di_amount_from_financier_to_carmudi_net_amount = di_amount_from_financier_to_carmudi_tax_percent &&(di_amount_from_financier_to_carmudi_amount ?? 0) * (1 - di_amount_from_financier_to_carmudi_tax_percent/100);
            const dealer_incentive_percentage_gross = computationMasterObject?.dealer_incentive_gross || 0;
            const dealer_incentive_percentage_net = dealer_incentive_percentage_gross * 0.9 || 0;
            const dealer_incentive_amount = (dealer_incentive_percentage_gross ?? 0) * (summaryDetails.loan_amount ?? 0) /100;
            const carmudi_top_up_amount = summaryDetails.loan_amount * (summaryDetails.carmudi_top_up_percent/100) || 0
            const total_dealer_incentive_percent = summaryDetails.carmudi_top_up_percent + dealer_incentive_percentage_gross || 0;

            const total_dealer_incentive_amount = carmudi_top_up_amount + dealer_incentive_amount || 0; 

            const remaining_dealer_incentive_amount = total_dealer_incentive_amount || 0;
            const total_dealer_incentive_net_amount = (remaining_dealer_incentive_amount * 0.9) || 0
            setComputationDetails({
                plate_number: lead?.leadDetail?.lead_details?.plate_number,
                make_id: lead?.leadDetail?.lead_details?.make_id || 0,
                model_id: lead?.leadDetail?.lead_details?.model_id || 0,
                variant_id: lead?.leadDetail?.lead_details?.variant_id || 0,
                other_brand: lead?.leadDetail?.lead_details?.make_name || '',
                other_model: lead?.leadDetail?.lead_details?.model_name || '',
                other_variant: lead?.leadDetail?.lead_details?.variant_name || '',
                year_of_manufacturing: lead?.leadDetail?.lead_details?.make_year || 0,
                tenure_in_months: summaryDetails.tenure,
                interest_rate: summaryDetails.interest_rate,
                installment: summaryDetails.installment_amount,
                fmv: summaryDetails.fmv,
                down_payment_amount: summaryDetails.downpayment,
                chattel_percentage: summaryDetails.chattel_percentage,
                out_of_town_charges: summaryDetails.outoftowncharge,
                dealer_incentive_percentage: summaryDetails.dealer_incentive_percent,
                dealer_incentive_tax_percentage: dealer_incentive_tax_percent,
                carmudi_top_up_percentage: summaryDetails.carmudi_top_up_percent,
                loan_value: summaryDetails.loan_amount,
                chattel_fee: summaryDetails.chattel_fee,
                dst_charges: summaryDetails.dst_charges,
                total_fee_by_customer: totalfeebycustomer,
                total_payment_by_customer: totalPaymentByCustomer,
                dealer_incentive_from_carmudi: dealerIncentiveFromCarmudi,
                dealer_incentive_from_financier:dealerIncentiveFromFinancer,
                total_dealer_incentive: totalDealerIncentive,
                total_dealer_incentive_net_of_tax: total_dealer_incentive_net_tax,
                total_dealer_payment: total_payment_to_dealer,
                dealer_incentive_financier_net_of_tax: dealerIncentiveFromFinancerNetofTax,
                total_payment_from_financier: total_payment_from_financer,
                vehicle_usage: quoteData?.vehicle_usage,
                vehicle_type: quoteData?.vehicle_type,
                other_vehicle_type: quoteData?.other_vehicle_type,
                loan_amount_paid: summaryDetails.loan_amount,
                di_amount_from_financier_to_carmudi_percent:di_amount_from_financier_to_carmudi_percent || 0,
                di_amount_from_financier_to_carmudi_amount: di_amount_from_financier_to_carmudi_amount || 0,
                di_amount_from_financier_to_carmudi_tax_percent: di_amount_from_financier_to_carmudi_tax_percent || 0,
                di_amount_from_financier_to_carmudi_net_amount: di_amount_from_financier_to_carmudi_net_amount || 0,
                advance_amount: totalfeebycustomer,
                total_payment_from_financier_to_carmudi: di_amount_from_financier_to_carmudi_amount &&di_amount_from_financier_to_carmudi_net_amount + summaryDetails.loan_amount,
                dealer_incentive_percentage_gross: dealer_incentive_percentage_gross || 0,
                dealer_incentive_percentage_net: dealer_incentive_percentage_net,
                dealer_incentive_amount: dealer_incentive_amount,
                carmudi_top_up_amount: carmudi_top_up_amount,
                total_dealer_incentive_percent: total_dealer_incentive_percent ,
                total_dealer_incentive_amount: total_dealer_incentive_amount,
                remaining_dealer_incentive_amount: remaining_dealer_incentive_amount,
                dealer_incentive_tax_percent: 10,
                total_dealer_incentive_net_amount: total_dealer_incentive_net_amount,
                final_loan_value: summaryDetails.loan_amount,
                dealer_incentive_net_of_tax: total_dealer_incentive_net_amount,
                payment_to_dealer: total_dealer_incentive_net_amount + summaryDetails.loan_amount || 0,
                title_transfer_type: 'No',
                orcr_docs_verification_done: 'No'
            })
        }
        
    },[lead?.leadDetail,computationMasterDetails])

    useEffect(() => {
        if(computationDetails['dealer_incentive_tax_percentage']>=0
        && lead?.leadDetail) {
            let dealer_incentive_financier_net_of_tax,total_dealer_incentive_net_of_tax,total_payment_from_financier,total_dealer_payment;
            dealer_incentive_financier_net_of_tax = computationDetails['dealer_incentive_from_financier'] * (1 - computationDetails['dealer_incentive_tax_percentage']/100);
            total_dealer_incentive_net_of_tax = computationDetails['total_dealer_incentive'] * (1 - computationDetails['dealer_incentive_tax_percentage']/100);
            total_payment_from_financier = computationDetails['loan_value'] + dealer_incentive_financier_net_of_tax;
            total_dealer_payment = computationDetails['loan_value'] + total_dealer_incentive_net_of_tax;
            setComputationDetails((prev) => ({
                ...prev,
                total_dealer_incentive_net_of_tax,
                total_dealer_payment,
                dealer_incentive_financier_net_of_tax,
                total_payment_from_financier
            }))
        }
        let vehicle_type = computationDetails?.vehicle_type || lead?.leadDetail?.lead_computation_details ? lead?.leadDetail?.lead_computation_details?.vehicle_type : quoteData?.vehicle_type;
        vehicle_type !== 6 ? get_master_data(vehicle_type) : setAllOptions({...allOptions,brand:[{value: 0,label:'Other'}]});
    }, [lead,computationDetails['dealer_incentive_tax_percentage']])

    useEffect(() => {
        if((computationDetails['fmv'] >=0 || computationDetails['down_payment_amount']>=0 || computationDetails['out_of_town_charges'] >=0 || computationDetails['dst_charges'] >=0  || computationDetails['chattel_percentage'] >=0 || computationDetails?.['di_amount_from_financier_to_carmudi_percent'] >=0 || computationDetails?.['di_amount_from_financier_to_carmudi_tax_percent'] >=0 || computationDetails?.['dealer_amount_from_financier_percent'] >=0 || computationDetails?.['dealer_incentive_percentage_gross'] >=0 || computationDetails?.['carmudi_top_up_percentage'] >= 0 || computationDetails?.['dealer_incentive_tax_percent'] >= 0)
        && lead?.leadDetail) {
            let loan_value,chattel_fee,dealer_incentive_from_financier,dealer_incentive_from_carmudi,total_dealer_incentive,total_dealer_incentive_net_of_tax,total_dealer_payment,dealer_incentive_financier_net_of_tax,total_payment_from_financier,total_fee_by_customer,total_payment_by_customer,revenue_including_vat,revenue_excluding_vat,vat_amount,di_amount_from_financier_to_carmudi_amount,di_amount_from_financier_to_carmudi_net_amount,dealer_amount_from_financier_amount,total_payment_from_financier_to_carmudi,dealer_incentive_percentage_net,dealer_incentive_amount,carmudi_top_up_amount,total_dealer_incentive_percent,total_dealer_incentive_amount,remaining_dealer_incentive_amount,total_dealer_incentive_net_amount,final_loan_value,payment_to_dealer,advance_amount;
            loan_value = computationDetails['fmv'] - computationDetails['down_payment_amount'];
            chattel_fee = loan_value * (computationDetails['chattel_percentage'] /100);
            total_fee_by_customer = computationDetails['out_of_town_charges'] + computationDetails['dst_charges'] + chattel_fee ?? 0;
            advance_amount = advanceDiPaid?.['advance_amount_paid'] === 'yes' ? total_fee_by_customer : 0
            total_payment_by_customer = total_fee_by_customer + computationDetails['down_payment_amount'];
            dealer_incentive_from_financier = loan_value * (computationDetails['dealer_incentive_percentage'] /100);
            dealer_incentive_from_carmudi = loan_value * (computationDetails['carmudi_top_up_percentage'] /100);
            total_dealer_incentive = dealer_incentive_from_financier + dealer_incentive_from_carmudi
            total_dealer_incentive_net_of_tax = total_dealer_incentive * (1 - computationDetails['dealer_incentive_tax_percentage']/100)
            total_dealer_payment = loan_value + total_dealer_incentive_net_of_tax;
            dealer_incentive_financier_net_of_tax = dealer_incentive_from_financier * (1 - computationDetails['dealer_incentive_tax_percentage']/100);
            total_payment_from_financier = loan_value + dealer_incentive_financier_net_of_tax;
            revenue_including_vat = loan_value * (computationDetails?.['inclusive_per']/100) || 0;
            revenue_excluding_vat = loan_value * (computationDetails?.['exclusive_per']/100) || 0;
            vat_amount = Number((revenue_including_vat) - (revenue_excluding_vat));  
            di_amount_from_financier_to_carmudi_amount = loan_value * (computationDetails?.['di_amount_from_financier_to_carmudi_percent'] /100) || 0;
            di_amount_from_financier_to_carmudi_net_amount = di_amount_from_financier_to_carmudi_amount &&di_amount_from_financier_to_carmudi_amount * (1 - computationDetails?.['di_amount_from_financier_to_carmudi_tax_percent']/100);
            dealer_amount_from_financier_amount = loan_value *(+computationDetails?.['dealer_amount_from_financier_percent']/100) || 0;
            total_payment_from_financier_to_carmudi = loanAmountPass?.['loan_amount_pass_through'] === 'yes' ? di_amount_from_financier_to_carmudi_net_amount : di_amount_from_financier_to_carmudi_net_amount + loan_value

            dealer_incentive_percentage_net = (computationDetails?.['dealer_incentive_percentage_gross'] * 0.9) || 0;
            dealer_incentive_amount = loan_value * (computationDetails?.['dealer_incentive_percentage_gross']/100) || 0;

            carmudi_top_up_amount = loan_value * (computationDetails?.['carmudi_top_up_percentage']/100) || 0;
            total_dealer_incentive_percent = computationDetails?.['carmudi_top_up_percentage'] + computationDetails?.['dealer_incentive_percentage_gross'] || 0;
            total_dealer_incentive_amount = carmudi_top_up_amount + dealer_incentive_amount || 0;
            remaining_dealer_incentive_amount = total_dealer_incentive_amount;
            if (computationDetails?.["paid_through"] === "dealer_incentive") {
                remaining_dealer_incentive_amount -= (advance_amount/(1 - computationDetails?.['dealer_incentive_tax_percent']/100))
            }
            total_dealer_incentive_net_amount = remaining_dealer_incentive_amount * (1 - computationDetails?.['dealer_incentive_tax_percent']/100) || 0;

            if (loanAmountPass?.['loan_amount_pass_through'] === 'no') {
                final_loan_value = loan_value;
                if (computationDetails?.["paid_through"] === "loan_proceeds") {
                    final_loan_value -= advance_amount;
                }
            } else {
                final_loan_value = 0;
            }
            payment_to_dealer = total_dealer_incentive_net_amount + final_loan_value;
            setComputationDetails((prev) => ({
                ...prev,
                loan_value,
                chattel_fee,
                total_fee_by_customer,
                total_payment_by_customer,
                dealer_incentive_from_financier,
                dealer_incentive_from_carmudi,
                total_dealer_incentive,
                total_dealer_incentive_net_of_tax,
                total_dealer_payment,
                dealer_incentive_financier_net_of_tax,
                total_payment_from_financier,
                revenue_including_vat,
                revenue_excluding_vat,
                vat_amount,
                loan_amount_paid: loanAmountPass?.['loan_amount_pass_through'] === 'yes' ? loan_value : 0,
                di_amount_from_financier_to_carmudi_amount,
                di_amount_from_financier_to_carmudi_net_amount,
                dealer_amount_from_financier_amount,
                advance_amount,
                total_payment_from_financier_to_carmudi,
                dealer_incentive_percentage_net,
                dealer_incentive_amount,
                carmudi_top_up_amount,
                total_dealer_incentive_percent,
                total_dealer_incentive_amount,
                remaining_dealer_incentive_amount,
                total_dealer_incentive_net_amount,
                final_loan_value,
                payment_to_dealer,
                dealer_incentive_net_of_tax:total_dealer_incentive_net_amount
            }))
        }
    }, [lead,computationDetails['fmv'],computationDetails['down_payment_amount'],computationDetails['out_of_town_charges'],computationDetails['dst_charges'],computationDetails['chattel_percentage'],computationDetails?.['di_amount_from_financier_to_carmudi_percent'],computationDetails?.['di_amount_from_financier_to_carmudi_tax_percent'],computationDetails?.['dealer_amount_from_financier_percent'],computationDetails?.['dealer_incentive_percentage_gross'],computationDetails?.['carmudi_top_up_percentage'],computationDetails?.['dealer_incentive_tax_percent'],computationDetails?.["paid_through"], advanceDiPaid,loanAmountPass])

    useEffect(() => {
        if(computationDetails['exclusive_per'] && lead?.leadDetail && !isLeadHistoryExists) {
            let inclusive_per,revenue_excluding_vat,revenue_including_vat, vat_amount;
            inclusive_per = ((computationDetails?.exclusive_per >= 0 ? computationDetails?.exclusive_per : 0) * 1.12) || 0;
            revenue_including_vat = computationDetails['loan_value'] * (inclusive_per/100);
            revenue_excluding_vat = computationDetails['loan_value'] * ((computationDetails?.exclusive_per >= 0 ? computationDetails?.exclusive_per : 0)/100);
            vat_amount = Number((revenue_including_vat) - (revenue_excluding_vat));  
            setComputationDetails((prev) => ({
                ...prev,
                inclusive_per,
                revenue_excluding_vat,
                revenue_including_vat,
                vat_amount
            }))
        }
    }, [lead,computationDetails?.exclusive_per, isLeadHistoryExists])

    useEffect(() => {
        if(computationDetails['inclusive_per'] && lead?.leadDetail && !isLeadHistoryExists) {
            let exclusive_per,revenue_excluding_vat,revenue_including_vat, vat_amount;
            exclusive_per = (computationDetails?.inclusive_per >= 0 ? computationDetails?.inclusive_per : 0) / 1.12;
            revenue_including_vat = computationDetails['loan_value'] * ((computationDetails?.inclusive_per >= 0 ? computationDetails?.inclusive_per : 0)/100);
            revenue_excluding_vat = computationDetails['loan_value'] * (exclusive_per/100);
            vat_amount = Number((revenue_including_vat) - (revenue_excluding_vat));  
            setComputationDetails((prev) => ({
                ...prev,
                exclusive_per,
                revenue_excluding_vat,
                revenue_including_vat,
                vat_amount
            }))
        }
    }, [lead,computationDetails?.inclusive_per,isLeadHistoryExists])
    useEffect(() => {
        const fetchData = async () => {
            let vehicle_type = computationDetails?.vehicle_type || lead?.leadDetail?.lead_computation_details ? lead?.leadDetail?.lead_computation_details?.vehicle_type : quoteData?.vehicle_type;
            get_master_data(vehicle_type)
            if(masterdata) await getMasterDataOptions();
        };
        fetchData();
    }, [masterdata,lead]);

    useEffect(() => {
        if(!lead?.leadDetail?.lead_computation_details) {
            executeGraphQLQuery(COMPUTATION_MASTER_DATA(financierId),client).then(res => {
                if(res?.data?.computationMasterDetails) {
                    setComputationMasterDetails(res?.data?.computationMasterDetails);
                }
            }).catch(err => {
                toast.error("Something went wrong!");
            })
        }
    },[])

    const get_master_data = (vehicle_type) => {
        if (!vehicle_type || vehicle_type === 0) return;
        let category_id=1;
        if(TRUCK_IDS.includes(Number(vehicle_type))){
            category_id=3;
        }
        if(Number(vehicleType)!==category_id) { 
            dispatch(getMasterDataMMVComputation(client,vehicle_type));
        }
        setVehicleType(category_id)
        getMasterDataOptions()
    }
    let allfieldsReadonly = props.current_page === 'computation_regenerated' ? false : props.current_page === 'computation_generated' && (!UserInfo?.role_id?.includes(1) || lead?.leadDetail?.sub_status_id === 32) ? lead?.leadDetail?.lead_computation_details && accessCondition : lead?.leadDetail?.lead_computation_details || false;
    
    let isComputationRadioMarked = (lead?.leadDetail?.lead_computation_details || (Object.keys(ongoingPromo).length && Object.keys(loanAmountPass).length && Object.keys(diAmountPass).length && Object.keys(advanceDiPaid).length && Object.keys(vatInput).length)) ? true : false;
    const sectionInfo = {section:"Computation" , sub_section:""}
    let latestResubmitRemarks = lead?.leadDetail?.lead_status_history?.filter(data => data.sub_status_id === 32)?.sort((a, b) => b.id - a.id);
    
    const isRegeneratedFieldActive = (field_name) => {
        if (props.current_page !== 'computation_regenerated') return false;
        if (props.current_page === 'computation_regenerated' && ![SUB_STATUS_CODES.COMPUTATION_RESET].includes(lead?.leadDetail?.sub_status_id)) return true;
        return isRegeneratedFieldNeedToActive(field_name)
    } 
    

    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel ">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <h2>Computation</h2>
              <div class="inline-container">
                <h2>{props.name}</h2>
                {props.current_page === 'computation_verified' && 
                <button type="submit" class="resubmit-btn" onClick={showConfirmation} disabled={isLeadHistoryExists ||getContext.markAsFreezed || accessCondition}>
                    Resubmit <img src={resubmitIcn} className="resubmit-icn" /> 
                </button>}
              </div>
                <div className="doument-gallery-tabs">
                    <Tab.Container id="left-tabs-example" activeKey={selectedTab} defaultActiveKey="first" onSelect={(e) => setSelectedTab(e)}>
                        <Nav variant="pills" className="flex-column">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Basic Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link disabled={lead?.leadDetail?.lead_computation_details ? false : (isSubmitDisabled)} eventKey="second">Computation</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                            <Formsy
                                className="lead-form-filed"
                                autoComplete="off"
                                onValid={() => setAllowSubmit(true)}
                                onInvalid={() => setAllowSubmit(false)}
                                onValidSubmit={() => handleComputationSubmit()}
                            >
                        <div className="row">
                
                            {computationDetailsForm.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                            <fieldset class="form-filed col-md-6 " style={{display : ((`${field.name}` === "other_vehicle_type" && (!computationDetails?.["vehicle_type"] || computationDetails?.["vehicle_type"] !== 6))) ? "none" : "block"}}>
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails && computationDetails[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        required={field.required}
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) :
                                    field.type === "dropdown" ? (
                                    <fieldset class="single-select col-md-6">
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: allOptions?.[field?.optionsKey],
                                            placeholder: field.label,
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: allOptions?.[field?.optionsKey]?.filter(({ value }) => value == computationDetails?.[field?.name]),
                                            isDisabled: field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)
                                        }}
                                        required={field.required}
                                        value={computationDetails && computationDetails[field?.name]}
                                        onChange={(data) => ["make_id", "model_id", "vehicle_usage","vehicle_type"].includes(field.name) ? handleMakeChange(field.name, data) : handleSelectChange(data, field.name)}
                                    />
                                    </fieldset>
                                    ) : null 
                                ))}
                                
                        </div>

                        <div className="btn-save-remarks">
                        {(isSubmitDisabled || getContext.markAsFreezed || accessCondition) ? <span className="span-disabled">Next</span> :
                            <button type="submit" className="btn-primary" disabled={isSubmitDisabled || getContext.markAsFreezed || accessCondition}>
                            Next
                            </button>
                        }
                            <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                            <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                            </button>
                        </div>
                            </Formsy>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            <Formsy
                                className="lead-form-filed"
                                autoComplete="off"
                                onValid={() => setComputationSubmit(true)}
                                onInvalid={() => setComputationSubmit(false)}
                                onValidSubmit={handleComputationSubmit}
                            >
                         <div className='right-panel-scroll'>       
                        <div className="row">
                            {latestResubmitRemarks[0]?.remarks && [8,32].includes(lead?.leadDetail?.sub_status_id) &&
                            <section>
                                <div className = 'comp-cmnt-heading'>Comments</div>
                                <div className='comp-cmnt'>{latestResubmitRemarks[0]?.remarks}</div>
                            </section>
                            }
                            <section className='loan-detail-filed-bg'>
                                <h2>Computation Details</h2>
                                {COMPUTATION_FIELDS.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                                <fieldset class="form-filed col-md-6 ">
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails?.[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        required={field.required}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) : 
                                    field.type === 'radio'? (
                                    <>
                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                            <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                                id={option.value}
                                                type="radio"
                                                className="custom-control-input"
                                                name={option.name}
                                                value={option.value}
                                                defaultChecked={option.value?.toString() === lead?.leadDetail?.lead_computation_details?.[option.name] ? true : false}
                                                onChange={handleRadioChange}
                                                disabled={allfieldsReadonly || isRegeneratedFieldActive(field?.id)}
                                                aria-label="handle change radio"
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                            </div>
                                        ))}
                                        </fieldset>
                                    </>
                                    ) :null 
                                ))}    
                            </section>  
                            <section className='loan-detail-filed-bg'>
                                <h2>Pass Through Details</h2>
                                {PASS_THROUGH_DETAILS.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                                <fieldset class={`form-filed ${field.label.length >=25 ? 'col-md-12' : 'col-md-6'}`}>
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails?.[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        required={field.required}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) : 
                                    field.type === 'radio'? (
                                    <>
                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                            <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                                id={option.value}
                                                type="radio"
                                                className="custom-control-input"
                                                name={option.name}
                                                value={option.value}
                                                defaultChecked={option.value?.toString() === lead?.leadDetail?.lead_computation_details?.[option.name] ? true : false}
                                                onChange={handleRadioChange}
                                                disabled={allfieldsReadonly || isRegeneratedFieldActive(field?.id)}
                                                aria-label={`for ${option.name}`}
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                            </div>
                                        ))}
                                        </fieldset>
                                    </>
                                    ) :null 
                                ))}    
                            </section>
                            <section className='loan-detail-filed-bg loan-input-filed-bg'>
                                <h2>Payment From Financier Details</h2>
                                {PAYMENT_FROM_FINANCIER_DETAILS.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                                <fieldset class={`form-filed ${field.label.length >=25 ? 'col-md-12' : 'col-md-6'}`}>
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails?.[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        required={field.required}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) : 
                                    field.type === 'radio'? (
                                    <>
                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                            <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                                id={option.value}
                                                type="radio"
                                                className="custom-control-input"
                                                name={option.name}
                                                value={option.value}
                                                defaultChecked={option.value?.toString() === lead?.leadDetail?.lead_computation_details?.[option.name] ? true : false}
                                                onChange={handleRadioChange}
                                                disabled={allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                                aria-label={`for ${option.name}`}
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                            </div>
                                        ))}
                                        </fieldset>
                                    </>
                                    ) : (field.name === "paid_through" ? (field.type === "dropdown" && advanceDiPaid?.advance_amount_paid === 'yes') : field.type === "dropdown") ? (
                                    <fieldset class="single-select col-md-12">
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: allOptions && allOptions[field.optionsKey],
                                            placeholder: field.label,
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: allOptions && allOptions[field.optionsKey] && allOptions[field.optionsKey].filter(({ value }) => (field.name === "paid_through" ? value : Number(value)) === computationDetails?.[field.name]),
                                            isDisabled: field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)
                                        }}
                                        required={field.required}
                                        value={computationDetails && computationDetails[field.name]}
                                        onChange={(data) => handleSelectChange(data, field.name)}
                                    />
                                    </fieldset>
                                    ) : null  
                                ))}    
                            </section>
                            <section className='loan-detail-filed-bg loan-input-filed-bg'>
                                <h2>Carmudi Payment To Dealer Details</h2>
                                {CARMUDI_PAYMENT_TO_DEALER_DETAILS.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                                <fieldset class={`form-filed ${field.label.length >=25 ? 'col-md-12' : 'col-md-6'}`}>
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails?.[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        required={field.required}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) : 
                                    field.type === 'radio'? (
                                    <>
                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                            <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                                id={option.value}
                                                type="radio"
                                                className="custom-control-input"
                                                name={option.name}
                                                value={option.value}
                                                defaultChecked={option.value?.toString() === lead?.leadDetail?.lead_computation_details?.[option.name] ? true : false}
                                                onChange={handleRadioChange}
                                                disabled={allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                                aria-label={`for ${option.label}`}
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                            </div>
                                        ))}
                                        </fieldset>
                                    </>
                                    ) :null 
                                ))}    
                            </section>
                            <section className="loan-payment-sec">
                                {FINAL_LOAN_DETAILS?.map(field => (
                                    <div className='form-filed col-md-12'>
                                    <div className="material">
                                        <label className='form-label'>{field.label}</label>
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={computationDetails?.[field.name]}
                                            placeholder=" "
                                            onChange={
                                            field.type === "number-format" 
                                            ? (data) => handlePatternFormatChange(data, field.name)
                                            : handleInputChange
                                            }
                                            format={field.ApplyNumberformat ? field.format : ""}
                                            validations={field.validations}
                                            validationError={
                                            computationDetails?.[field.name] ? field.validationError : ""
                                            }
                                            readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                            required={field.required}
                                            decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                            valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                        />
                                    </div>
                                </div>
                                ))}
                             </section>

                            <section className='loan-detail-filed-bg'>
                                <h2>Advance Dealer Payout</h2>
                                {IS_ADVANCE_DEALER_PAYOUT.map((field) => (
                                    <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                        <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                            id={option.value}
                                            type="radio"
                                            className="custom-control-input"
                                            name={option.name}
                                            value={option.value}
                                            checked={option.value === computationDetails?.[option.name]}
                                            defaultChecked={option.value === lead?.leadDetail?.lead_computation_details?.[option.name]}
                                            onChange={handleRadioChange}
                                            disabled={ allfieldsReadonly || (field.id === "adp_type" && option.value === "Yes" && computationDetails?.final_loan_value < 1) || isRegeneratedFieldActive(field?.name)}
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                        </div>
                                    ))}
                                    </fieldset>
                                ))}
                                {computationDetails?.adp_type === "Yes" && ADVANCE_DEALER_PAYOUT.map((field) => (
                                    ["text", "number-format"].includes(field.type) && ((field.name === "adp_percentage_value" && adpPerValue) || field.name !== "adp_percentage_value") && (
                                    <fieldset className={`form-filed col-md-12`}>
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={computationDetails?.[field.name]}
                                            placeholder=" "
                                            // {...(field.type === "number-format") && { thousandsGroupStyle:"thousand", thousandSeparator: ',', decimalSeparator: '.' } || {}}
                                            label={field.label}
                                            onChange={ (data) => handleADPChange(data, field.name) }
                                            format={field.ApplyNumberformat || ""}
                                            validations={field.validations}
                                            validationError={ computationDetails?.[field.name] && field.validationError || "" }
                                            readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                            required={field.required}
                                            decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                        />
                                    </fieldset>) 
                                    || field.type === "dropdown" && (
                                        <fieldset className="single-select col-md-12">
                                            <FormsySelect
                                                name={field.name}
                                                id={field.id}
                                                inputProps={{
                                                    options: field?.options,
                                                    placeholder: field.label,
                                                    className: "react-select",
                                                    classNamePrefix: "react-select",
                                                    value: field?.options.filter(({ value }) => value === computationDetails?.[field.name]),
                                                    isDisabled: field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)
                                                }}
                                                required={field.required}
                                                value={field?.options}
                                                onChange={(data) => handleADPChange(data, field.name)}
                                            />
                                        </fieldset>) || null
                                )) || null}
                            </section>
                                                    
                            <section className='loan-detail-filed-bg'>
                                <h2>Extra Details</h2>
                                {EXTRA_DETAILS.map((field) => (
                                ["text", "number-format"].includes(field.type) ? (
                                <fieldset class={`form-filed ${field.label.length >=25 ? 'col-md-12' : 'col-md-6'}`}>
                                <FormsyInputField
                                    id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={computationDetails?.[field.name]}
                                        placeholder=" "
                                        {...(field.type === "number-format") ? {
                                            thousandsGroupStyle:"thousand",
                                            thousandSeparator: ',',
                                            decimalSeparator: '.'
                                          }: {}}
                                        label={field.label}
                                        onChange={
                                        field.type === "number-format" 
                                        ? (data) => handlePatternFormatChange(data, field.name)
                                        : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ""}
                                        validations={field.validations}
                                        validationError={
                                        computationDetails?.[field.name] ? field.validationError : ""
                                        }
                                        readOnly={field.readOnly || allfieldsReadonly || isRegeneratedFieldActive(field?.name)}
                                        required={field.required}
                                        decimalScale={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_DECIMAL_LIMIT.JACCS_FINANCE) || FINANCIER_DECIMAL_LIMIT.DEFAULT}
                                        valueLength={(Number(financierId) === FINANCIER_ID.JACCS_FINANCE && FINANCIER_VALUE_LIMIT.JACCS_FINANCE) || FINANCIER_VALUE_LIMIT.DEFAULT}
                                    />
                                </fieldset>
                                    ) : 
                                    field.type === 'radio'? (
                                    <>
                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                        <label className="form-label">{field.label}</label>
                                        {field.options.map((option) => (
                                            <div className="custom-control custom-checkbox" key={option.value}>
                                            <input
                                                id={option.value}
                                                type="radio"
                                                className="custom-control-input"
                                                name={option.name}
                                                value={option.value}
                                                defaultChecked={option.value?.toString() === lead?.leadDetail?.lead_computation_details?.[option.name] ? true : false}
                                                onChange={handleRadioChange}
                                                disabled={allfieldsReadonly || isRegeneratedFieldActive(field?.id)}
                                                aria-label={`for ${option.name}`}
                                            />
                                            <label htmlFor= {option.id} className="custom-control-label">{option.label}</label>
                                            </div>
                                        ))}
                                        </fieldset>
                                    </>
                                    ) :null 
                                ))}    
                            </section>
                        </div>
                        </div>
                        <div className="btn-save-remarks p-lg-t">
                        {(isComputationDisabled || !isComputationRadioMarked || accessCondition) ? <span className="span-disabled">{props.current_page === 'computation_generated' ? 'Save' : 'Save & Next' }</span> :
                            <button type="submit" className="btn-primary" disabled={isComputationDisabled || !isComputationRadioMarked || accessCondition}>
                            {props.current_page === 'computation_generated' ? 'Save' : 'Save & Next' }
                            </button>
                        }
                            <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                </button>
                        </div>
                            </Formsy>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo}/>
          </Modal>
          <Modal show={confirmationModal} handleClose={hideConfirmation} >
            <div className="modal-header">
                <h2 style={{"padding-bottom":"0px"}}>Additional Remarks for re-submisison</h2>
                <div style={{"padding-bottom":"20px"}}>For holding the <span  style={{"font-weight":"600"}}>Financier Computation Details</span></div>
            </div>
            <div className="modal-body" >
              <div className="custom-control custom-checkbox">
              <div class="material">
                    <textarea
                        name="remarks"
                        id="remarks"
                        placeholder="Comments/Reason"
                        class="form-input"
                        rows="3"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                    >
                    </textarea>
                </div>
                <button class="btn-primary" onClick={() => updatestatus('computation_resubmit')} disabled={!comment.length || props.markAsFreezed || accessCondition}>Submit</button>
                </div>
            </div>
          </Modal>
        </div>
      </div>
    );
}
export default Computation;