import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import noImage from "../../../webroot/images/noimage.svg";
import GallaryCarousel from "./GallaryCarousal";
import { toast } from 'react-toastify';
import Loader from '../../elements/Loader';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import DocumentHistory from './DocumentHistory';
import ReSubmissionDocument from './ReSubmissionDocument';
import { LEAD_DOCS_REMARKS_QUERY } from '../../../services/leadDocsRemarks.gql';
import { executeGraphQLQuery } from '../../../common/executeGraphQLQuery';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const ListGridView = ({ docGallery, docCategoryList, documentList, categoryList, filterCategoryChildList, subCategoryList, setSubCategoryList, setDocGallery, seletedTabDocs, selectedTab, authUser, setDefaultImageCategory, leadDetail,markAsFreezed }) => {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [isDocLoading, setIsDocLoading] = useState(false);
    const [maxUploadCount, setMaxUploadCount] = useState(2);
    const [downloadList, setDownloadFiles] = useState([]);
    const [gridView, setGridView] = useState({
        gridView: false,
        ListView: true
    });
    const [currentFile, setCurrentFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documentHistoryList, setDocumentHistoryList] = useState([]);
    const [showDocumentHistoryModal, setShowDocumentHistoryModal] = useState(false);
    const [showResubmisisonModal, setShowResubmissionModal] = useState(false);
    const [currentDocData, setCurrentDocData] = useState({ doc_id: 0, parent_id: 0 });
    const [docActionType, setDocActionType] = useState('');

    useEffect(() => {
        setDownloadFiles([]);
        setDocGallery({ ...docGallery, filterFiles: [] });
    }, [selectedTab]);
    useEffect(() => {
        if (docGallery && docGallery.hasOwnProperty("filterFiles")) {
            if (docGallery["filterFiles"].length) {
                setCurrentFile(...docGallery["filterFiles"]);
            } else {
                setCurrentFile(null);
            }
        }
        setCurrentDocData((currentData) => ({
            ...currentData,
            doc_id: docGallery.selectedSubCategory.value,
            parent_id: docGallery.selectedCategory.value,
        }));
    }, [docGallery]);


    const toggleswitchgrid = (type) => {
        if (type === 'list') setGridView({ ...gridView, gridView: true, ListView: false });
        else setGridView({ ...gridView, ListView: true, gridView: false });
    }

    const handleChange = (key, field_obj) => {
        let docList = documentList, filtredFiles = [];
        if (key === 'selectedCategory') {
            let subCategory = docCategoryList && docCategoryList.filter(f => f.id === field_obj.value)?.map(v => v.child)[0]
            subCategory = subCategory && subCategory.length && subCategory.map(e => ({ label: e.doclang.name, value: e.id }));
            let maxUploadCount = docCategoryList && docCategoryList.filter(data => data.id === field_obj.value).map(e => e.max_upload)[0];

            setMaxUploadCount(maxUploadCount)
            /* If child category is not available then filter doc on parent category */
            if (!subCategory.length) {
                filtredFiles = docList.reduce((res, data) => {
                    if (data.doc_id === field_obj.value) res.push(data);
                    return res;
                }, []);
            }
            setSubCategoryList(subCategory)
            setDocGallery({ ...docGallery, selectedCategory: field_obj, selectedSubCategory: {}, filterFiles: filtredFiles })
        } else {
            filtredFiles = docList.reduce((res, data) => {
                if (data.doc_id === field_obj.value) res.push(data);
                return res;
            }, []);
            setDocGallery({ ...docGallery, selectedSubCategory: field_obj, filterFiles: filtredFiles })
        }
    }

    const markedChecked = (event) => {
        let inpvalue = event.target.value;
        let downloadListNew = [...downloadList];
        let itemIndex = downloadListNew.indexOf(inpvalue);
        if (event.target.checked && itemIndex === -1) {
            if (inpvalue === 'all') {
                // downloadListNew = [...seletedTabDocs.map(v => v.id.toString())];
                downloadListNew = seletedTabDocs
                .filter(file => file.doc_id && file.doc_status !== '0')
                .map(file => file.id.toString());
            }
            downloadListNew.push(inpvalue);

        } else {
            if (itemIndex > -1) {
                if (inpvalue === 'all') {
                    downloadListNew = [];
                } else {
                    downloadListNew.splice(itemIndex, 1);
                    let allIndex = downloadListNew.indexOf('all');
                    if (allIndex > -1) downloadListNew.splice(allIndex, 1);
                }

            }
        }
        if (downloadListNew.length === seletedTabDocs.length && !downloadListNew.includes('all')) downloadListNew.push('all');
        setDownloadFiles(downloadListNew);
    }

    //Download All
    const DownloadAll = () => {
        let links = [];
        if (seletedTabDocs.length) {
            setIsDocLoading(true);
            links = seletedTabDocs.filter(v => downloadList.includes(v.id.toString())).map(v => (v.doc_path));
            var zip = new JSZip();
            var count = 0;
            let zipfoldername = (leadDetail.id) ? leadDetail.id : "docs"
            var zipFilename = zipfoldername + ".zip";
            // const proxyurl = "https://api.allorigins.win/raw?url=";
            links.forEach((url, i) => {
                let image_url = url.split("?")[0];
                let base_name = image_url.split('/').pop()
                // var filename = links[i];
                // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com", "");
                // loading a file and add it in a zip file
                //let new_url = `${proxyurl}/proxy?url=${url}`;            
                JSZipUtils.getBinaryContent(url, (err, data) => {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(base_name, data, { binary: true });
                    count++;
                    if (count === links.length) {
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                            saveAs(content, zipFilename);
                            setIsDocLoading(false);
                        });
                    }
                });
            });

        }
    }

    const markAllAsApproved = async () => {
        let leadDocs = [];
        if (seletedTabDocs.length) {
            leadDocs = seletedTabDocs.filter(v => downloadList.includes(v.id.toString()) && ![2, 0].includes(v.doc_status)).map(file => ({
                id: file.id,
                doc_id: file.doc_id,
                parent_doc_id: file.parent_doc_id
            }));

            /** If selected docs already approved */
            if (leadDocs.length < 1) {
                return toast.error("Already approved");
            }
            let message = "Doc Approved Successfully";
            let data = {
                lead_id: leadDetail.id,
                user_id: authUser.id,
                doc_status: "2",
                doc_source: "web",
                update_action_type: 'file',
                remark: '',
                lead_docs: leadDocs
            }
            manageDocAction(data, message);

        }
    }

    const markAsApproved = () => {
        let data = {
            lead_id: leadDetail.id,
            user_id: authUser.id,
            doc_status: "2",
            doc_source: "web",
            remark: '',
            update_action_type: 'documents',
            lead_docs: [{
                id: currentFile.id,
                doc_id: currentFile.doc_id,
                parent_doc_id: currentFile.parent_doc_id
            }]
        };
        let message = "Doc Approved Successfully";

        manageDocAction(data, message);
    }

    /** will handle for both resubmission request as well as approval */
    const manageDocAction = async (leadData, message = null) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            setIsLoading(true);
            let variables = { approvedDocInput: leadData };
            const mutation = gql`
                mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                    approved_document(approvedDocInput: $approvedDocInput) {
                    id
                    lead_id
                    doc_path
                    doc_id
                    parent_doc_id
                    doc_status
                    }
                }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result && result.data) {
                    if (message) toast.success(message);
                    if (result.data) {
                        dispatch(getLoanDetail(leadDetail.id, client));
                       let filterFiles=docGallery.filterFiles[0];
                       if(filterFiles) {
                        filterFiles.doc_status=leadData?.doc_status;
                       }
                       else {
                        filterFiles = {
                            id: result?.data?.approved_document?.id,
                            lead_id: result?.data?.approved_document?.lead_id,
                            doc_id: result?.data?.approved_document?.doc_id,
                            parent_doc_id: result?.data?.approved_document?.parent_doc_id,
                            doc_path: result?.data?.approved_document?.doc_path,
                            doc_status: result?.data?.approved_document?.doc_status,
                        }
                       }
                        setDocGallery({ ...docGallery, filterFiles: [filterFiles] });
                    
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }).catch(error => {
                toast.error('Docs already approved/requested. Please refresh the page and check');
                setIsLoading(false);
            });
        } else {
            toast.error("No image found.");
        }
    }

    const getDocumentHistoryList = async (doc_id, lead_id) => {
        if (doc_id) {
            executeGraphQLQuery(LEAD_DOCS_REMARKS_QUERY(lead_id, doc_id), client).then((result) => {
                setDocumentHistoryList(result.data.doc_remark_history);
            })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        }
    }

    const showDocumentHistory = () => {
        setShowDocumentHistoryModal(true);
        document.body.classList.add("overflow-hidden");
        getDocumentHistoryList(currentFile.doc_id, leadDetail.id);
    }

    const hideModalDocumentHistory = () => {
        setShowDocumentHistoryModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showResubmission = (action_type) => {
        setShowResubmissionModal(true);
        setDocActionType(action_type);
        document.body.classList.add("overflow-hidden");
    }

    const hideResubmissionModal = () => {
        setShowResubmissionModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    return (
        <div className="doument-view-list-gallery" key={gridView}>
            {(isLoading) ? (<Loader />) : null}
            {gridView.ListView ?
                <div className="file-submit-sec list-view">
                    <div className="file-submit-selet-list">
                        <fieldset className="single-select m-xs-r">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    placeholder="Category"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={categoryList}
                                    name="selectedCategory"
                                    value={docGallery.selectedCategory && categoryList ? categoryList.filter(({ value }) => value === docGallery.selectedCategory.value) : ""}
                                    onChange={handleChange.bind(this, 'selectedCategory')}
                                    aria-label="category select"
                                />
                            </div>
                        </fieldset>
                        {subCategoryList.length ?
                            <fieldset className="single-select m-xs-l">
                                <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        options={subCategoryList}
                                        placeholder="Sub-category"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="selectedSubCategory"
                                        value={docGallery.selectedSubCategory}
                                        onChange={handleChange.bind(this, 'selectedSubCategory')}
                                        aria-label="sub category"
                                    />
                                </div>
                            </fieldset> : null}

                        <div className="grid-icn" onClick={toggleswitchgrid.bind(this, 'list')}>
                            <i className="ic-gridview">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                                <i className="path6"></i>
                                <i className="path7"></i>
                                <i className="path8"></i>
                            </i>
                        </div>
                    </div>
                    <div className="doc-count">Documents <span> {docGallery.filterFiles.length}/{maxUploadCount}</span></div>
                    {docGallery.filterFiles && docGallery.filterFiles.length ?
                        <div className="verfication-img">
                            <GallaryCarousel isLoading={isDocLoading} slides={docGallery.filterFiles} document_list={docCategoryList} />
                        </div>
                        :
                        <div className="verfication-img-no-image">
                            <img alt="No Document attached" src={noImage} className="" />
                            <span className="no-img-txt">No Document Attached</span>
                        </div>
                    }
                    <div className="image-opt doc-image-opt">
                        {/* Checking for non Pre-disbursal doc */}
                        {(!["pre-disbursal","car_transfer","post_disbursal"].includes(selectedTab)) && (
                            <div className="btn-submission-approved">
                                {currentFile && ![1,29,37].includes(leadDetail.sub_status_id) && (
                                    <>
                                        <button className="btn-line" onClick={showDocumentHistory}>View History</button>
                                        {
                                            currentFile && +currentFile.doc_status === 2 ? <><button className="btn-line btn-approve"><i className="ic-check"></i>Approved</button></>
                                                : currentFile && +currentFile.doc_status === 0 ? <><button className="btn-fill"> <i className="ic-check"></i>Re-submission Requested</button></>
                                                    : (selectedTab === 'first_submit' && ![3,5].includes(leadDetail?.status_id) && !leadDetail.lead_status_history.find((history) => history.sub_status_id === 3)) ? 
                                                    <><button className="btn-line" onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                                        <button className="btn-line" onClick={markAsApproved}>Mark as Approved</button></>
                                                        : <button className="btn-line" onClick={markAsApproved}>Mark as Approved</button>
                                        }
                                    </>
                                )}
                                {!docGallery.filterFiles.length && ![3,5].includes(leadDetail.status_id) && ![1,29,37].includes(leadDetail.sub_status_id) && !leadDetail.lead_status_history.find((history) => history.sub_status_id === 3 && selectedTab === 'first_submit') && (
                                    <>
                                        <button className="btn-line" onClick={showDocumentHistory}>View History</button>
                                        <button className="btn-line" onClick={() => showResubmission("resubmission")}>Request Re-submission</button>
                                    </>
                                )}
                                
                            </div>
                        )}
                    </div>
                </div>
                : null}


            {/* <--------- GRID view -------> */}
            {gridView.gridView ?
                <div className="file-submit-sec grid-view">
                    <div className="gird-view-select-opt">
                        {
                            seletedTabDocs && seletedTabDocs.length ? 
                            <div className="custom-control custom-checkbox check-box-small select-check-bx">
                                <input aria-label="select all input" id="selectall" type="checkbox" className="custom-control-input " name="Buyer Type" checked={downloadList.includes('all') ? true : false} onClick={markedChecked} value="all" />
                                <label className="custom-control-label" htmlFor="selectall">Select All </label>
                            </div> : null
                        }
                        <div className="list-icn" onClick={toggleswitchgrid.bind(this, 'grid')}>
                            <i className="ic-listview">
                                <i className="path1"></i>
                                <i className="path2"></i>
                                <i className="path3"></i>
                                <i className="path4"></i>
                                <i className="path5"></i>
                                <i className="path6"></i>

                            </i>
                        </div>
                    </div>
                    <div className="grid-item-list">
                        <div className="row">
                            {seletedTabDocs.length ?
                                seletedTabDocs && seletedTabDocs.map((file, index) => (
                                    <div className="col-md-4" key={index}>
                                        <div className="img-bx-select-opt">
                                            <div className="image-bx">
                                                {
                                                    (file.ext === 'pdf' || file.ext === 'PDF') ?
                                                        <a href={file.doc_path} target="_blank" rel="noopener noreferrer"><i className="ic-pdf-file icons-normal"></i></a>
                                                        : <img
                                                            src={file.doc_path}
                                                            alt={(file.parent_doc_id) ? docCategoryList.filter(v => v.id === file.parent_doc_id).map(v => v.doclang.name) + '|' + filterCategoryChildList.filter(v => v.id === +file.doc_id).map(v => v.doclang.name) : docCategoryList.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)}
                                                            className=""
                                                        />
                                                }
                                                {selectedTab === "first_submit" ? (
                                                    (file && file.doc_id && file.doc_status === '2') ? <span className="image-status">Approved</span> : (file && file.doc_id && file.doc_status === '0') ? <span className="image-requested">Requested</span> : ''
                                                ) : null}
                                            </div>
                                            <div className="custom-control custom-checkbox check-box-small">
                                                <input aria-label="individual file" id={`Individual${file.id}`} type="checkbox" className="custom-control-input " name="selectFile" disabled={file && file.doc_status === '0'} checked={downloadList.includes(file.id.toString()) ? true : false} onClick={markedChecked} value={file.id} />
                                                <label className="custom-control-label" For={`Individual${file.id}`}>{(file.parent_doc_id) ? docCategoryList.filter(v => v.id === file.parent_doc_id).map(v => v.doclang.name) + ' | ' + filterCategoryChildList.filter(v => v.id === +file.doc_id).map(v => v.doclang.name) : docCategoryList.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)}</label >
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                <div className="col-md-12">
                                    <div className="verfication-img-no-image">
                                        <img alt="No Document attached" src={noImage} className="" />
                                        <span className="no-img-txt">No Document Attached</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(downloadList && downloadList.length) ? (<div className="btn-line-img">
                        <button className="btn-line" onClick={DownloadAll}>Download Photos</button>
                        {(!["pre-disbursal","car_transfer","post_disbursal"].includes(selectedTab)) && (<button className="btn-line" onClick={markAllAsApproved}>Mark as Approved</button>)}

                    </div>) : null}
                </div>
                : null}
            <div className="view-timeline-popup image-timeline-popup doc-history-timeline">
                <DocumentHistory documentHistoryList={documentHistoryList} documentHistoryModelShowFlag={showDocumentHistoryModal} hideModalDocumentHistory={hideModalDocumentHistory} />
            </div>
            <ReSubmissionDocument leadId={leadDetail?.id} documentId={currentFile && currentFile.doc_id} filterData={currentDocData} documentParentId={currentFile && currentFile.parent_doc_id} actionType={docActionType} showResubmissionModal={showResubmisisonModal} hideModalReSubmission={hideResubmissionModal} requestResubmission={manageDocAction} user_id={authUser?.id} />
        </div>
    )
}


export default ListGridView;