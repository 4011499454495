import axios from "axios";
import {API_URL} from '../config/constants';
import jsPDF from 'jspdf';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { executeGraphQLQuery } from "./inventory-funding/executeGraphQLQuery";

const generalfnction =  {
  async uploadFilesToS3(postdata, headers) {
      headers = headers || {};  
      return axios.post(`${API_URL}core/commonservice/docs_upload`,
        postdata,
        { headers }
      );
    },
    getThousandsGroupRegex(thousandsGroupStyle) {
      switch (thousandsGroupStyle) {
        case "lakh":
          return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
  
        case "wan":
          return /(\d)(?=(\d{4})+(?!\d))/g;
  
        case "thousand":
        default:
          return /(\d)(?=(\d{3})+(?!\d))/g;
      }
    },
    phNumber (str) {
      if(!str) return;
        str=str.toString();
        let lastThree = str.substring(str.length-3);
        let otherNumbers = str.substring(0,str.length-3);
        if(otherNumbers !== '') lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
    },
    convertInputNumberIntoPh (str) {
      let str1 = String(str).toString();
      let formatedStr = str1.replaceAll(",", "");
      if (formatedStr.indexOf('.') > -1) {
          let firstPart = formatedStr.substring(0, formatedStr.indexOf('.'));
          let lastPart = formatedStr.substring(formatedStr.indexOf('.'), formatedStr.length);
          lastPart = lastPart.length > 2 ? lastPart.substring(0, 3) : lastPart;   
          return this.phNumber(firstPart)+lastPart;
      }
      return this.phNumber(formatedStr);
    },
    numberFormatWithDots(str) {
      let phNumberResult = this.phNumber(str);
      return this.convertInputNumberIntoPh(phNumberResult);
    },

    async handleDownloadPDF(docs,leadDetails,showBpkp=false) {
      const pdf = new jsPDF();
  
      for (let i = 0; i < docs?.length; i++) {
        const imageUrl = docs[i]?.doc_path_watermark;
        if(!imageUrl) continue;
        const imageSrc = await this.loadImage(imageUrl);
  
        if (i !== 0) {
          pdf.addPage();
        }
        pdf.addImage(imageSrc, "PNG", 10, 10, 180, 180);
      }
      let dealer_name = leadDetails?.dealer_name.split(' ').join('_');
      let fileName = showBpkp == true ? `file_${dealer_name}_${leadDetails?.lead_id}_${leadDetails?.bpkp_number}.pdf` : `file_${dealer_name}_${leadDetails?.lead_id}.pdf`;
      pdf.save(fileName);
    },

    async loadImage(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },

    async downloadAll(docs,leadDetails,showBpkp=false) {
      let zip = new JSZip();
      let folder = zip.folder('collection');
      let i=1;
      for (const sl of docs){
          if( sl?.doc_path_watermark){ 
              let extenion=this.get_url_extension(sl?.doc_path_watermark);
              let imageUrl = sl?.doc_path_watermark;
              let imageBlob = await fetch(imageUrl).then(async (response) => await response.blob());
              let imageFile = new File([imageBlob], (sl?.doc_master?.doc_master_lang?.name || sl?.doc_name) );
              await folder.file((sl?.doc_master?.doc_master_lang?.name || sl?.doc_name)+`${i}`+`.${extenion}` , imageFile);
              i++;
          }
      }
      let fileName = showBpkp == true ? `file_GCD${leadDetails?.dealer_id}_${leadDetails?.lead_id}` : `file_GCD${leadDetails?.dealer_id}_${leadDetails?.lead_id}`;
      folder.generateAsync({ type: "blob" }).then(content => saveAs(content, fileName));
    },
    get_url_extension( url ) {
      return url.split(/[#?]/)[0].split('.').pop().trim();
    },
    
  };


  export default generalfnction;