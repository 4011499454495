const crypto = require("crypto");
const CONSTANT = require("./constants");
const CIPHER_ALGO = "aes-128-cbc"; //"bf-ecb"
//const iv = "";

module.exports.encode = text => {
  var cipher = crypto.createCipher(CIPHER_ALGO, CONSTANT.SETTING.CRYPTO_KEY);
  var crypted = cipher.update("" + text, "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
};

module.exports.decode = text => {
  try {
    var decipher = crypto.createDecipher(
      CIPHER_ALGO,
      CONSTANT.SETTING.CRYPTO_KEY
    );
    var dec = decipher.update("" + text, "hex", "utf8");
    dec += decipher.final("utf8");
    return dec;
  } catch (error) {
    return null;
  }
};
