import React from "react";
const NoQuote = ({displayQuote}) => {
    const displayQuoteFn = () =>{
        displayQuote(true)
    }
  return (
    <div className="generate-quote">
      <p className="no-quote">No Quote Available</p>
      <button
        type="button"
        className="btn-line btn-submit gen-quote"
        onClick={displayQuoteFn}
      >
        {" "}
        <i className="ic-add"></i> Generate Quote
      </button>
    </div>
  );
};

export default NoQuote;
