import React, {useState, useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import FormFields from "./formFields/loanExpectedRightPanel.json";
import Formsy from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { gql, useApolloClient } from '@apollo/client';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../store/action/allAction';
import { toast } from "react-toastify";
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';

const LoanExpectedRightPanelFields = FormFields['loan_expected_right_panel'] || [];

const LoanExpectedRightPanel = (props) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const leadDetail = useSelector((state) => state.lead.leadDetail);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [carDetails, setCarDetails] = useState({});
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform,setShowremarkform]=useState(false);
  let { editLoanExpectedData, allOptions } = props;
  const leadDetails = {
    brand: leadDetail?.lead_details?.make_name ?? "",
    model: leadDetail?.lead_details?.model_name ?? "",
    variant: leadDetail?.lead_details?.variant_name ?? "",
    make_year: leadDetail?.lead_details?.make_year ?? "",
    color: leadDetail?.lead_details?.color ?? "",
    plate_number: leadDetail?.lead_details?.plate_number ?? "",
    fmv: editLoanExpectedData?.car_fmv ? editLoanExpectedData?.car_fmv : "",
    orcr: leadDetail?.lead_details?.orcr_received_reference_notes ?? "",
  };
  const isSubmitDisabled = useMemo(() => {
    // if(leadDetail?.lead_details?.dealer_car_id != null)
    //   return true;
    return !allowSubmit;
  }, [allowSubmit,leadDetail?.lead_details]);

  const handleInputChange = (target_id, event) => {
    let value = event.target?.value;
    if (target_id && value) {
        setCarDetails(currentValue => ({
            ...currentValue,
            [target_id]: value
        }))
    }
  }

  const handleCarDetailsSubmit = async() => {
    let finalResponse = {
      "lead_id": Number(props.lead_id),
      "color": carDetails.color,
      "plate_number": carDetails.plate_number
    }

    let variables = {
      carDetailInput: finalResponse
    };
    let mutation = gql`
        mutation saveCarDetailsLoanExpected($carDetailInput: CarDetailInput!) {
          car_details_loan_expected_save(carDetailInput: $carDetailInput) {
                success
            }
        }
    `;

    try {
        await executeGraphQLMutation(mutation, variables, client);
        dispatch(getLoanDetail(props.lead_id, client))
        toast.success("Success");
    } catch (error) {
        toast.error(error.toString().replace('ApolloError:',''));
        console.error(error);
    }
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const makeModelVersionChange = (field_name) => {
    let name = leadDetails?.[field_name] || "";
    return name;
  };

  const sectionInfo = {section:"Loan Expected" , sub_section:""}
  
  return (
    <div className="loan-form">
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValid={() => setAllowSubmit(true)}
        onInvalid={() => setAllowSubmit(false)}
        onValidSubmit={handleCarDetailsSubmit}
      >
        <div className="form-group-bx m-t-10">
          <h2>Car Details</h2>
          <div className="row">
            {LoanExpectedRightPanelFields.map((field) =>
              ["text", "number-format"].includes(field.type) ? (
                <fieldset
                  class="form-filed col-md-6"
                >
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={ ["brand", "model", "variant"].includes(field.name) ? makeModelVersionChange(field.name) : leadDetails && leadDetails[field.name] }
                    placeholder=" "
                    {...(field.type === "number-format") ? {
                        thousandsGroupStyle:"thousand",
                        thousandSeparator: ',',
                        decimalSeparator: '.'
                    }: {}}
                    label={field.label}
                    onChange={(e) => handleInputChange(field.id, e)}
                    format={field.ApplyNumberformat ? field.format : ""}
                    validations={field.validations}
                    validationError={field.validationError}
                    required={['color', 'plate_number'].includes(field.name) ? (leadDetail?.lead_details?.dealer_car_id == null || props.editButtonCondition ? true : false) : field.required}
                    // disabled={['color'].includes(field.name) ? (leadDetail?.lead_details?.dealer_car_id != null && leadDetails[field.name] || props.editButtonCondition ? true : false) : field.readOnly}
                    disabled={field.readOnly}

                  />
                </fieldset>
              ) : null
            )}
          </div>
        </div>
        <div class="btn-save-remarks">
        {(isSubmitDisabled || props.editButtonCondition || props.markAsFreezed || props.accessCondition) ? <span className="span-disabled">Save</span> :
          <button type="submit" class="btn-primary" disabled={isSubmitDisabled || props.editButtonCondition || props.markAsFreezed || props.accessCondition}>
            Save
          </button>
        }
          <button type="button" class="btn-line" onClick={showModalViewTimeLine}>
            Remarks <span className="m-xs-l">({props.remarksHistory && (props.remarksHistory.length || 0)})</span>
          </button>
        </div>
      </Formsy>

      <div className="view-timeline-popup">
            <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                <div className="modal-header">
                <h2>Remark History</h2>
                </div>
                <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={props.markAsFreezed} accessCondition={props.accessCondition} sectionInfo={sectionInfo}/>
            </Modal>
            </div>
    </div>
  );
};
export default LoanExpectedRightPanel;
