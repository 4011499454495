import { gql } from '@apollo/client';

export const SAVE_COBORROWER_BASIC_DETAILS = gql`
    mutation saveCBBasicDetails($cb_basicDetailsInput: SaveCBBasicDetailsInput!) {
        save_cb_basic_details(cb_basic_details_input: $cb_basicDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_COBORROWER_ADDITIONAL_DETAILS = gql`
    mutation saveCBAdditionalDetails($cb_additionalDetailsInput: SaveCBAdditionalDetailsInput!) {
        save_cb_additional_details(cb_additional_details_input: $cb_additionalDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_COBORROWER_EMPLOYMENT_DETAILS = gql`
    mutation saveCBEmploymentDetails($cb_empDetailInput: SaveCBEmploymentDetails!) {
        save_cb_employment_details(cb_employment_details_input: $cb_empDetailInput) {
            success
            error
        }
    }
`;

export const SAVE_COBORROWER_BANK_DETAILS = gql`
    mutation saveCBBankDetails($cb_bankDetailsInput: SaveCBBankDetails!) {
        save_cb_bank_details(cb_bank_details_input: $cb_bankDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_COBORROWER_RESIDENCE_DETAILS = gql`
    mutation saveCBResidentialDetails($cb_residenceDetailsInput: SaveCBResidentialDetails!) {
        save_cb_residential_details(cb_residential_details_input: $cb_residenceDetailsInput) {
            success
            error
        }
    }
`;


