import { gql } from "@apollo/client";

export const SAVE_REFINANCE_CALCULATION = gql`
  mutation SaveRefinanceCalculation($refinanceInput: RefinanceComputationInput!) {
    saveRefinanceCalculation(refinance_calculation_data: $refinanceInput) {
      message
    }
  }
`;

export const SAVE_PAYMENT_VERIFICATION = gql`
  mutation SavePaymentVerification($paymentVerificationInput: PaymentVerificationInput!) {
    savePaymentVerification(payment_verification_data: $paymentVerificationInput) {
      message
    }
  }
`;

export const SAVE_INCENTIVE_STATUS = gql`
  mutation SaveIncentiveDataStatus($incentiveStatusData: IncentiveInputDataStatus!) {
    saveIncentiveDataStatus(incentive_data_status: $incentiveStatusData) {
      message
    }
  }
`;