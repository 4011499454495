
const masterDataUCRFReducer = (state = '', action) => {
    switch (action.type) {
        case "GET_MASTER_DATA_UCRF":
            return {
                ...state,
                master_data_ucrf: action.payload,
            };
        case "GET_UCRF_STATE":
            
            return {
                ...state,
                ucrfStateList: action.state,
            };
        case "GET_UCRF_CITY":
            return {
                ...state,
                ucrfCityList: action.city,
            };
        default:
            return state
    }
}

export default masterDataUCRFReducer;
