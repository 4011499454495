import React,{useState, useEffect} from "react";
import Formsy from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
const MAX_DECIMAL = 14;

const LoanExpectedRightPanel = (props) => {
  // const client = useApolloClient();
  // console.log("==== LoanExpectedRightPanel props====",props.carDetails);
  let {
    car_fmv,
    make_id,
    make_name,
    make_year,
    model_id,
    model_name,
    tenure_in_month,
    variant_id,
    variant_name,
    vehicle_category,
    vehicle_type,
    vehicle_usage,
    r_vehicle_type,
  } = props.carDetails;

  if(!r_vehicle_type){
  r_vehicle_type = props.vehicleTypeOptions.filter(
    (item) => parseInt(item.value) === parseInt(vehicle_type)
  );
  r_vehicle_type = r_vehicle_type?.[0]?.label;
  }

  const [current_fmv, setCurrentFmv] = useState(car_fmv);
  useEffect(() => {
    if(car_fmv !== undefined && car_fmv !== ""){
      setCurrentFmv(car_fmv);
    }
  },[car_fmv]);

  return (
    <div className="loan-form">
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        // onValid={() => setAllowSubmit(true)}
        // onInvalid={() => setAllowSubmit(false)}
        // onValidSubmit={handleCarDetailsSubmit}
      >
        <>
          <div className="form-group-bx m-t-10">
            <h2>Car Details</h2>

            <div className="loan-form-filed-scroll">
              <div className="loan-filed-sec">
                {/* <div className="form-group-bx"></div> */}
                <div className="row">
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="vehicle_type"
                      name="vehicle_type"
                      type="text"
                      // value={vehicle_type}
                      value={r_vehicle_type}
                      placeholder=""
                      label={"Vehicle Type"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="vehicle_usage"
                      name="vehicle_usage"
                      type="text"
                      value={vehicle_usage}
                      placeholder=""
                      label={"Vehicle Usage"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="make"
                      name="make"
                      type="text"
                      value={make_name}
                      placeholder=""
                      label={"Brand"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="model"
                      name="model"
                      type="text"
                      value={model_name}
                      placeholder=""
                      label={"Model"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="variant"
                      name="variant"
                      type="text"
                      value={variant_name}
                      placeholder=""
                      label={"Variant"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="make_year"
                      name="make_year"
                      type="text"
                      value={make_year}
                      placeholder=""
                      label={"Manufacturing Year"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="tenure"
                      name="tenure"
                      type="text"
                      value={tenure_in_month}
                      placeholder=""
                      label={"Tenure"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                      disabled
                    />
                  </fieldset>
                  {/* </div> */}

                  {/* <div className="row"> */}
                  {/* <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="car_fmv"
                      name="car_fmv"
                      type="text"
                      value={car_fmv}
                      placeholder=""
                      label={"FMV of Car"}
                      validationError=""
                      //   required={true}
                      //   showAsterisk={true}
                    />
                  </fieldset> */}
                  {/* </div> */}


                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="car_fmv"
                      name="car_fmv"
                      type="number-format"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      decimalSeparator="."
                      decimalScale={MAX_DECIMAL}
                      value={current_fmv}
                      placeholder=" "
                      label={"FMV Of Car"}
                      // onChange={(e) => {
                      //   handleChange(
                      //     e,
                      //     financierId,
                      //     "loan_details",
                      //     "loan_amount",
                      //     "number-format"
                      //   );
                      // }}
                      // onChange={(e) => {
                      //   handleChange(e, "car_fmv", "number-format");
                      // }}
                      // validations={{ isNumeric: true }}
                      // validationError="Enter Valid  Amount"
                      // readOnly={false}
                      // required={true}
                      // showAsterisk={true}
                      rule={"max16DigitValidation"}
                      disabled
                    />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </>
        <div class="btn-save-remarks">
          {/* {(isSubmitDisabled || props.editButtonCondition || props.markAsFreezed || props.accessCondition) ? <span className="span-disabled">Save</span> :
          <button type="submit" class="btn-primary" disabled={isSubmitDisabled || props.editButtonCondition || props.markAsFreezed || props.accessCondition}>
            Save
          </button>
        }
          <button type="button" class="btn-line" onClick={showModalViewTimeLine}>
            Remarks <span className="m-xs-l">({props.remarksHistory && (props.remarksHistory.length || 0)})</span>
          </button> */}
        </div>
      </Formsy>

      <div className="view-timeline-popup">
        {/* <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                <div className="modal-header">
                <h2>Remark History</h2>
                </div>
                <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={props.markAsFreezed} accessCondition={props.accessCondition} sectionInfo={sectionInfo}/>
            </Modal> */}
      </div>
    </div>
  );
};
export default LoanExpectedRightPanel;
