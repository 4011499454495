import { gql } from '@apollo/client';
export const GET_MASTER_DATA = (vehicle_type=0)=> gql`{
	masterdata(api_called_by: web,vehicle_type:${vehicle_type}) {
      vehicle_usage {
          id
          label
          value
          vehicle_type {
              id
              label
              value
          }
      }
      qualification {
          id,
          label,
          value
      },
     
      duration_of_stay {
          id,
          label,
          value
      },
      make_year {
          id,
          label,
          value
      },
      dp_value {
          id,
          label,
          value
      },
      date_type {
          id,
          label,
          value
      },
      customer_type {
          id,
          label,
          value
      },
      marital_status {
          id,
          label,
          value
      },
      legal_form {
        id,
        label,
        value
    },
      nationality {
          id,
          label,
          value
      },
      bank_list {
        id,
        label,
        value
      }
      
      tenure {
        id,
        label,
        value
      }
      sub_status_dates{
        label
        value
      }
	}
}
`;

export const GET_MASTER_DATA_MMV = (vehicle_type=0)=> gql`{
	masterdata(api_called_by: web,vehicle_type:${vehicle_type}) {
      vehicle_usage {
          id
          label
          value
          vehicle_type {
              id
              label
              value
          }
      }
      make {
        make
        id
      }
      model {
        m
        id
        mk_id
        s_year
        e_year
      }
      version {
        vn
        vn_id
        mk_id
        md_id
        s_year
        e_year
        c_b_type
      }
	}
}
`;

export const GET_BANK_LIST = () => { 
  return gql`query{
    bank_list{
      id
      label
      value
    }
  }`
}