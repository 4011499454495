import { gql } from '@apollo/client';

export const SAVE_CUSTOMER_BASIC_DETAILS = gql`
    mutation saveBasicDetails($basicDetailsInput: SaveBasicDetailsInput!) {
        save_customer_basic_details(basic_details_input: $basicDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_ADDITIONAL_DETAILS = gql`
    mutation saveAdditionalDetails($additionalDetailsInput: SaveAdditionalDetails!,$insuranceDetailsInput: SaveInsuranceDetails!) {
        save_customer_additional_details(additional_details_input: $additionalDetailsInput, insurance_details_input: $insuranceDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_EMPLOYMENT_DETAILS = gql`
    mutation saveEmploymentDetails($empDetailInput: SaveEmploymentDetails!) {
        save_customer_employment_details(employment_details_input: $empDetailInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_BANK_DETAILS = gql`
    mutation saveBankDetails($bankDetailsInput: SaveBankDetails!) {
        save_customer_bank_details(bank_details_input: $bankDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_RESIDENCE_DETAILS = gql`
    mutation saveResidentialDetails($residenceDetailsInput: SaveResidentialDetails!) {
        save_customer_residential_details(residential_details_input: $residenceDetailsInput) {
            success
            error
        }
    }
`;

export const SAVE_CUSTOMER_CHARACTER_REF_DETAILS = gql`
    mutation saveCharacterRefDetails($characterRefInput: SaveCharacterRefDetails!) {
        save_customer_character_ref_details(character_ref_details: $characterRefInput) {
            success
            error
        }
    }
`;

export const UCRF_SAVE_CUSTOMER_BASIC_DETAILS = gql`
mutation CreateBasicLead(
    $tab_id: Float!
    $basic_details: BasicLeadDetailInput!
    $user_id: Float!
    $role_id: Float!
    $lead_id: Float
  ) {
    saveLeadDetails(
      tab_id: $tab_id
      basic_details: $basic_details
      user_id: $user_id
      role_id: $role_id
      lead_id: $lead_id
    ) {
      lead_id
      is_duplicate
      message
    }
  }
`;

export const UCRF_UPDATE_CUSTOMER_BASIC_DETAILS = gql`
mutation updateLeadDetails(
    $lead_data: UpdateLeadDetailInput!
  ) {
    updateLeadDetails(
        lead_data: $lead_data
    ) {
      lead_id
    }
}
`;

export const UCRF_UPDATE_CI_INVESTIGATION_DATA = gql`
mutation SaveCreditInvestigationData(
    $lead_data: LeadProcessDataInput!
  ) {
    saveCreditInvestigationData(
        lead_data: $lead_data
    ) {
      lead_id
    }
}
`;

