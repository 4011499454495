import { useEffect, useRef, useState } from "react";
import Loader from "../../elements/Loader";
import Formsy from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from "../../../store/action/allAction";
import { executeGraphQLMutation } from "../../../services/refinance/executeGraphQLQuery";
import { SAVE_PAYMENT_VERIFICATION } from "../../../services/refinance/refinance-computation.gql";
import DocumentGallery from "../DocsUpload/DocumentGallery";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from "../../../config/constants";
import LeadRemarkHistory from "../lead/component/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import FormsySelect from "../../elements/FormsySelect";
import { LOST_STATUS, ON_HOLD, PAYMENT_VERFICATION } from "../../../config/refinance/constant";

/**
 * Recursively removes '__typename' key from all objects and arrays
 * @param {*} data - The input data (object, array, or primitive)
 * @returns {*} - The data with all '__typename' keys removed
 */
const removeTypename = (data) => {
    if (Array.isArray(data)) {
        return data.map((item) => removeTypename(item));
    } else if (data !== null && typeof data === "object") {
        const newObj = {};
        for (const [key, value] of Object.entries(data)) {
            if (key !== "__typename") {
                newObj[key] = removeTypename(value);
            }
        }
        return newObj;
    }
    return data;
};

const MAX_DECIMAL = 14;
const now = new Date();
const currhour = Number(now.getHours());
const currmin = now.getMinutes();
let maxTime = now.setHours(currhour, currmin, 0);

const RefinancePaymentVerification = (props) => {
    let { lead_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const { master_data_ucrf, leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadDetail: lead?.ucrfLeadDetail || {},
    }));
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [paymentVerification, setPaymentVerification] = useState({});
    const [disableBank, setDisableBank] = useState(true);
    const [isSaveDisable, setIsSaveDisabled] = useState(true);
    const [isRemarkDisable, setIsRemarkDisabled] = useState(true);
    const [isNextEnable, setIsNextEnabled] = useState(false);
    const [bankAccountOptions, setBankAccountOptions] = useState([]);

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);

    const navigateToNext = () => {
        navigate(`/refinance/lead-detail/incentive-paid/${lead_id}`);
    };

    const handleChange = (event, fieldName, fieldType) => {
        let tempPaymentData = { ...paymentVerification };
        let newValue;
        if (["number-format", "select"].includes(fieldType)) {
            newValue = event.value;
        } else if (["input"].includes(fieldType)) {
            newValue = event.target.value;
        }
        if (newValue) {
            if (fieldName === "bank_account") {
                tempPaymentData["bank_id"] = event.bank_id;
                tempPaymentData["bank_account"] = newValue;
                tempPaymentData["bank_name"] = event.bank_name;
                tempPaymentData["branch_name"] = event.branch_name;
                tempPaymentData["account_number"] = event.account_number;
                tempPaymentData["account_name"] = event.account_name;
            } else if (fieldName === "bank_name") {
                tempPaymentData["bank_name"] = event.label;
                tempPaymentData["bank_id"] = newValue;
            } else {
                tempPaymentData[fieldName] = newValue;
            }
            setPaymentVerification(tempPaymentData);
        }
    };

    useEffect(() => {
        if (leadDetail?.payment_verification) {
            let copiedData = JSON.parse(JSON.stringify(leadDetail.payment_verification));
            copiedData = removeTypename(copiedData);
            const formattedBankList = copiedData?.bank_details?.map((bank) => ({
                value: `${bank.account_number} - ${bank.branch_name}`,
                label: `${bank.account_number} - ${bank.branch_name}`,
                ...bank,
            }));
            setBankAccountOptions(formattedBankList || []);

            const primaryBank =
                copiedData?.bank_details?.find((bank) => copiedData?.bank_account === `${bank.account_number} - ${bank.branch_name}`) ||
                copiedData?.bank_details?.[0];
            console.log('effect_payment_verification', primaryBank, leadDetail.payment_verification);
            if (primaryBank) {
                setPaymentVerification({
                    ...copiedData,
                    bank_id: primaryBank.bank_id,
                    bank_name: primaryBank.bank_name,
                    branch_name: primaryBank.branch_name,
                    account_number: primaryBank.account_number,
                    account_name: primaryBank.account_name,
                    bank_account: `${primaryBank.account_number} - ${primaryBank.branch_name}`,
                });
            } else {
                setPaymentVerification(copiedData);
            }
        }

        if (leadDetail?.basic_details?.source_type === "agent") {
            setDisableBank(false);
        }
        checkSaveEnable(leadDetail);
        dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: "WEB" }, client));
    }, [leadDetail]);

    const handleSave = async () => {
        setLoading(true);
        const paymentData = {
            tin_number: paymentVerification.tin_number || "",
            accreditation_date: paymentVerification.accreditation_date || "0000-00-00",
            total_incentive_amount: paymentVerification.total_incentive_amount || "",
            total_incentive_amount_net_tax: paymentVerification.total_incentive_amount_net_tax || "",
            incentive_amount: paymentVerification.incentive_amount || "",
            di_amount: paymentVerification.di_amount || "",
            di_tax_amount: paymentVerification.di_tax_amount || "",
            bank_id: paymentVerification.bank_id || 0,
            bank_name: paymentVerification.bank_name || "",
            branch_name: paymentVerification.branch_name || "",
            account_number: paymentVerification.account_number || "",
            account_name: paymentVerification.account_name || "",
            lead_id: leadDetail?.lead_id?.toString() || "0",
        };

        const variables = { paymentVerificationInput: paymentData };
        console.log('payment_verification_data ======================>', variables);
        try {
            const response = await executeGraphQLMutation(SAVE_PAYMENT_VERIFICATION, variables, client);

            if (response?.data) {
                toast.success(response.data.savePaymentVerification.message);

                await Promise.all([
                    dispatch(getUCRFLeadDetail(leadDetail.lead_id, client)),
                    dispatch(getUCRFFormFillStatus({ lead_id: leadDetail.lead_id }, client)),
                ]);

                navigateToNext();
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = { section: "Payment Verification", sub_section: "" };

    const submitForm = () => {
        if (formRef.current) {
            formRef.current.validateForm();
            if (formRef.current.state.isValid) {
                formRef.current.submit();
            } else {
                toast.error("Please correct the errors in the form");
            }
        }
    };

    const checkSaveEnable = (leadDetail) => {
        let markAsFreezed = 0;
        let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
        let user_page_access = IS_USER_ACCESS_PAGE("refinancePaymentVerification", "ucrf-payment-verification")?.is_edit_access;
        let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to);
        let nextEnable =
            leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex((e) => e == PAYMENT_VERFICATION) > -1
                ? true
                : false;
        let isHold =
            leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex((e) => e == ON_HOLD) > -1 ? true : false;
        if (nextEnable && !isHold) {
            setIsNextEnabled(true);
            setIsSaveDisabled(true)
        } else if ((!user_edit_access && user_page_access && !leadLost && !markAsFreezed && !nextEnable) || isHold) {
            setIsSaveDisabled(false);
            setIsNextEnabled(false)
        } else {
            setIsSaveDisabled(true)
            setIsNextEnabled(false)
        }
        if (!user_edit_access && user_page_access) {
            setIsRemarkDisabled(false);
        }
    };

    return (
        <>
            <div className="image-form-outer">
                {loading ? <Loader /> : ""}
                <div className="image-tab-panel">
                    <DocumentGallery documentCategoryList={documentCategoryList} />
                </div>
                <div className="form-tab-right-panel">
                    <div className="lead-detail-from-outer">
                        <div className={" switch-toggle"}>
                            <div className="lead-form-heading">
                                <h2>Payment Verification</h2>
                                <Formsy className="lead-form-filed soft-approval-filled-outer" autoComplete="off" ref={formRef} onSubmit={handleSave}>
                                    <div className="row">
                                        {["dealer"].includes(paymentVerification?.source_type) ? (
                                            <>
                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="tin_number"
                                                        name="tin_number"
                                                        type="text"
                                                        value={paymentVerification?.tin_number || ""}
                                                        placeholder=" "
                                                        label={"TIN Number"}
                                                        onChange={(e) => {
                                                            handleChange(e, "tin_number", "input");
                                                        }}
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Provide Valid TIN Number"
                                                        readOnly={true}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyDatePicker
                                                        col="col-md-12"
                                                        id="accreditation_date"
                                                        name="accreditation_date"
                                                        selectedDate={new Date()}
                                                        value={paymentVerification?.accreditation_date}
                                                        placeholder={"Accreditation Date"}
                                                        maxDate={new Date()}
                                                        minTime={now.setHours(0, 0, 0)}
                                                        maxTime={maxTime}
                                                        dateFormat="dd MMM, yyyy"
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Enter Valid Date"
                                                        showTimeSelect={true}
                                                        required={true}
                                                        readOnly={true}
                                                        showAsterisk={true}
                                                        disabled={true}
                                                        hasError={""}
                                                        clearDate={false}
                                                    />
                                                </fieldset>
                                            </>
                                        ) : null}

                                        <fieldset class="form-filed col-md-6 ">
                                            <FormsyInputField
                                                id="di_amount"
                                                name="di_amount"
                                                type="number-format"
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator=","
                                                decimalSeparator="."
                                                decimalScale={MAX_DECIMAL}
                                                value={Math.abs(+paymentVerification?.di_amount) || ""}
                                                placeholder=" "
                                                label={"DI Amount from Financier to Carmudi"}
                                                onChange={(e) => {
                                                    handleChange(e, "di_amount", "number-format");
                                                }}
                                                readOnly={true}
                                                validations={"ucrfMaxAmountLength"}
                                                required={true}
                                                showAsterisk={true}
                                            />
                                        </fieldset>

                                        <fieldset className="form-filed col-md-6">
                                            <FormsyInputField
                                                id="di_tax_amount"
                                                name="di_tax_amount"
                                                type="number-format"
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator=","
                                                decimalSeparator="."
                                                decimalScale={MAX_DECIMAL}
                                                value={Math.abs(+paymentVerification?.di_tax_amount) || ""}
                                                placeholder=" "
                                                label="DI Amount from Financier to Carmudi Net of Tax"
                                                onChange={(e) => handleChange(e, "di_tax_amount", "number-format")}
                                                readOnly={true}
                                                required={true}
                                                validations={"ucrfMaxAmountLength"}
                                                showAsterisk={true}
                                            />
                                        </fieldset>

                                        {leadDetail?.computation_details?.pass_through?.dealer_incentive_amount === "no" &&
                                            ["dealer", "agent"].includes(paymentVerification?.source_type) ? (
                                            <>
                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyInputField
                                                        id="total_incentive_amount"
                                                        name="total_incentive_amount"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={Math.abs(+paymentVerification?.total_incentive_amount) || ""}
                                                        placeholder=" "
                                                        label="Total Incentive Amount"
                                                        onChange={(e) => handleChange(e, "total_incentive_amount", "number-format")}
                                                        readOnly={true}
                                                        validations={"ucrfMaxAmountLength"}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyInputField
                                                        id="total_incentive_amount_net_tax"
                                                        name="total_incentive_amount_net_tax"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={Math.abs(+paymentVerification?.total_incentive_amount_net_tax) || ""}
                                                        placeholder=" "
                                                        label="Total Incentive Amount Net of Tax"
                                                        validations={"ucrfMaxAmountLength"}
                                                        onChange={(e) => handleChange(e, "total_incentive_amount_net_tax", "number-format")}
                                                        readOnly={true}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>
                                            </>
                                        ) : null}
                                    </div>

                                    {leadDetail?.computation_details?.pass_through?.dealer_incentive_amount === "no" &&
                                        ["dealer", "agent"].includes(paymentVerification?.source_type) ? (
                                        <>
                                            <h2>Incentive Amount</h2>
                                            <div className="row">
                                                {["dealer"].includes(paymentVerification?.source_type) ? (
                                                    <fieldset className="single-select col-md-6">
                                                        <FormsySelect
                                                            value={
                                                                bankAccountOptions
                                                                    ? bankAccountOptions.filter(({ value }) => value === paymentVerification.bank_account)
                                                                    : []
                                                            }
                                                            onChange={(e) => handleChange(e, "bank_account", "select")}
                                                            options={bankAccountOptions?.length ? bankAccountOptions : []}
                                                            name="bank_account"
                                                            id="bank_account"
                                                            inputProps={{
                                                                isDisabled: paymentVerification?.is_payment_verification_filled === 1,
                                                                options: bankAccountOptions?.length ? bankAccountOptions : [],
                                                                placeholder: "Bank Account",
                                                                className: "react-select",
                                                                classNamePrefix: "react-select",
                                                                value: bankAccountOptions
                                                                    ? bankAccountOptions.filter(({ value }) => value === paymentVerification.bank_account)
                                                                    : [],
                                                            }}
                                                            required={true}
                                                            showAsterisk={true}
                                                            readOnly={false}
                                                        />
                                                    </fieldset>
                                                ) : null}

                                                <fieldset className="single-select col-md-6">
                                                    <FormsySelect
                                                        value={
                                                            paymentVerification?.bank_id
                                                                ? master_data_ucrf.bank_list.filter(({ value }) => value === paymentVerification.bank_id)
                                                                : ""
                                                        }
                                                        onChange={(e) => handleChange(e, "bank_name", "select")}
                                                        options={master_data_ucrf.bank_list || []}
                                                        name="bank_name"
                                                        id="bank_name"
                                                        disabled={disableBank}
                                                        inputProps={{
                                                            isDisabled: disableBank,
                                                            options: master_data_ucrf.bank_list?.length ? master_data_ucrf.bank_list : [],
                                                            placeholder: "Select Bank Name",
                                                            className: "react-select",
                                                            classNamePrefix: "react-select",
                                                            value: paymentVerification?.bank_id
                                                                ? master_data_ucrf.bank_list.filter(({ value }) => value === paymentVerification.bank_id)
                                                                : "",
                                                        }}
                                                        required={true}
                                                        showAsterisk={true}
                                                        readOnly={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyInputField
                                                        id="branch_name"
                                                        name="branch_name"
                                                        type="text"
                                                        value={paymentVerification?.branch_name || ""}
                                                        placeholder=" "
                                                        label="Branch Name"
                                                        onChange={(e) => handleChange(e, "branch_name", "input")}
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Enter Valid Branch Name"
                                                        disable={disableBank}
                                                        readOnly={disableBank}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyInputField
                                                        id="account_name"
                                                        name="account_name"
                                                        type="text"
                                                        value={paymentVerification?.account_name || ""}
                                                        placeholder=" "
                                                        label="Account Name"
                                                        onChange={(e) => handleChange(e, "account_name", "input")}
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Enter Valid Account Name"
                                                        disable={disableBank}
                                                        readOnly={disableBank}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6">
                                                    <FormsyInputField
                                                        id="account_no"
                                                        name="account_number"
                                                        type="text"
                                                        value={paymentVerification?.account_number || ""}
                                                        placeholder=" "
                                                        label="Account Number"
                                                        onChange={(e) => handleChange(e, "account_number", "input")}
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Enter Valid Account Number"
                                                        disable={disableBank}
                                                        readOnly={disableBank}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-12">
                                                    <FormsyInputField
                                                        id="incentive_amount"
                                                        name="incentive_amount"
                                                        type="text"
                                                        value={Math.abs(+paymentVerification?.incentive_amount) || ""}
                                                        placeholder=" "
                                                        label="Incentive Amount"
                                                        onChange={(e) => handleChange(e, "incentive_amount", "input")}
                                                        validations={(rule, value) => !isNaN(parseFloat(value)) && isFinite(value)}
                                                        validationError="Enter Valid Incentive Amount"
                                                        disable={true}
                                                        readOnly={true}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>
                                            </div>
                                        </>
                                    ) : null}
                                </Formsy>
                            </div>
                        </div>
                        <div className="btn-save-remarks">
                            {isSaveDisable ? (
                                <>
                                    {isNextEnable ? (
                                        <button type="button" className="btn-primary" onClick={() => navigateToNext()}>
                                            Next
                                        </button>
                                    ) : (
                                        <span className="span-disabled">Save</span>
                                    )}
                                </>
                            ) : (
                                <button aria-label="save naxt" type="submit" onClick={submitForm} className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory
                            showremarkform={showremarkform}
                            markAsFreezed={getContext.markAsFreezedNewFlow}
                            isRemarkDisable={isRemarkDisable}
                            sectionInfo={sectionInfo}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default RefinancePaymentVerification;
