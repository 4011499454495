import { useEffect, useState } from "react";
import FormsyInputField from "../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import Formsy from "formsy-react";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";
const MAX_DECIMAL = 14;

const LoanExpectedVehicalDetails = ({
  carDetails,
  allOptions,
  handleChange,
  makeOptions,
  modelOptions,
  versionOptions,
  tenure,
  handleSave,
  allCarDetailsFilled,
  vehicleTypeOptions,
  currentFmv,
  isSaveDisable,
  olderFMV,
  fetchNewQuote,
  editQuote,
  selectionquotes,
}) => {
  // console.log("=====fetchNewQuote=====", fetchNewQuote);

  const [current_fmv, setCurrentFmv] = useState(carDetails?.car_fmv);
  useEffect(() => {
    if (carDetails?.car_fmv !== undefined && carDetails?.car_fmv !== "") {
      setCurrentFmv(carDetails.car_fmv);
    } else if (currentFmv) {
      setCurrentFmv(currentFmv);
    }
  }, [carDetails?.car_fmv, currentFmv]);

  // console.log("========currentFmv======", currentFmv);
  return (
    <div className="image-form-outer">
      {/* {loading ? <Loader /> : null} */}
      <div className="loan-form">
        <div className="heading-top">
          <span>Vehicle Details</span>
        </div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValidSubmit={console.log()}
          aria-label="lead form field personal detail"
        >
          <div className="loan-form-filed-scroll">
            <div className="loan-filed-sec">
              <div className="form-group-bx">
                <div className="row">
                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"vehicle_usage"}
                      id={"vehicle_usage"}
                      inputProps={{
                        options: allOptions?.vehicle_usage?.length
                          ? allOptions.vehicle_usage
                          : [],
                        placeholder: "Vehicle Useage",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: allOptions?.vehicle_usage?.length
                          ? allOptions.vehicle_usage.filter(
                              (item) => item.value === carDetails.vehicle_usage
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "vehicle_usage", "select");
                      }}
                    />
                  </fieldset>

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"vehicle_type"}
                      id={"vehicle_type"}
                      inputProps={{
                        options: vehicleTypeOptions?.length
                          ? vehicleTypeOptions
                          : [],
                        placeholder: "Vehicle Type",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: vehicleTypeOptions?.length
                          ? vehicleTypeOptions.filter(
                              (item) =>
                                parseInt(item.value) ===
                                parseInt(carDetails.vehicle_type)
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "vehicle_type", "select");
                      }}
                    />
                  </fieldset>

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"make_id"}
                      id={"make_id"}
                      inputProps={{
                        options: makeOptions?.length ? makeOptions : [],
                        placeholder: "Brand",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: makeOptions?.length
                          ? makeOptions.filter(
                              (item) =>
                                parseInt(item.value) ===
                                parseInt(carDetails.make_id)
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "make_id", "select");
                      }}
                    />
                  </fieldset>

                  {parseInt(carDetails?.make_id) === 0 ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="make_name"
                        name="make_name"
                        type="text"
                        value={carDetails.make_name}
                        placeholder=""
                        label={"Others"}
                        onChange={(e) => {
                          handleChange(e, "make_name", "input");
                        }}
                        validationError=""
                        required={true}
                        showAsterisk={true}
                      />
                    </fieldset>
                  ) : null}

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"model_id"}
                      id={"model_id"}
                      inputProps={{
                        options: modelOptions?.length ? modelOptions : [],
                        placeholder: "Model",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: modelOptions?.length
                          ? modelOptions.filter(
                              (item) =>
                                parseInt(item.value) ===
                                parseInt(carDetails.model_id)
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "model_id", "select");
                      }}
                    />
                  </fieldset>

                  {parseInt(carDetails?.model_id) === 0 ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="model_name"
                        name="model_name"
                        type="text"
                        value={carDetails.model_name}
                        placeholder=""
                        label={"Others"}
                        onChange={(e) => {
                          handleChange(e, "model_name", "input");
                        }}
                        validationError=""
                        required={true}
                        showAsterisk={true}
                      />
                    </fieldset>
                  ) : null}

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"variant_id"}
                      id={"variant_id"}
                      inputProps={{
                        options: versionOptions?.length ? versionOptions : [],
                        placeholder: "Variant",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: versionOptions?.length
                          ? versionOptions.filter(
                              (item) =>
                                parseInt(item.value) ===
                                parseInt(carDetails.variant_id)
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "variant_id", "select");
                      }}
                    />
                  </fieldset>

                  {parseInt(carDetails?.make_id) === 0 ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="variant_name"
                        name="variant_name"
                        type="text"
                        value={carDetails.variant_name}
                        placeholder=""
                        label={"Others"}
                        onChange={(e) => {
                          handleChange(e, "variant_name", "input");
                        }}
                        validationError=""
                        required={true}
                        showAsterisk={true}
                      />
                    </fieldset>
                  ) : null}

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"make_year"}
                      id={"make_year"}
                      inputProps={{
                        options: allOptions?.make_year?.length
                          ? allOptions.make_year
                          : [],
                        placeholder: "Manufacturing Year",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: allOptions?.make_year?.length
                          ? allOptions.make_year.filter(
                              (item) =>
                                parseInt(item.value) ===
                                parseInt(carDetails?.make_year)
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "make_year", "select");
                      }}
                    />
                  </fieldset>

                  <fieldset class="single-select col-md-6">
                    <FormsySelect
                      name={"tenure_in_month"}
                      id={"tenure_in_month"}
                      inputProps={{
                        options: tenure?.length ? tenure : [],
                        placeholder: "Tenure (In Months)",
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value: allOptions?.vehicle_usage?.length
                          ? tenure.filter(
                              (item) =>
                                item.value ===
                                carDetails.tenure_in_month?.toString()
                            )
                          : [],
                      }}
                      required={true}
                      showAsterisk={true}
                      onChange={(e) => {
                        handleChange(e, "tenure_in_month", "select");
                      }}
                    />
                  </fieldset>

                  {/* <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="car_fmv"
                      name="car_fmv"
                      type="text"
                      value={carDetails.car_fmv}
                      placeholder=""
                      label={"FMV Of Car"}
                      onChange={(e) => {
                        handleChange(e, "car_fmv", "input");
                      }}
                      validationError=""
                      required={true}
                      showAsterisk={true}
                    />
                  </fieldset> */}

                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id="car_fmv"
                      name="car_fmv"
                      type="number-format"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      decimalSeparator="."
                      decimalScale={MAX_DECIMAL}
                      value={current_fmv}
                      placeholder=" "
                      label={"FMV Of Car"}
                      onChange={(e) => {
                        handleChange(e, "car_fmv", "number-format");
                      }}
                      // validations={{ isNumeric: true }}
                      // validations={"ucrfMaxAmountLength"}
                      rule={"max16DigitValidation"}
                      validationError="Enter Valid  Amount"
                      readOnly={false}
                      // required={true} /comment out the code to fixed save button issue/
                      showAsterisk={true}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          {!isSaveDisable && (
            <div className="btn-save-remarks">
              <button
                type="submit"
                className="btn-primary"
                disabled={!allCarDetailsFilled}
                onClick={handleSave}
              >
                Save & Continue
              </button>
              {selectionquotes && selectionquotes.length > 0 && (
                <button
                  type="submit"
                  className="btn-primary"
                  // disabled={!allCarDetailsFilled}
                  onClick={editQuote}
                >
                  Cancel
                </button>
              )}
            </div>
          )}
        </Formsy>
      </div>
    </div>
  );
};

export default LoanExpectedVehicalDetails;
