export function executeGraphQLQuery(query, client, variables = {}) {
  // if(header!==''){
  //   const user_info = JSON.parse(localStorage.getItem('user_information'));
  //   let user_data = user_info?.user_data;
  //   user_data = JSON.stringify(user_data);

  // }

  return client.query({
    query: query,
    context: {
      targetPath: 'ph-refinance/api',
      // headers: {
      //   "authkey": AUTH_KEY
      // }
    },
    variables
  })
    .then(res => {
      if ((res?.errors?.[0]?.statusCode === 403 && !(res?.errors?.[0]?.message == 'Invalid Access') || res?.errors?.[0]?.message == "Unable to update. The lead has already been marked as lost.") ||
      res?.errors?.[0]?.message.includes('Invalid Token')) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return res;
    })
    .catch(err => {
      const { networkError } = err;
      if (networkError.statusCode === 401) {
        localStorage.clear()
        window.location.href = '/login';
      }
    });
}


export function executeGraphQLMutation(mutation, variables, client) {

  return client.mutate({
    mutation: mutation,
    context: {
      targetPath: 'ph-refinance/api',
      // headers: {
      //   "authkey": AUTH_KEY
      // }
    },
    variables,
  })
}
