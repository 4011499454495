import { useCallback } from 'react';

export const useAiIcon = (primaryData, secondaryData) => {
  const showAiIcon = useCallback((fieldName) => {
    if (primaryData?.[fieldName] && secondaryData?.[fieldName] && primaryData[fieldName] === secondaryData[fieldName]) {
      return true;
    }
    if (primaryData?.[fieldName]) {
      return false;
    }
    if (secondaryData?.[fieldName]) {
      return true;
    }
    return false;
  }, [primaryData, secondaryData]);

  return { showAiIcon };
};