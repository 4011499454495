import React from 'react';
import SendDoc from './SendDoc';
import { useSelector } from 'react-redux';

const CreditInvestigation = (props) => {
  const pagetab=[{key:'Customer Details',is_co_borrower:0,type:"borrower",doc:"1,85,2,3,4,5,6"},{key: 'Co-Borrower Details',is_co_borrower:1,type:"coborrower",doc:"43,86,44,45,46,47,48"},{key: 'Soft Approval Received',is_co_borrower:2,type:"softapproval",doc:"87"},{key: 'Credit Investigation',is_co_borrower:2,type:"credit-investigation",doc:"89"}];
  const {  lead} = useSelector(({masterdata, lead}) => ({ lead }));
  const sectionInfo = {section : "Credit investigation" , sub_section : ""}
    return (
      <>
        <SendDoc template_title="Credit Investigation Sent" template_for="credit_investigation" pagetab={pagetab} is_completed={lead?.leadDetail?.tab_status?.credit_advice_received?.credit_document_received?.is_completed} sectionInfo={sectionInfo}/>
      </>

    )
}


export default CreditInvestigation;