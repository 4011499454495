import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Tab, Nav } from 'react-bootstrap';
import UploadedDocumentList from './UploadedDocumentList';
import UploadedTagDocs from './UploadedTagDocs';
import UploadedDocContentList from './UploadedDocContentList';
import Loader from '../../elements/Loader';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import LeadHelper from "../LeadHelper";

const UploadDocumentview = (props) => {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [activeDocTab, setActiveDocTab] = useState(props.activeDocTab);
    const [activeTab] = useState(props.activeTab);
    const [filterData, setFilterData] = useState({ doc_id: 0, file_id: 0, parent_id: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const { leadDetail, leadFiles } = useSelector(({ lead, user }) => ({
        leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
        userInfo: user.user_information || {},
        leadDetail: lead && lead.leadDetail
    }));
    const [UploadedFiles, setUploadedFiles] = useState([]);
    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);

    useEffect(() => {
        setUploadedFiles(leadFiles)
    }, [leadFiles])

    const handleSelectTab = (eventKey) => {
        setActiveDocTab(eventKey);
        setFilterData({ doc_id: 0, file_id: 0, parent_id: 0 });
    }
    const setFilter = (params) => {
        setFilterData(params);
        setActiveDocTab('tagimage');
    }
    const tagDoc = (obj, file_id, filter_doc_list) => {
        let required_doc_ids = LeadHelper.getRequiredDocIds(obj, filter_doc_list, false);
        if (obj.count < obj.max_upload) {
            handleChangeOption(file_id, obj.id, obj.parent_id, required_doc_ids, obj?.financier_id || 0);
        } else {
            toast.error('Tagged Already Max Image');
        }

    }

    const removeTag = (obj, file_id, filter_doc_list) => {
        let required_doc_ids = LeadHelper.getRequiredDocIds({id : obj.doc_id}, filter_doc_list, true);        
        handleChangeOption(file_id, 0, 0, required_doc_ids, obj?.financier_id || 0);
    }

    const handleChangeOption = (id, doc_id, parent_id, required_doc_ids, financier_id=0) => {
        let credit_advice_data = {}
        let UploadedFilesNew = UploadedFiles.map((v) => {
            if (v.id === id) {
                v.doc_id = doc_id;
                v.parent_doc_id = parent_id
                v.financier_id = financier_id
            }
            return v;
        });
        setUploadedFiles(UploadedFilesNew);
        setIsLoading(true);

        if(doc_id === 96 && parent_id === 95){
            credit_advice_data = {
                email: authUser?.email || "",
                user_name: authUser?.name || "",
                financier_name : leadDetail?.financier?.find(fn => fn.is_proceed === "yes")?.financier?.financierlang?.financier_name || "",
                borrower_name : leadDetail?.customer?.name || "",
                plate_number: leadDetail?.lead_details?.plate_number || "",
                credit_advice_received_date : leadDetail?.lead_status_dates?.custom_credit_advice_received_date || null,
            }
        }
        let variables = {
            updateDocInput: {
                id: id,
                lead_id: leadDetail.id,
                parent_doc_id: parent_id,
                doc_id: doc_id,
                doc_source: 'web',
                tab_name: activeTab || "",
                status: '1',
                user_id: authUser && (authUser.id || 0),
                required_doc_ids: required_doc_ids,
                financier_id: financier_id || 0,
                credit_advice_data : credit_advice_data || null
            }
        };

        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                id,
                lead_id
                doc_id
                parent_doc_id
                }
            }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                setFilterData((filterData) => ({ ...filterData, doc_id: 0, file_id: id }))
                toast.success("Success");
                setIsLoading(false);
                dispatch(getLoanDetail(leadDetail.id, client));

                let UploadedFilesNew = UploadedFiles.map((v) => {
                    if (v.id === id) {
                        v.doc_id = doc_id;
                        v.parent_doc_id = parent_id
                        v.financier_id = financier_id
                    }
                    return v;
                });
                setUploadedFiles(UploadedFilesNew);

            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            toast.error(error.toString().replace('ApolloError:',''));
            setIsLoading(false);
        });
    }

    const send4TagFile = (file_id) => {
        setFilterData((filterData) => ({ ...filterData, doc_id: 0, file_id: file_id }))
        setActiveDocTab('tagimage');
    }

    let document_list = props.docCategoryList || [];
    let filter_doc_list = document_list.filter(data => data.tab_name === activeTab);
    let filter_doc_child_list = [];
    filter_doc_list && filter_doc_list.forEach(data => {
        filter_doc_child_list = [...filter_doc_child_list, ...data.child]
    })

    let filterFiles = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));

    let { temp, docList } = useMemo(()=> {
        return LeadHelper.getFilterDocumentList(UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail)
    }, [UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail])

    return (
        <div className="uplaod-view-tabs" key={`${activeTab}${activeDocTab}${filterFiles.length}`}>
            {
                (isLoading) ? <Loader /> : null
            }
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeDocTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={activeDocTab === 'upload'} eventKey="upload">Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={activeDocTab === 'tagimage'} eventKey="tagimage">Tag Image</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane active={activeDocTab === 'upload'} eventKey="upload">
                        <UploadedDocumentList filter_doc_list={filter_doc_list} filterFiles={filterFiles} filter_doc_child_list={filter_doc_child_list} document_list={props.docCategoryList} key={UploadedFiles.length} getDocumentlist={UploadedFiles} send4TagFile={send4TagFile} activeTab={activeTab} markAsFreezed={props.markAsFreezed} />
                    </Tab.Pane>
                    <Tab.Pane active={activeDocTab === 'tagimage'} eventKey="tagimage">
                        <UploadedTagDocs filter_doc_list={filter_doc_list} filter_doc_child_list={filter_doc_child_list} UploadedFiles={UploadedFiles} document_list={document_list} activeTab={activeTab} key={UploadedFiles.length} filterFiles={filterFiles} filterData={filterData} tagDoc={tagDoc} setFilterData={setFilterData} removeTag={removeTag} user_id={authUser?.id || 0} leadDetail={leadDetail} markAsFreezed={props.markAsFreezed} temp= {temp} docList = {docList}/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <div className="document-list-panel">
                <UploadedDocContentList filter_doc_list={filter_doc_list} UploadedFiles={UploadedFiles} document_list={props.docCategoryList} activeTab={activeTab} key={UploadedFiles.length} setFilter={setFilter} leadDetails={leadDetail} markAsFreezed={props.markAsFreezed}/>
            </div>
        </div>
    )
}
export default UploadDocumentview;