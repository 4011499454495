import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withFormsy } from 'formsy-react';
import dateFormat from "dateformat";
function FormsyDatePicker(props) {
    let { selectedDate, onDateSelect, placeholder = "Date", maxDate = "", minDate = "", hasError = false, applyLabelTxtClass = true, allowKeyDown = false,col="",clearDate = true } = props;

    let [ dateUpdated, setDateUpdated ] = useState(false);
    const [ isValid, setIsValid ] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setIsValid(!hasError);
    }, [hasError]);

    useEffect(() => {
        if(props.errorMessage) setErrorMessage(props.errorMessage);
    }, [props.errorMessage]);


    useEffect(() => {
        if (dateUpdated && errorMessage) setIsValid(false);
    }, [dateUpdated, errorMessage]);
    
    const keyboardInput = (e) => {
        setIsValid(true);
        setErrorMessage("");
        if (!allowKeyDown) {
            // To disallow keyboard input
            return e.preventDefault();
        }
        e.target.value = e.target.value && (e.target.value.replace(/[^0-9-]/g, '') || "");

    }

    const dateChangeHandler = (date) => {
        setDateUpdated(true);
        if (date) {
            setIsValid(true);
            setErrorMessage("");
        }
        if (typeof onDateSelect === 'function') {
            onDateSelect(date);
        }
        props.setValue(date);

    }

    const onBlurHandler = (e) => {
        setDateUpdated(true);
        /** Checking for required validation */
        if (props.isRequired && !e.target.value) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }

        /** Checking for date format and min max validation */
        if (e.target.value) {
            let date_regex = /^\d{4}-\d{1,2}-\d{1,2}$/g;
            if (e.target.value.match(date_regex)) {
                let [ month, date ] = e.target.value.split('-');

                /** Validating month and date values */
                if (
                  (Number(month) > 12 || Number(month) < 1) ||
                  (Number(date) > 31 || Number(date) < 1)
                ) {
                  onDateSelect(""); // Clearing date
                  setErrorMessage("Invalid date format");
                  setIsValid(false);
                }
            } else {
                clearDate && onDateSelect(""); // Clearing date
            }

            if (new Date(e.target.value) > new Date(maxDate)) {
                onDateSelect(""); // Clearing date
                setErrorMessage(`Date cannot be greater than ${dateFormat(new Date(maxDate), "yyyy-mm-dd")}`);
                setIsValid(false);
            } else if (new Date(e.target.value) < new Date(minDate)) {
                onDateSelect(""); // Clearing date
                setErrorMessage(`Date cannot be less than ${dateFormat(minDate, "yyyy-mm-dd")}`);
                setIsValid(false);
            } 
        }
    }

    let labeltxtClass = (applyLabelTxtClass && placeholder && placeholder.length && placeholder.length >=22) && col!=="col-md-12" ? 'label-txt':'';
    if (props.isRequired || props.showAsterisk) placeholder += " *";

    return (
            <div className={`material datepicker form-control ${props.isRequired && selectedDate ? (!isValid ? 'error-date' : '') : ""}`}>
                <DatePicker
                selected={selectedDate}
                dateFormat="yyyy-MM-dd"
                onChange={dateChangeHandler}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={maxDate}
                title={placeholder}
                placeholderText={placeholder.length >=22 ? placeholder.substring(0,22)+'...' : placeholder}
                {...props}
                autoComplete="off"
                onKeyDown={keyboardInput}
                onBlur={onBlurHandler}
                />
                <label
                data-label={placeholder}
                className={`form-label datepick-heading `}
                ></label>
                {dateUpdated && errorMessage ? (
                <div className="error-msg">{errorMessage}</div>
                ) : (
                ""
                )}
          </div>
    );
}

export default withFormsy(FormsyDatePicker);