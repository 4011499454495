import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
// import Notifaction from '../component/Notification'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { gql, useApolloClient } from "@apollo/client";
import { IS_USER_ACCESS_PAGE } from "../config/constants";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Navigation = () => {

    const { t } = useTranslation('language');
    const client = useApolloClient();
    const handleLogout = () => {

        const user_information = JSON.parse(localStorage.getItem('user_information'));
        const mutation = gql`
          mutation {
            logout_user(user_id: ${user_information.id})
          }
        `;
      
        client
          .mutate({
            mutation,
          })
          .then(() => {
            localStorage.clear()
            window.location.href = '/login';
            toast.success('Logged Out');
          })
          .catch((error) => {
            localStorage.clear()
            window.location.href = '/login';
            console.error(error);
          });
      };
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information)
    let user_info = JSON.parse(localStorage.getItem("user_information"));
    let login_status = user_info?.user_data?.login_status || 'available';
    let away_time = user_info?.user_data?.away_time || 0;
    const [LoginStatus, setLoginStatus] = useState({login_status: login_status, away_time: away_time});
    
    
    const changeUserLoginStatus = async (login_status={}) => {
        if(Object.keys(login_status).length===0){
            login_status={ login_status: 'available', away_time: 0 }
        }
        let user_info = JSON.parse(localStorage.getItem("user_information"));
        const mutation = gql`mutation{
            update_login_status(user_id:${user_info.user_data.user_id},login_status:"${login_status.login_status}",away_minutes:${login_status.away_time}){
            login_status
            away_minutes
            }
        }`
        document.dispatchEvent(new MouseEvent('click'));
        if(user_info.user_data?.login_status !== login_status.login_status ||
		(user_info.user_data?.login_status === login_status.login_status && user_info.user_data?.away_time !== login_status.away_time)) {
            client.mutate({mutation}).then((res) => {
                if(res?.data) {
                    toast.success("Status Changes Successfully");
                    if(user_info) {
                        user_info.user_data.login_status = login_status.login_status;
                        user_info.user_data.away_time = login_status.away_time;
                        user_info.user_data.away_start_time=new Date();
                        localStorage.setItem("user_information", JSON.stringify(user_info));
                    }
                    setLoginStatus({ login_status: login_status.login_status, away_time: login_status.away_time });
                }
            }).catch((err) => {
                toast.error(err.toString());
            })
        }
	};

    useEffect(function(){
        if(UserInfo?.user_data?.away_time){
            let endtime=new Date(UserInfo.user_data.away_start_time).getTime() + UserInfo.user_data.away_time*60000;
            let starttime=new Date().getTime();
            if(endtime<starttime){
              changeUserLoginStatus()
            }
          }
    },[UserInfo])
        return (
            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                        <NavLink to='/dashboard'>Finance Box</NavLink>
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                                <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li className="dropdownmenu"><NavLink>Manage Panel</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/lead-list">UCF Lead List</NavLink> </li>
                                        <li><NavLink to="/refinance/lead-list">UCRF Lead List</NavLink> </li>
                                        {/* <li><NavLink to="/about">Option1</NavLink> </li>
                                        <li><NavLink to="/about">Option1</NavLink> </li> */}
                                    </ul>
                                </li>
                                <li className="dropdownmenu"><NavLink aria-label="manage dif">DIF</NavLink>
                                    <ul className="submenu">
                                        
                                        <li><NavLink to="/inventory-funding/lead-list" aria-label="manage dif">Inventory Funding</NavLink> </li>
                                        
                                        {/* <li><NavLink to="/inventory-funding/loan-management" >Portfolio Dashboard</NavLink> </li>
                                        <li><NavLink to="/inventory-funding/loan-management/dealer-dashboard" >Dealer Dashboard</NavLink> </li> */}
                                        <li><NavLink to="/inventory-funding/update-financier" >Update Financier</NavLink> </li>
                                        {/* <li><NavLink to="/inventory-funding/bpkp-list" >BPKP Check</NavLink> </li>
                                        <li><NavLink to="/inventory-funding/dealer-notifications" >Notification</NavLink> </li> */}
                                    </ul>
                                </li>
                                <li className="dropdownmenu"><NavLink>Rule Engine</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/rule-engine">Rule Engine</NavLink> </li>
                                        {/* <li><NavLink to="/about">Option1</NavLink> </li>
                                        <li><NavLink to="/about">Option1</NavLink> </li> */}
                                    </ul>
                                </li>
                                <li className="dropdownmenu"><NavLink>More</NavLink>
                                    <ul className="submenu">
                                        {IS_USER_ACCESS_PAGE('role_management','role_management').is_access_page && 
                                            <li><NavLink to="/role-list">Role Management</NavLink> </li>}
                                        {IS_USER_ACCESS_PAGE('financier','financier').is_access_page && 
                                            <li><NavLink to="/financer">Financier List</NavLink> </li>}
                                        {/* <li><NavLink to="/about">Option1</NavLink> </li>
                                        <li><NavLink to="/about">Option1</NavLink> </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        {/* <a className="notification-btn">
                            <Notifaction />

                        </a> */}

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu"><NavLink to="#">
                                    <span className="userarc">
                                        {UserInfo && UserInfo.name?.[0]}
                                    </span>
                                    <div className="username">
                                        <span>{UserInfo && UserInfo.name}</span>
                                        <span className="user-degintion">{UserInfo?.role_names?.join(",") || ""}</span>
                                    </div>
                                </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to = "#" onClick={handleLogout}>Logout</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                        <div className="user-status">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className="status">
                                    <i className={`active-status ${LoginStatus.login_status === 'away' ? 'away-icon' : ''} `}></i>
                                    <div className="status-txt">
                                        <span>{LoginStatus.login_status === 'available' ? 'Active' :
                                            (LoginStatus.login_status === 'offline' ? 'Inactive' : 'Away')}</span>
                                        <span className="sub-status"></span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul className="satus-drop-downlist">
                                    <li className="cursor-pointer">
                                        <i className={LoginStatus.login_status === 'available' ? 'active-status' : 'inactive-status'}></i>
                                        <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'available', away_time: 0 })}>
                                            Active
                                        </span>
                                    </li>
                                    <li className="">
                                        <i className={LoginStatus.login_status === 'away' ? 'active-status' : 'inactive-status'}></i>
                                        <span className="">Set as Away</span>
                                        <ul className="away-time-stamp">
                                            <li className={LoginStatus.login_status === 'away' && LoginStatus.away_time === 30 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 30 })}>
                                                    30 Minutes
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status === 'away' && LoginStatus.away_time === 60 ? 'active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 60 })}
                                                >
                                                    1 Hour
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status === 'away' && LoginStatus.away_time === 120 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 120 })}
                                                >2 Hours
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status === 'away' && LoginStatus.away_time === 240 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 240 })}
                                                >4 Hours</span>
                                            </li>
                                            <li className={LoginStatus.login_status === 'away' && LoginStatus.away_time === 480 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 480 })}
                                                >8 Hours</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                        {/* <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        ENG
                                    </NavLink>
                                    <ul className="submenu">
                                     
                                    </ul>
                                </li>

                            </ul>
                        </div> */}
                    </div>

                </div>

            </div >
        );
    }

export default Navigation;