import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import Loader from '../../elements/Loader';
import { CONTRACT_SIGNED, CUSTOMER_PERSONAL_DETAIL_FILLED, LOST_STATUS, SENT_TO_FINANCIER } from '../../../config/refinance/constant';
const MAX_DECIMAL = 14;

const roundLargeNumber = (num) => {
  if (typeof num !== "string") {
    return num;
  }
  const strNum = num.toString();
  if (strNum?.length > 16) {
    return strNum.substring(0, 16)
  }
  return strNum
};
const RefinanceEmployeeDetailsForm = (props) => {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  const sectionInfo = { section: "Customer Details", sub_section: "Employee Details" }

  useEffect(() => {
    if (leadDetail?.basic_details) {
      if (leadDetail?.employee_details && Object.keys(leadDetail?.employee_details).length) {
        let employeeDetails = { ...leadDetail.employee_details };
        setEmployeeDetails(employeeDetails);
      }
      let basicDetails = { ...leadDetail.basic_details };
      setBasicDetails(basicDetails)
      checkSaveEnable(leadDetail)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));
  }, [leadDetail]);


  let ALL_OPTIONS = useMemo(() => {
    let options = master_data_ucrf ? { ...master_data_ucrf } : {};
    return options;
  }, [master_data_ucrf]);

  const handleInputChange = event => {
    if (event.target.name) {
      setEmployeeDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value !== undefined && field_name) {
      if (field_name == "gross_montly_income") {
        let round_of_number = roundLargeNumber(data.value)
        setEmployeeDetails(currentValue => ({
          ...currentValue,
          [field_name]: round_of_number
        }))
      } else {
        setEmployeeDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setEmployeeDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let reqBody = {
      name_of_employer: employeeDetails.name_of_employer || "",
      business_address: employeeDetails.business_address || "",
      company_mobile_no: employeeDetails.company_mobile_no || "",
      company_office_telephone_no: employeeDetails.company_office_telephone_no || "",
      position: employeeDetails.position || "",
      gross_montly_income: employeeDetails.gross_montly_income || "",
      years_with_the_company: employeeDetails.years_with_the_company || ""
    }
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          tab_id: 4,
          sub_tab_id: 0,
          lead_id,
          auto_save: 0,
          employee_details: reqBody
        },
      };
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/co-personal-details/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-customer-employment-details")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CUSTOMER_PERSONAL_DETAIL_FILLED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Customer Details</span>
            <h2>Employee Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field personal detail"
          >
            <div className="row">
              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="name_of_employer"
                  name="name_of_employer"
                  type="tb"
                  value={employeeDetails?.["name_of_employer"] || ""}
                  placeholder=" "
                  label={"Name of Employer"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Name of Employer"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="business_address"
                  name="business_address"
                  type="tb"
                  value={employeeDetails?.["business_address"] || ""}
                  placeholder=" "
                  label={"Business Address"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Business Address"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="company_mobile_no"
                  name="company_mobile_no"
                  type="mobile"
                  value={employeeDetails?.["company_mobile_no"] || ""}
                  placeholder=" "
                  label="Company's Mobile No"
                  onChange={(data) => handlePatternFormatChange(data, "company_mobile_no")}
                  format={"+63 ### ### ######"}
                  validations={"isValidPhoneNumber"}
                  validationError="Enter Valid Company's Mobile No"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="company_office_telephone_no"
                  name="company_office_telephone_no"
                  type="mobile"
                  value={employeeDetails?.["company_office_telephone_no"] || ""}
                  placeholder=" "
                  label="Company's Office Telephone No"
                  onChange={(data) => handlePatternFormatChange(data, "company_office_telephone_no")}
                  validationError="Enter Valid Company's Office Telephone No"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="position"
                  name="position"
                  type="tb"
                  value={employeeDetails?.["position"] || ""}
                  placeholder=" "
                  label={"Position"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Position"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="gross_montly_income"
                  name="gross_montly_income"
                  type="number-format"
                  value={employeeDetails?.["gross_montly_income"] || ""}
                  placeholder=" "
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={MAX_DECIMAL}
                  label={"Gross Montly Income"}
                  onChange={(data) => handlePatternFormatChange(data, "gross_montly_income")}
                  validationError="Enter Valid Gross Montly Income"
                  readOnly={(isSaveDisable) ? true : false}
                  validations={"ucrfMaxAmountLength"}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"years_with_the_company"}
                  id={"years_with_the_company"}
                  inputProps={{
                    options: ALL_OPTIONS?.["years_with_the_company"],
                    placeholder: "Years with the Company",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS?.["years_with_the_company"] && ALL_OPTIONS?.["years_with_the_company"].filter(({ value }) => value == employeeDetails["years_with_the_company"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={employeeDetails && employeeDetails["years_with_the_company"]}
                  onChange={(data) => handleSelectChange(data, "years_with_the_company")}
                />
              </fieldset>
            </div>

            <div className="btn-save-remarks">
              {(!allowSubmit || isSaveDisable) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceEmployeeDetailsForm;