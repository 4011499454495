import { Tab, Nav } from "react-bootstrap";
import FinancierLoanDetails from "./FinancierLoanDetails";

const RefinanceLoanDetails = ({ loanDetails, handleChange, formRefs, handleSave, isSaveDisable, handleFinancierTabChange, markAsFreezed }) => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="financier-0">
            {loanDetails.size > 0 ? (
                <>
                    <Nav variant="pills" className="flex-column">
                        <div className="tab-list">
                            {Array.from(loanDetails.entries()).map(([key, value], index) => (
                                <Nav.Item key={key}>
                                    <Nav.Link eventKey={`financier-${index}`} onClick={(e) => handleFinancierTabChange(e, key)}>
                                        {value.financier_name}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </div>
                    </Nav>
                    <Tab.Content>
                        {Array.from(loanDetails.entries()).map(([key, value], index) => (
                            <Tab.Pane key={key} eventKey={`financier-${index}`}>
                                <div className="dashboard-card">
                                    <div className="dashboard-card">
                                        <FinancierLoanDetails
                                            financierLoanData={value}
                                            financierId={key}
                                            handleChange={handleChange}
                                            handleSave={handleSave}
                                            formRef={(el) => (formRefs.current[key] = el)}
                                            isSaveDisable={isSaveDisable}
                                            markAsFreezed={markAsFreezed}
                                        />
                                    </div>
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </>
            ) : (
                "No data found"
            )}
        </Tab.Container>
    );
};

export default RefinanceLoanDetails;
