import React from 'react';
import { useSelector } from 'react-redux';
import SendDocORCR from './SendDocORCR';

const OrcrSubmission = (props) => {
    const pagetab=[
      {key:'Customer Details',is_co_borrower:0,type:"borrower",doc:"1,85,2,3,4,5,6"},
      {key: 'Co-Borrower Details',is_co_borrower:1,type:"coborrower",doc:"43,86,44,45,46,47,48"},
      {key: 'Soft Approval Received',is_co_borrower:2,type:"softapproval",doc:"87"},
      {key: 'Credit Investigation',is_co_borrower:2,type:"credit-investigation",doc:"89"},
      {key: 'Credit Document Received',is_co_borrower:2,type:"credit-document-received",doc:"95,97"},
      {key: 'Contract Signing Received',is_co_borrower:2,type:"contract-signing-received",doc:"99"},
      {key: 'Car Handover',is_co_borrower:2,type:"car-handover",doc:"101"},
      {key: 'ORCR Received from dealer',is_co_borrower:2,type:"orcr-received-from-dealer",doc:"103"}, 
      {key: 'ORCR Sent for Title Transfer',is_co_borrower:2,type:"orcr-sent-for-title-transfer",doc:"128"},
      {key: 'ORCR Received After Title Transfer',is_co_borrower:2,type:"orcr-received-after-title-transfer",doc:"130"}];
    const {  lead} = useSelector(({masterdata, lead}) => ({ lead }));
    const sectionInfo = {section : "Vehicle Transfer" , sub_section : "ORCR Submission to Financier"}
    return (
      <>
        <SendDocORCR template_title="ORCR Submission to Financier" template_for="orcr_submission_to_financier" pagetab={pagetab} is_completed={lead?.leadDetail?.tab_status?.vehicle_details?.orcr_submission_to_financier?.is_completed} sectionInfo={sectionInfo}/>
      </>

    )
}


export default OrcrSubmission;