import React, { useEffect, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import FormsyCheckBox from '../../elements/FormsyCheckBox';
import Loader from '../../elements/Loader';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { CONTRACT_SIGNED, LOST_STATUS, SENT_TO_FINANCIER, SOFT_APPROVAL_RECEIVED } from '../../../config/refinance/constant';

const RefinanceSelectFinancierForm = (props) => {
  const [financierDetails, setFinancierDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [financierList, setFinancierList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, leadDetail, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  const sectionInfo = { section: "Soft Approval", sub_section: "Financier Selection" }

  useEffect(() => {
    if (leadDetail?.basic_details) {
      let financierDetails = []
      if (leadDetail?.select_financier?.length) {
        financierDetails = [...financierDetails, ...leadDetail.select_financier]
        setIsDataChanged(true)
      }
      setFinancierDetails(financierDetails);
      checkSaveEnable(leadDetail)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));
  }, [leadDetail]);

  useEffect(() => {
    if (master_data_ucrf?.financier_list) {
      setFinancierList(master_data_ucrf.financier_list);
    }
  }, [master_data_ucrf?.financier_list]);


  const handleCheckboxChange = (event) => {
    let formDetails = [...financierDetails]
    let inputval = event.target.value;
    let allow_select = true
    if (event.target.checked) {
      if (master_data_ucrf?.noTouchPolicyFinancier?.length) {
        let financier_exist = master_data_ucrf.noTouchPolicyFinancier.findIndex(e => e == inputval)
        if (financier_exist > -1) {
          let selected_financier = formDetails.filter(e => master_data_ucrf.noTouchPolicyFinancier.includes(e.financier_id))
          if (selected_financier?.length) {
            allow_select = false
          }
        }
      }
      if (allow_select) {
        formDetails.push({
          financier_id: parseInt(inputval)
        })
      } else {
        toast.error("Same Group Financier Cannot be selected")
      }
    } else {
      let selected_financier = formDetails.filter(e => e.financier_id == inputval)
      if (selected_financier?.length) {
        let soft_approval_status = leadDetail?.soft_approval_status || []
        soft_approval_status = soft_approval_status.filter(e => e.financier_id == selected_financier[0].financier_id)
        if (soft_approval_status?.length == 0 || soft_approval_status[0].soft_approval_status == 0) {
          formDetails = formDetails.filter(e => e.financier_id != inputval)
        }
      }
    }
    if (formDetails.length == 0) {
      setIsDataChanged(false)
    } else {
      setIsDataChanged(true)
    }
    setFinancierDetails(formDetails);
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let selectFinancierReqBody = [...financierDetails]
    selectFinancierReqBody = selectFinancierReqBody.map((ob) => ({
      financier_id: ob.financier_id
    }))
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          tab_id: 2,
          sub_tab_id: 1,
          lead_id,
          auto_save: 0,
          select_financier: selectFinancierReqBody
        },
      };
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/soft-approval-data/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceSoftApproval", "ucrf-soft-approval-received")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SOFT_APPROVAL_RECEIVED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable || leadDetail?.select_financier?.length) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Soft Approval</span>
            <h2>Financier Selection</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field residential details"
          >
            <div className="row">
              <div className='col-md-12 financier_scroll'>
                {financierList?.length > 0 && financierList.map((ele, index) => (
                  <div key={index} className='financier_selected'>
                    <span>
                      <img src={ele.logo} alt='' />
                    </span>
                    <label className='financier_label'>{ele.financier_name}</label>
                    <fieldset className="form-filed">
                      <div className="material">
                        <FormsyCheckBox
                          id={ele.financier_name + "_" + ele.id}
                          name={ele.financier_name}
                          type="checkbox"
                          className="custom-control-input"
                          value={ele.id}
                          checked={financierDetails.length && financierDetails.findIndex(e => e.financier_id == ele.id) > -1 ? true : false}
                          onChange={handleCheckboxChange}
                          disabled={(leadDetail?.soft_approval_status?.length && leadDetail.soft_approval_status.findIndex(e => e.financier_id == ele.id && e.soft_approval_status != 0) > -1) || isSaveDisable ? true : false}
                        />
                      </div>
                    </fieldset>
                  </div>
                ))}
              </div>
            </div>

            <div className="btn-save-remarks p-lg-t">
              {(!allowSubmit || isSaveDisable || !isDataChanged) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            sectionInfo={sectionInfo} isRemarkDisable={isRemarkDisable} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceSelectFinancierForm;