import { gql } from '@apollo/client';
export const CONFIG_QUERY= (template_type, financier_id) => gql`
query {
    get_attributes(attribute_for_template:"${template_type}", financier_id:${financier_id}){
      financier_name
      cohort_list{
        id
        financier_group_name
        status
        template_type
      }
      attributes{
        attribute_name
        attribute_type
        attribute_value
      }
      template_list{
        value
        label
      }
    }
  }`;

export const FINANCIER_CONFIG_QUERY= (template_for) => gql`
query {
    financierList(template_for:"${template_for}") {
          id
          name
          total_publish
          total_unpublish
          total_email
          total_whatsapp
          total_viber
    }
}`;

export const FILTER_TEMPLATE_CONFIG_QUERY= (financier_group_id, financier_id, template_type, main_template_type) => gql`
query {
    sentToFinancierList(financier_group_id:${financier_group_id}, financier_id:${financier_id}, template_type:"${template_type}", main_template_type:"${main_template_type}"){
      id
      financier_id
      financier_group_name
      financier_group_email
      template_type
      main_template_type
      template_body
      status
    }
  }
  `;

export const CONFIG_SAVE_QUERY = ()=> {
    return `mutation saveConfig($configInput: SaveConfigInput!) {
        config_save(configInput: $configInput) {
            success
      }
    }
    `
}

export const GET_REGION_CITY_LIST = () => gql`
  query {
    get_region_city_list {
      region_list {
        id
        label
        value
      }
      city_list {
        id, 
        label
        value,
        state_id
      }
    }
  }
`

export const GET_LOCALITY_LIST = (city_id) => gql`
  query {
    get_locality_list(city_id: ${city_id}) {
      locality_list { 
        id
        label
        value
        zipcode
      }
    }
  }
`

export const GET_DEALER_LIST = gql`
  query{
    get_dealer_list{ 
      dealer_list{
        value
        label
      }
    }
  }
`

export const GET_ROLES_WITH_USERS = gql`
  query{
    get_roles_with_users {
        role_id
        column_name
        role_name
        title
        users {
          id
          name
        }
    }
  }
`

export const GET_ALL_DEALER_LIST = (vertical_id, user_id) => gql`
  query{
    get_all_dealer_list(vertical_id: ${vertical_id}, user_id: ${user_id}){ 
      dealer_list{
        value
        label
        mapped_dealer_id
      }
    }
  }
`

export const GET_INVENTORY_LIST = (dealer_id) => gql`
  query{
    get_inventory_list(dealer_ids: ${dealer_id}){ 
      inventory_list{
        value
        dealer_id
        make_year
        make_id
        model_id
        version_id
        make_name
        model_name
        version_name
        label
        color
      }
    }
  }
`