import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coBorrowerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import FormsyCheckBox from '../../elements/FormsyCheckBox';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_COBORROWER_RESIDENCE_DETAILS } from '../../../services/coborrower';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { GET_REGION_CITY_LIST, GET_LOCALITY_LIST } from '../../../services/config.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS_CRA = FormFields["RESIDENTIAL_DETAILS_CRA"];
const FORM_FIELDS_PA = FormFields["RESIDENTIAL_DETAILS_PA"]

const RESIDENCE_TYPE_OPTIONS = [
  { id: "1", label: "Owned", value: "owned" },
  { id: "1", label: "Rented", value: "rented" },
  { id: "1", label: "Living with Relatives", value: "living_with_relatives" },
]
const ResidenceDetails = (props) => {
    const [residenceDetails, setResidenceDetails] = useState({});
    const [origResidenceDetails, setOrigResidenceDetails] = useState({});
    const [,setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [origCityList, setOrigCityList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [CRACityList, setCRACityList] = useState([]);
    const [CRABarangayList, setCRABarangayList] = useState([]);
    const [PACityList, setPAACityList] = useState([]);
    const [PABarangayList, setPABarangayList] = useState([]);
    const { masterdata, lead, remarksHistory} = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || []
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const context = useOutletContext();
    const documentCategoryList = context.docList || []
    const viberCoborrowerData = context?.viberData?.get_viber_column?.co_borrower || [];
    const { showAiIcon } = useAiIcon(residenceDetails, viberCoborrowerData);
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "co-borrower-residential-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.co_borrower) {
      let originalData = { ...lead.leadDetail.co_borrower };
      let updatedData = { ...originalData };

      if (viberCoborrowerData) {
        Object.keys(viberCoborrowerData).forEach(key => {
          if (!updatedData[key] && viberCoborrowerData[key]) {
            updatedData[key] = viberCoborrowerData[key];
          }
        });
      }

      originalData["cb_pa_sameas_cra"] = originalData["cb_pa_sameas_cra"] === "Yes" ? "1" : "0";
      updatedData["cb_pa_sameas_cra"] = updatedData["cb_pa_sameas_cra"] === "Yes" ? "1" : "0";

      setOrigResidenceDetails(originalData);
      setResidenceDetails(updatedData);
    }
  }, [lead]);

    useEffect(() => {
      getRegionCityList()
    }, []);

    useEffect(() => {
      let region_id = residenceDetails["cb_cra_region"] || 0;
      if (origCityList && origCityList.length && region_id) {
        let city_list = origCityList.filter(v => v.state_id === String(region_id));
        setCRACityList(city_list);
      }
    }, [origCityList, residenceDetails["cb_cra_region"]])

    useEffect(() => {
      let region_id = residenceDetails["cb_pa_region"] || 0; 
      if (origCityList && origCityList.length && region_id) {
        let city_list = origCityList.filter(v => v.state_id === String(region_id));
        setPAACityList(city_list);
      }
    }, [origCityList, residenceDetails["cb_pa_region"]])

    useEffect(() => {
      let city_id = residenceDetails["cb_cra_city"] || 0;
      if (city_id) {
        getLocalityList(city_id, "cb_cra_city")
      }
    }, [residenceDetails["cb_cra_city"]])

    useEffect(() => {
      let city_id = residenceDetails["cb_pa_city"] || 0;
      if (city_id) {
        getLocalityList(city_id, "cb_pa_city")
      }
    }, [residenceDetails["cb_pa_city"]])


    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      options["residence_type"] = RESIDENCE_TYPE_OPTIONS;
      options["region_list"] = regionList || [];
      options["cra_city_list"] = CRACityList || [];
      options["pa_city_list"] = PACityList || []
      options["cra_barangay_list"] = CRABarangayList || [];
      options["pa_barangay_list"] = PABarangayList || []
      return options;
    }, [masterdata,regionList,CRACityList, PACityList, CRABarangayList, PABarangayList]);

    const getRegionCityList = () => {
      executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_region_city_list) {
          let { region_list, city_list } = resp.data.get_region_city_list || [];
          setRegionList(region_list);
          setOrigCityList(city_list);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }

    const getLocalityList = (city_id, field_name) => {
      executeGraphQLQuery(GET_LOCALITY_LIST(city_id), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_locality_list) {
          let { locality_list } = resp.data.get_locality_list || [];
          if (field_name === "cb_cra_city") {
            setCRABarangayList(locality_list)
          } else if (field_name === "cb_pa_city") {
            setPABarangayList(locality_list);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
    }

    const handleInputChange = event => {
        if (event.target.id) {
          let residenceData = {
            ...residenceDetails,
            [event.target.id]: event.target.value || ""
          }
          if (
            residenceData["cb_pa_sameas_cra"] === "1" && 
            [
              "cb_cra_house_number",
              "cb_cra_street",
              "cb_cra_barangay",
              "cb_cra_zipcode",
            ].includes(event.target.id)
          ) {
            residenceData = {
              ...residenceData,
              "cb_pa_house_number": residenceData.cb_cra_house_number || "",
              "cb_pa_street": residenceData.cb_cra_street || "",
              "cb_pa_barangay": residenceData.cb_cra_barangay || "",
              "cb_pa_zipcode": residenceData.cb_cra_zipcode || ""
            }
          }
          setResidenceDetails(residenceData)
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
      if (field_name && data.value !== undefined) {
          setResidenceDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        let residenceData = {
          ...residenceDetails,
          [field_name]: data.value || ""
        }
        if (["cb_cra_region","cb_cra_city"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            cb_cra_zipcode: ""
          }
        }
        if (["cb_pa_region","cb_pa_city"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            cb_pa_zipcode: ""
          }
        }
        if (field_name === "cb_cra_barangay") {
          residenceData = {
            ...residenceData,
            cb_cra_zipcode: data.zipcode
          }
        }
        if (field_name === "cb_pa_barangay") {
          residenceData = {
            ...residenceData,
            cb_pa_zipcode: data.zipcode
          }
        }
        if (residenceData["cb_pa_sameas_cra"] === "1" && ["cb_cra_city","cb_cra_region", "cb_cra_barangay"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            "cb_pa_region": residenceData.cb_cra_region || "",
            "cb_pa_city": residenceData.cb_cra_city || "",
            "cb_pa_barangay": residenceData.cb_cra_barangay || "",
            "cb_pa_zipcode": residenceData.cb_cra_zipcode || ""
          }
        }
        if (field_name === "cb_cra_region") {
          setCRABarangayList([]);
        }
        if (field_name === "cb_pa_region") {
          setPABarangayList([]);
        }
        setResidenceDetails(residenceData)
      }
    }
    
    const handleCheckboxChange = (event) => {
      let formDetails = {...residenceDetails}
      let inputval = event.target.value;
        if(event.target.checked){
            formDetails = {
              ...formDetails,
              "cb_pa_sameas_cra": inputval,
              "cb_pa_house_number": formDetails.cb_cra_house_number || "",
              "cb_pa_street": formDetails.cb_cra_street || "",
              "cb_pa_barangay": formDetails.cb_cra_barangay || "",
              "cb_pa_city": formDetails.cb_cra_city || "",
              "cb_pa_region": formDetails.cb_cra_region || "",
              "cb_pa_zipcode": formDetails.cb_cra_zipcode || ""
            }
        } else {
          formDetails = {
            ...formDetails,
            "cb_pa_sameas_cra": '0',
            "cb_pa_house_number": origResidenceDetails.cb_pa_house_number || "",
            "cb_pa_street": origResidenceDetails.cb_pa_street || "",
            "cb_pa_barangay": origResidenceDetails.cb_pa_barangay || "",
            "cb_pa_city": origResidenceDetails.cb_pa_city || "",
            "cb_pa_region": origResidenceDetails.cb_pa_region || "",
            "cb_pa_zipcode": origResidenceDetails.cb_pa_zipcode || ""
          }
        }

        setResidenceDetails({...formDetails});
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        lead_id: lead_id,
        cb_cra_house_number: residenceDetails.cb_cra_house_number,
        cb_cra_street: residenceDetails.cb_cra_street,
        cb_cra_barangay: Number(residenceDetails.cb_cra_barangay) || null, 
        cb_cra_city: Number(residenceDetails.cb_cra_city) || null ,
        cb_cra_region: Number(residenceDetails.cb_cra_region) || null,
        cb_cra_zipcode: residenceDetails.cb_cra_zipcode,
        cb_pa_house_number: residenceDetails.cb_pa_house_number,
        cb_pa_street: residenceDetails.cb_pa_street,
        cb_pa_barangay: Number(residenceDetails.cb_pa_barangay) || null,
        cb_pa_city: Number(residenceDetails.cb_pa_city) || null ,
        cb_pa_region: Number(residenceDetails.cb_pa_region) || null,
        cb_pa_zipcode: residenceDetails.cb_pa_zipcode,
        cb_pa_sameas_cra: residenceDetails.cb_pa_sameas_cra === "1" ? "Yes" : "No",
        cb_house_type: residenceDetails.cb_house_type,
        cb_duration_of_stay: residenceDetails.cb_duration_of_stay,
        updated_by: user_id || 0
      }

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'co_borrower',
        changed_object: {
          cb_cra_house_number: residenceDetails?.cb_cra_house_number?.toString(),
          cb_cra_street: residenceDetails?.cb_cra_street?.toString(),
          cb_cra_barangay: residenceDetails?.cb_cra_barangay?.toString() || null,
          cb_cra_city: residenceDetails?.cb_cra_city?.toString() || null,
          cb_cra_region: residenceDetails?.cb_cra_region?.toString() || null,
          cb_cra_zipcode: residenceDetails?.cb_cra_zipcode?.toString(),
          cb_pa_house_number: residenceDetails?.cb_pa_house_number?.toString(),
          cb_pa_street: residenceDetails?.cb_pa_street?.toString(),
          cb_pa_barangay: residenceDetails?.cb_pa_barangay?.toString() || null,
          cb_pa_city: residenceDetails?.cb_pa_city?.toString() || null,
          cb_pa_region: residenceDetails?.cb_pa_region?.toString() || null,
          cb_pa_zipcode: residenceDetails?.cb_pa_zipcode?.toString(),
          cb_house_type: residenceDetails?.cb_house_type?.toString(),
          cb_duration_of_stay: residenceDetails?.cb_duration_of_stay?.toString(),
        }
      }
      
      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client).then(resp => {
        console.log(resp)
      }).catch(err => console.log(err))

      let mutation = SAVE_COBORROWER_RESIDENCE_DETAILS, 
          variables = { cb_residenceDetailsInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_cb_residential_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      let isApplicationCreated = lead?.leadDetail?.lead_status_history?.find(history => history.sub_status_id === SUB_STATUS_CODES["APPLICATION_CREATED"]);
      if (isApplicationCreated) {
        navigate(`/lead-detail/send-financier/${lead_id}`);
      }
    }

    let CRADetailsForm = FORM_FIELDS_CRA;

    let PADetailsForm = useMemo(() => {
      let formFields = FORM_FIELDS_PA; 
      if (residenceDetails) {
        formFields = FORM_FIELDS_PA.map(v => {
          v["readOnly"] = residenceDetails["cb_pa_sameas_cra"] === "1" ? true : false;
          return v;
        })
      }
      return formFields;
    }, [residenceDetails])

    // All the conditions can be combined here for button enable/disable
    // const isSubmitDisabled = useMemo(() => {
    //   return lead.leadDetail.sub_status_id===2 ? false : true;
    // }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = {section : "Co-borrower details" , sub_section : "Residential details"}
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;    
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Co-Borrower Details</span>
              <h2>Residence Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-md-12">
                  <h3 className='p-t-0'>Current Address</h3>
                </div>
                
                {CRADetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(residenceDetails && residenceDetails[field.name])}
                        placeholder=" "
                        thousandsGroupStyle="thousand"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        residenceDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        readOnly={origResidenceDetails && origResidenceDetails[field.name] && isSubStatus3Marked ?true : false}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (residenceDetails[field.name])),
                            isDisabled: field.readOnly || (origResidenceDetails[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        value={residenceDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}

                <fieldset className="form-filed col-md-12" style={{marginTop: "10px"}}>
                    <div className="material">
                    <FormsyCheckBox
                        id="cb_pa_sameas_cra"
                        name="cb_pa_sameas_cra"
                        type="checkbox"
                        className="custom-control-input"
                        value="1"
                        label={"Same as Current Address"}
                        checked={residenceDetails['cb_pa_sameas_cra'] === "1" ? true : false}
                        onChange={handleCheckboxChange}
                        disabled={origResidenceDetails['pa_sameas_cra'] === "1" && isSubStatus3Marked ? true : false}
                    />

                    </div>
                </fieldset>
                
              </div>
              <div className='row'>
                <div className="col-md-12">
                    <h3>Permanent Address</h3>
                </div>

                {PADetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(residenceDetails && residenceDetails[field.name])}
                        placeholder=" "
                        {...(field.type === "number-format") ? {
                          thousandsGroupStyle:"thousand",
                          thousandSeparator: ',',
                          decimalSeparator: '.'
                        }: {}}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        residenceDetails[field.name] ? field.validationError : ""
                        }
                        disabled={field.readOnly}
                        required={field.required}
                        readOnly={origResidenceDetails && origResidenceDetails[field.name] && isSubStatus3Marked ?true : false}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (residenceDetails[field.name])),
                            isDisabled: field.readOnly || (origResidenceDetails[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        value={residenceDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}


              </div>

              <div className="btn-save-remarks">
                {accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span> :
                <button aria-label="save next" type="submit" className="btn-primary">
                  Save & Next
                </button> }
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezedNewFlow} accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default ResidenceDetails;