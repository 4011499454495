import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import FormsyCheckBox from '../../elements/FormsyCheckBox';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_CUSTOMER_RESIDENCE_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_REGION_CITY_LIST, GET_LOCALITY_LIST } from '../../../services/config.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS_CRA = FormFields["RESIDENTIAL_DETAILS_CRA"];
const FORM_FIELDS_PA = FormFields["RESIDENTIAL_DETAILS_PA"]

const RESIDENCE_TYPE_OPTIONS = [
  { id: "1", label: "Owned", value: "owned" },
  { id: "1", label: "Rented", value: "rented" },
  { id: "1", label: "Living with Relatives", value: "living_with_relatives" },
]
const ResidenceDetails = (props) => {
    const [residenceDetails, setResidenceDetails] = useState({});
    const [origResidenceDetails, setOrigResidenceDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [origCityList, setOrigCityList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [CRACityList, setCRACityList] = useState([]);
    const [CRABarangayList, setCRABarangayList] = useState([]);
    const [PACityList, setPAACityList] = useState([]);
    const [PABarangayList, setPABarangayList] = useState([]);
    const { masterdata, lead, remarksHistory, leadFiles} = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    const viberPersonalData = getContext?.viberData?.get_viber_column?.personal_details || [];
    const { showAiIcon } = useAiIcon(residenceDetails, viberPersonalData);
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-residential-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.customer) {
        let originalData = { ...lead.leadDetail.customer };
        let updatedData = { ...originalData };

        if (viberPersonalData) {
          Object.keys(viberPersonalData).forEach(key => {
            if (!updatedData[key] && viberPersonalData[key]) {
              updatedData[key] = viberPersonalData[key];
            }
          });
        }

        originalData["pa_sameas_cra"] = originalData["pa_sameas_cra"] === "Yes" ? "1" : "0";
        updatedData["pa_sameas_cra"] = updatedData["pa_sameas_cra"] === "Yes" ? "1" : "0";

        setOrigResidenceDetails(originalData);
        setResidenceDetails(updatedData);
      }
    }, [lead]);

    useEffect(() => {
      getRegionCityList()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      let region_id = residenceDetails["cra_region"] || 0;
      if (origCityList && origCityList.length && region_id) {
        let city_list = origCityList.filter(v => v.state_id === String(region_id));
        setCRACityList(city_list);
      }
    }, [origCityList, residenceDetails?.cra_region])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      let region_id = residenceDetails["pa_region"] || 0; 
      if (origCityList && origCityList.length && region_id) {
        let city_list = origCityList.filter(v => v.state_id === String(region_id));
        setPAACityList(city_list);
      }
    }, [origCityList, residenceDetails?.pa_region])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      let city_id = residenceDetails["cra_city"] || 0;
      if (city_id) {
        getLocalityList(city_id, "cra_city")
      }
    }, [residenceDetails?.cra_city])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      let city_id = residenceDetails["pa_city"] || 0;
      if (city_id) {
        getLocalityList(city_id, "pa_city")
      }
    }, [residenceDetails?.pa_city])// eslint-disable-line react-hooks/exhaustive-deps


    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      options["residence_type"] = RESIDENCE_TYPE_OPTIONS;
      options["region_list"] = regionList || [];
      options["cra_city_list"] = CRACityList || [];
      options["pa_city_list"] = PACityList || []
      options["cra_barangay_list"] = CRABarangayList || [];
      options["pa_barangay_list"] = PABarangayList || []
      return options;
    }, [masterdata,regionList, CRACityList, PACityList, CRABarangayList, PABarangayList]);

    const getRegionCityList = () => {
      executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_region_city_list) {
          let { region_list, city_list } = resp.data.get_region_city_list || [];
          setRegionList(region_list);
          setOrigCityList(city_list);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }

    const getLocalityList = (city_id, field_name) => {
      executeGraphQLQuery(GET_LOCALITY_LIST(city_id), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_locality_list) {
          let { locality_list } = resp.data.get_locality_list || [];
          if (field_name === "cra_city") {
            setCRABarangayList(locality_list)
          } else if (field_name === "pa_city") {
            setPABarangayList(locality_list);
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
    }


    const handleInputChange = event => {
        if (event.target.id) {
          let residenceData = {
            ...residenceDetails,
            [event.target.id]: event.target.value || ""
          }
          if (
            residenceData["pa_sameas_cra"] === "1" && 
            [
              "cra_house_number",
              "cra_street",
              "cra_barangay",
              "cra_zipcode",
            ].includes(event.target.id)
          ) {
            residenceData = {
              ...residenceData,
              "pa_house_number": residenceData.cra_house_number || "",
              "pa_street": residenceData.cra_street || "",
              "pa_barangay": residenceData.cra_barangay || "",
              "pa_zipcode": residenceData.cra_zipcode || ""
            }
          }
          setResidenceDetails(residenceData)
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setResidenceDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        let residenceData = {
          ...residenceDetails,
          [field_name]: data.value || ""
        }
        if (["cra_region"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            cra_city: "",
            cra_barangay: "",
            cra_zipcode: ""
          }
        }
        if (["cra_city"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            cra_barangay: "",
            cra_zipcode: ""
          }
        }
        if (["pa_region"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            pa_city: "",
            pa_barangay: "",
            pa_zipcode: ""
          }
        }
        if (["pa_city"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            pa_barangay: "",
            pa_zipcode: ""
          }
        }
        if (field_name === "cra_barangay") {
          residenceData = {
            ...residenceData,
            cra_zipcode: data.zipcode
          }
        }
        if (field_name === "pa_barangay") {
          residenceData = {
            ...residenceData,
            pa_zipcode: data.zipcode
          }
        }
        if (residenceData["pa_sameas_cra"] === "1" && ["cra_city","cra_region", "cra_barangay"].includes(field_name)) {
          residenceData = {
            ...residenceData,
            "pa_region": residenceData.cra_region || "",
            "pa_city": residenceData.cra_city || "",
            "pa_barangay": residenceData.cra_barangay || "",
            "pa_zipcode": residenceData.cra_zipcode || ""
          }
        }
        if (field_name === "cra_region") {
          setCRABarangayList([]);
        }
        if (field_name === "pa_region") {
          setPABarangayList([]);
        }
        setResidenceDetails(residenceData)
      }
    }
    
    const handleCheckboxChange = (event) => {
      let formDetails = {...residenceDetails}
      let inputval = event.target.value;
        if(event.target.checked){
            formDetails = {
              ...formDetails,
              "pa_sameas_cra": inputval,
              "pa_house_number": formDetails.cra_house_number || "",
              "pa_street": formDetails.cra_street || "",
              "pa_barangay": formDetails.cra_barangay || "",
              "pa_city": formDetails.cra_city || "",
              "pa_region": formDetails.cra_region || "",
              "pa_zipcode": formDetails.cra_zipcode || ""
            }
        } else {
          formDetails = {
            ...formDetails,
            "pa_sameas_cra": '0',
            "pa_house_number": origResidenceDetails.pa_house_number || "",
            "pa_street": origResidenceDetails.pa_street || "",
            "pa_barangay": origResidenceDetails.pa_barangay || "",
            "pa_city": origResidenceDetails.pa_city || "",
            "pa_region": origResidenceDetails.pa_region || "",
            "pa_zipcode": origResidenceDetails.pa_zipcode || ""
          }
        }

        setResidenceDetails({...formDetails});
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        lead_id: lead_id,
        cra_house_number: residenceDetails.cra_house_number || null,
        cra_street: residenceDetails.cra_street || null,
        cra_barangay: Number(residenceDetails.cra_barangay) || 0,
        cra_city: Number(residenceDetails.cra_city) || 0,
        cra_region: Number(residenceDetails.cra_region) || 0,
        cra_zipcode: residenceDetails.cra_zipcode || "",
        pa_house_number: residenceDetails.pa_house_number || null,
        pa_street: residenceDetails.pa_street || null,
        pa_barangay: Number(residenceDetails.pa_barangay) || 0,
        pa_city: Number(residenceDetails.pa_city) || 0,
        pa_region: Number(residenceDetails.pa_region) || 0,
        pa_zipcode: residenceDetails.pa_zipcode || "",
        pa_sameas_cra: residenceDetails.pa_sameas_cra === "1" ? "Yes" : "No",
        house_type: residenceDetails.house_type || null,
        duration_of_stay: residenceDetails.duration_of_stay || null,
        updated_by: user_id || 0
      }
      
      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'personal_details',
        changed_object: {
          cra_house_number: residenceDetails.cra_house_number?.toString() || null,
          cra_street: residenceDetails.cra_street?.toString() || null,
          cra_barangay: residenceDetails.cra_barangay?.toString() || null,
          cra_city: residenceDetails.cra_city?.toString() || null,
          cra_region: residenceDetails.cra_region?.toString() || null,
          cra_zipcode: residenceDetails.cra_zipcode?.toString() || null,
          pa_house_number: residenceDetails.pa_house_number?.toString() || null,
          pa_street: residenceDetails.pa_street?.toString() || null,
          pa_barangay: residenceDetails.pa_barangay?.toString() || null,
          pa_city: residenceDetails.pa_city?.toString() || null,
          pa_region: residenceDetails.pa_region?.toString() || null,
          pa_zipcode: residenceDetails.pa_zipcode?.toString() || null,
          house_type: residenceDetails.house_type?.toString() || null,
          duration_of_stay: residenceDetails.duration_of_stay?.toString() || null
        }
      };

      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));
      
      let mutation = SAVE_CUSTOMER_RESIDENCE_DETAILS, 
          variables = { residenceDetailsInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_customer_residential_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/customer-details/character-ref/${lead_id}`);
    }

    let CRADetailsForm = FORM_FIELDS_CRA;

    let PADetailsForm = useMemo(() => {
      let formFields = FORM_FIELDS_PA; 
      if (residenceDetails) {
        formFields = FORM_FIELDS_PA.map(v => {
          v["readOnly"] = residenceDetails["pa_sameas_cra"] === "1" ? true : false;
          return v;
        })
      }
      return formFields;
    }, [residenceDetails])

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const sectionInfo = {section : "Customer details" , sub_section : "Residential details"}

    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;   
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Customer Details</span>
              <h2>Residence Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field residence"
            >
              <div className="row">
                <div className="col-md-12">
                  <h3 className='p-t-0'>Current Address</h3>
                </div>
                
                {CRADetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(residenceDetails && residenceDetails[field.name])}
                        placeholder=" "
                        thousandsGroupStyle="thousand"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        residenceDetails[field.name] ? field.validationError : ""
                        }
                        showAsterisk={field.showAsterisk}
                        required={field.required}
                        readOnly={origResidenceDetails?.[field.name] && isSubStatus3Marked}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (residenceDetails[field.name]?.toString())),
                            isDisabled: field.readOnly || origResidenceDetails?.[field.name] && isSubStatus3Marked
                        }}
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        value={residenceDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}

                <fieldset className="form-filed col-md-12" style={{marginTop: "10px"}}>
                    <div className="material">
                    <FormsyCheckBox
                        id="pa_sameas_cra"
                        name="pa_sameas_cra"
                        type="checkbox"
                        className="custom-control-input"
                        value="1"
                        label={"Same as Current Address"}
                        checked={residenceDetails['pa_sameas_cra'] === "1" ? true : false}
                        onChange={handleCheckboxChange}
                        disabled={origResidenceDetails['pa_sameas_cra'] === "1" && isSubStatus3Marked ? true : false}
                    />

                    </div>
                </fieldset>
                
              </div>
              <div className='row'>
                <div className="col-md-12">
                    <h3>Permanent Address</h3>
                </div>

                {PADetailsForm.map((field) => (
                    ["text", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(residenceDetails && residenceDetails[field.name])}
                        placeholder=" "
                        {...(field.type === "number-format") ? {
                          thousandsGroupStyle:"thousand",
                          thousandSeparator: ',',
                          decimalSeparator: '.'
                        }: {}}
                        label={field.label}
                        onChange={
                        ["pattern-format", "number-format"].includes(field.type) 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                        residenceDetails[field.name] ? field.validationError : ""
                        }
                        disabled={field.readOnly}
                        required={field.required}
                        readOnly={origResidenceDetails?.[field.name] && isSubStatus3Marked}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value === (residenceDetails[field.name]?.toString())),
                            isDisabled: field.readOnly || origResidenceDetails?.[field.name] && isSubStatus3Marked
                        }}
                        required={field.required}
                        value={residenceDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}


              </div>

              <div className="btn-save-remarks">
              {isSubmitDisabled || !allowSubmit || getContext.markAsFreezedNewFlow || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span>
                :
                <button aria-label="save btn" type="submit" className="btn-primary">
                  Save & Next
                </button>}
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default ResidenceDetails;