import React from 'react';
import SendDoc from './SendDoc';
import { useSelector } from 'react-redux';

const SendFinancer = (props) => {
  const pagetab=[{key:'Customer Details',is_co_borrower:0,type:"borrower",doc:"1,85,2,3,4,5,6"},{key: 'Co-Borrower Details',is_co_borrower:1,type:"coborrower",doc:"43,86,44,45,46,47,48"}];
  const {  lead} = useSelector(({masterdata, lead}) => ({ lead }));
  const sectionInfo = {section : "Sent to financier" , sub_section : ""}
    return (
      <>
        <SendDoc template_title="Sent to Financier" template_for="send_to_financier" pagetab={pagetab} is_completed={lead?.leadDetail?.tab_status?.soft_approval_received?.is_completed} sectionInfo={sectionInfo}/>
      </>

    )
}


export default SendFinancer;