import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, NAME_WITH_LATIN_CHARACTER_REGEX } from '../../../config/constants';
import Loader from '../../elements/Loader';
import { CONTRACT_SIGNED, CUSTOMER_PERSONAL_DETAIL_FILLED, LOST_STATUS, SENT_TO_FINANCIER } from '../../../config/refinance/constant';

const RefinanceCoPersonalDetailsForm = (props) => {
  const [personalDetails, setPersonalDetails] = useState({});
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (leadDetail?.basic_details) {
      if (leadDetail?.co_personal_details && Object.keys(leadDetail?.co_personal_details).length) {
        let personalDetails = { ...leadDetail.co_personal_details };
        setPersonalDetails(personalDetails);
      }
      checkSaveEnable(leadDetail)
      let basicDetails = { ...leadDetail.basic_details };
      setBasicDetails(basicDetails)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));
  }, [leadDetail]);

  let ALL_OPTIONS = useMemo(() => {
    let options = master_data_ucrf ? { ...master_data_ucrf } : {};
    return options;
  }, [master_data_ucrf]);

  const handleInputChange = event => {
    if (event.target.name) {
      setPersonalDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value !== undefined && field_name) {
      setPersonalDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setPersonalDetails(currentValue => ({
        ...currentValue,
        [field_name]: date
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setPersonalDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let reqBody = {
      co_name: personalDetails.co_name || "",
      co_mobile_number: personalDetails.co_mobile_number || "",
      co_date_of_birth: personalDetails.co_date_of_birth || "",
      co_email_id: personalDetails.co_email_id || "",
      co_alternative_mobile_no: personalDetails.co_alternative_mobile_no || "",
      co_civil_status: personalDetails.co_civil_status || "",
      co_relationship_with_borrower: personalDetails.co_relationship_with_borrower || "",
      co_type: personalDetails.co_type || "",
      co_educational_attainment: personalDetails.co_educational_attainment || "",
      co_mother_name: personalDetails.co_mother_name || "",
      co_father_name: personalDetails.co_father_name || "",
      co_gender: personalDetails.co_gender || "",
    }
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          tab_id: 4,
          sub_tab_id: 0,
          lead_id,
          auto_save: 0,
          co_personal_details: reqBody
        },
      };
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/co-residential-details/${lead_id}`);
  }

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return basicDetails.case_sub_status === "Contract Signing" ? true : false;
  }, [basicDetails]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const nameWithLatinCharacterValidation = (rule, value) => {
    return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
  }

  const sectionInfo = { section: "Co Borrower Details", sub_section: "Personal Details" }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-co-borrower-personal-details")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CUSTOMER_PERSONAL_DETAIL_FILLED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Co Borrower Details</span>
            <h2>Personal Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field personal detail"
          >
            <div className="row">
              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_name"
                  name="co_name"
                  type="tb"
                  value={personalDetails?.["co_name"] || ""}
                  placeholder=" "
                  label="Co Borrower Name"
                  onChange={handleInputChange}
                  format={"+63 ### ### ######"}
                  validations={nameWithLatinCharacterValidation}
                  validationError="Enter Valid Co Borrower Name"
                  readOnly={isSaveDisable}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_mobile_number"
                  name="co_mobile_number"
                  type="mobile"
                  value={personalDetails?.["co_mobile_number"] || ""}
                  placeholder=" "
                  label="Co Borrower Mobile Number"
                  onChange={(data) => handlePatternFormatChange(data, "co_mobile_number")}
                  format={"+63 ### ### ######"}
                  validations={"isValidPhoneNumber"}
                  validationError="Enter Valid Co Borrower Mobile Number"
                  readOnly={isSaveDisable}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_alternative_mobile_no"
                  name="co_alternative_mobile_no"
                  type="mobile"
                  value={personalDetails?.["co_alternative_mobile_no"] || ""}
                  placeholder=" "
                  label="Co Borrower Alternative Mobile No"
                  onChange={(data) => handlePatternFormatChange(data, "co_alternative_mobile_no")}
                  format={"+63 ### ### ######"}
                  validations={"isValidPhoneNumber"}
                  validationError="Enter Valid Co Borrower Alternative Mobile No"
                  readOnly={isSaveDisable}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_email_id"
                  name="co_email_id"
                  type="text"
                  value={personalDetails?.["co_email_id"] || ""}
                  placeholder=" "
                  label={"Co Borrower Email ID"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Co Borrower Email ID"
                  validations={"isEmail"}
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyDatePicker
                  name="co_date_of_birth"
                  selectedDate={(personalDetails && personalDetails['co_date_of_birth'] && new Date(personalDetails["co_date_of_birth"])) || ""}
                  placeholder={"Date Of Birth"}
                  maxDate={new Date()}
                  value={(personalDetails && personalDetails["co_date_of_birth"] && new Date(personalDetails["co_date_of_birth"])) || ""}
                  onDateSelect={(date) => handleDateChange(date, "co_date_of_birth")}
                  dateFormat="dd MMM, yyyy"
                  validationError="Enter Valid Date Of Birth"
                  required={false}
                  showAsterisk={false}
                  readOnly={(isSaveDisable) ? true : false}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"co_civil_status"}
                  id={"co_civil_status"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["co_civil_status"],
                    placeholder: "Co Borrower Civil Status",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["co_civil_status"] && ALL_OPTIONS["co_civil_status"].filter(({ value }) => value == personalDetails["co_civil_status"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={false}
                  value={personalDetails && personalDetails["co_civil_status"]}
                  onChange={(data) => handleSelectChange(data, "co_civil_status")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"co_relationship_with_borrower"}
                  id={"co_relationship_with_borrower"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["co_relationship_with_borrower"],
                    placeholder: "Relationship with Co Borrower",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["co_relationship_with_borrower"] && ALL_OPTIONS["co_relationship_with_borrower"].filter(({ value }) => value == personalDetails["co_relationship_with_borrower"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={false}
                  value={personalDetails && personalDetails["co_relationship_with_borrower"]}
                  onChange={(data) => handleSelectChange(data, "co_relationship_with_borrower")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"co_type"}
                  id={"co_type"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["co_type"],
                    placeholder: "Co Borrower Type",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["co_type"] && ALL_OPTIONS["co_type"].filter(({ value }) => value == personalDetails["co_type"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={false}
                  value={personalDetails && personalDetails["co_type"]}
                  onChange={(data) => handleSelectChange(data, "co_type")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"co_educational_attainment"}
                  id={"co_educational_attainment"}
                  inputProps={{
                    options: ALL_OPTIONS?.["co_educational_attainment"],
                    placeholder: "Co Borrower Educational attainment",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS?.["co_educational_attainment"] && ALL_OPTIONS?.["co_educational_attainment"].filter(({ value }) => value == personalDetails["co_educational_attainment"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={false}
                  value={personalDetails && personalDetails["co_educational_attainment"]}
                  onChange={(data) => handleSelectChange(data, "co_educational_attainment")}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_mother_name"
                  name="co_mother_name"
                  type="tb"
                  value={personalDetails?.["co_mother_name"] || ""}
                  placeholder=" "
                  label={"Co Borrower Mother's Name"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Co Borrower Mother's Name"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="co_father_name"
                  name="co_father_name"
                  type="tb"
                  value={personalDetails?.["co_father_name"] || ""}
                  placeholder=" "
                  label={"Co Borrower Father's Name"}
                  onChange={handleInputChange}
                  validationError="Enter Valid Co Borrower Father's Name"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={false}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"co_gender"}
                  id={"co_gender"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["co_gender"],
                    placeholder: "Co Borrower Gender",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS && ALL_OPTIONS["co_gender"] && ALL_OPTIONS["co_gender"].filter(({ value }) => value == personalDetails["co_gender"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={false}
                  value={personalDetails && personalDetails["co_gender"]}
                  onChange={(data) => handleSelectChange(data, "co_gender")}
                />
              </fieldset>

            </div>

            <div className="btn-save-remarks">
            {(!allowSubmit || isSaveDisable) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceCoPersonalDetailsForm;