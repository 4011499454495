import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coBorrowerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_COBORROWER_BASIC_DETAILS } from '../../../services/coborrower';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, NAME_WITH_LATIN_CHARACTER_REGEX, SUB_STATUS_CODES} from '../../../config/constants';
import { GET_REGION_CITY_LIST } from '../../../services/config.gql';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");

const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" }
]

const RELATIONSHIP_OPTIONS = [
  { id: "1", label: "Mother", value: "mother" },
  { id: "2", label: "Father", value: "father" },
  { id: "3", label: "Mother in Law", value: "mother_in_law" },
  { id: "4", label: "Father in Law", value: "father_in_law" },
  { id: "5", label: "Immediate Sibling", value: "immediate_sibling" },
  { id: "6", label: "Son", value: "son" },
  { id: "7", label: "Daughter", value: "daughter" },
  { id: "8", label: "Live-in Partner", value: "live_in_partner" },
  { id: "9", label: "Spouse", value: "spouse" },
  { id: "10", label: "Other", value: "other" },
]

const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const BasicDetailsForm = (props) => {
    const [basicDetails, setBasicDetails] = useState({});
    const [orgbasicDetails, setOrgBasicDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead,remarksHistory, leadFiles } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),

    }));
    const [cityList, setCityList] = useState([]);
    const client = useApolloClient();
    const dispatch = useDispatch();
    const context = useOutletContext();
    const documentCategoryList = context.docList || []
    const viberCoborrowerData = context?.viberData?.get_viber_column?.co_borrower || [];
    const { showAiIcon } = useAiIcon(basicDetails, viberCoborrowerData);
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "co-borrower-basic-details")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;
    
    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.co_borrower) {
        let originalData = { ...lead.leadDetail.co_borrower };
        let updatedData = { ...originalData };

        originalData["cb_dob"] = originalData["cb_dob"] ? dateFormat(originalData["cb_dob"], "dd mmm, yyyy") : null;
        updatedData["cb_dob"] = updatedData["cb_dob"] ? dateFormat(updatedData["cb_dob"], "dd mmm, yyyy") : null;
        if (viberCoborrowerData) {
          Object.keys(viberCoborrowerData).forEach(key => {
            if (!updatedData[key] && viberCoborrowerData[key]) {
              if (key === "cb_dob") {
                updatedData[key] = viberCoborrowerData[key] ? dateFormat(viberCoborrowerData[key], "dd mmm, yyyy") : null;
              } else {
                updatedData[key] = viberCoborrowerData[key];
              }
            }
          });
        }

        setOrgBasicDetails(originalData);
        setBasicDetails(updatedData);
      }
    }, [lead]);

    useEffect(() => {
      getCityList()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    
    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      options["gender"] = GENDER_OPTIONS;
      options["cb_birthplace"] = cityList || [];
      options["relationship_with_customer"] = RELATIONSHIP_OPTIONS;
      return options;
    }, [masterdata,cityList]);

    const getCityList = () => {
      executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_region_city_list) {
          let { city_list } = resp.data.get_region_city_list || [];
          setCityList(city_list);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  
    const handleInputChange = event => {
        if (event.target.id) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: date
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setBasicDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        lead_id: lead_id,
        relationship_with_customer: basicDetails.relationship_with_customer,
        cb_name: basicDetails.cb_name,
        cb_phone: basicDetails.cb_phone,
        cb_alt_phone: basicDetails.cb_alt_phone,
        cb_email: basicDetails.cb_email,
        cb_dob: basicDetails?.cb_dob ? dateFormat(basicDetails.cb_dob , "yyyy-mm-dd") : null,
        cb_birthplace: Number(basicDetails.cb_birthplace) || null,
        cb_marital_status: basicDetails.cb_marital_status,
        cb_gender: basicDetails.cb_gender,
        cb_nationality: basicDetails.cb_nationality,
        cb_customer_type: Number(basicDetails.cb_customer_type) || null,
        updated_by: user_id || 0
      }
      if (basicDetails && basicDetails.cb_nationality === "other" && basicDetails.cb_other_nationality ) {
        reqBody["cb_other_nationality"] = basicDetails["cb_other_nationality"] || "";
      }
      Object.keys(reqBody).forEach(key => {
        if (reqBody[key] === "" && key!=='cb_nationality') reqBody[key] = null;
      });
      let mutation = SAVE_COBORROWER_BASIC_DETAILS, 
          variables = { cb_basicDetailsInput: reqBody };

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'co_borrower',
        changed_object: {
          relationship_with_customer: basicDetails.relationship_with_customer?.toString() || null,
          cb_name: basicDetails.cb_name?.toString() || null,
          cb_phone: basicDetails.cb_phone?.toString() || null,
          cb_alt_phone: basicDetails.cb_alt_phone?.toString() || null,
          cb_email: basicDetails.cb_email?.toString() || null,
          cb_dob: basicDetails?.cb_dob ? dateFormat(basicDetails.cb_dob, "yyyy-mm-dd") : null,
          cb_birthplace: basicDetails.cb_birthplace?.toString() || null,
          cb_marital_status: basicDetails.cb_marital_status?.toString() || null,
          cb_gender: basicDetails.cb_gender?.toString() || null,
          cb_nationality: basicDetails.cb_nationality?.toString() || null,
          cb_customer_type: basicDetails.cb_customer_type?.toString() || null
        }
      };

      if (basicDetails.cb_nationality === "other" && basicDetails.cb_other_nationality) {
        viberReqBody.changed_object.cb_other_nationality = basicDetails.cb_other_nationality.toString();
      }
      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_cb_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/co-borrower-details/additional-details/${lead_id}`);
    }


    let BasicDetailsForm = useMemo(() => {
      return FORM_FIELDS.map(field => {
        if (field.name === "cb_dob") {
            field["maxDate"] = DOB_MAX_DATE;
            field["minDate"] = DOB_MIN_DATE;
        }
        return field;
      })
    }, []);

    BasicDetailsForm = useMemo(() => {
      if (basicDetails) {
        // To Show nationality input text box in case of non phillipines
        if (basicDetails.cb_nationality !== "other") {
          return FORM_FIELDS.filter(v => v.id !== "cb_other_nationality");
        } 
      }
      return FORM_FIELDS;
      
    }, [basicDetails]);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };
    const sectionInfo = {section : "Co-borrower details" , sub_section : "Basic details"}
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;    

    const nameWithLatinCharacterValidation = (rule, value) => {
      return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
    }
    
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Co-Borrower Details</span>
              <h2>Basic Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
            >
              <div className="row">
                
                {BasicDetailsForm.map((field) => (
                    ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(basicDetails && basicDetails[field.name])}
                        placeholder=" "
                        label={field.label}
                        onChange={
                        field.type === "pattern-format" 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={ field.name === 'cb_name' && {nameWithLatinCharacterValidation} || field.validations}
                        validationError={
                        basicDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        readOnly={field.readOnly || (orgbasicDetails?.[field.name] && isSubStatus3Marked) ? true : false}
                    />
                  {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyDatePicker
                          name="dob"
                          selectedDate={basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])}
                          placeholder={field.label}
                          maxDate={field.maxDate}
                          value={basicDetails && basicDetails[field.name] ? dateFormat(basicDetails[field.name], "dd mmm, yyyy") : null}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                              basicDetails[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          hasError={(basicDetails.hasOwnProperty(field.name) && !basicDetails[field.name]) ? true : false}
                          readOnly={orgbasicDetails?.[field.name] && isSubStatus3Marked ? true : false}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) :
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value?.toString() === (basicDetails[field.name]?.toString())),
                            isDisabled: field.readOnly || (orgbasicDetails?.[field.name] && isSubStatus3Marked) ? true : false
                        }}
                        required={field.required}
                        value={basicDetails && basicDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
              {(isSubmitDisabled || !allowSubmit || context.markAsFreezedNewFlow || accessCondition) ? <span className="span-disabled">Save & Next</span> :
                <button aria-label="save next" type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit || context.markAsFreezedNewFlow || accessCondition}>
                  Save & Next
                </button>
              }
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezedNewFlow} accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default BasicDetailsForm;