import React, { useState, useEffect } from "react";
import Q from 'q';
// import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import GeneralService from '../../../services/generalService';
import UploadMoreICn from "../../../webroot/images/upload_icn.svg";
import Loader from '../../elements/Loader';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE,STATUS_CODES, SUB_STATUS_CODES} from '../../../../src/config/constants';
import LeadHelper from "../LeadHelper";
// import { getFileExtensionFromURL } from '../../../config/constants'


function UploadedDocumentList(props) {
    const {filter_doc_list} = props;
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [filterFiles, setFilterFiles] = useState([]);
    const [document_list] = useState(props.document_list);
    const [filter_doc_child_list] = useState(props.filter_doc_child_list)
    const [isLoading, setIsLoading] = useState(true);
    const [downloadList, setDownloadFiles] = useState([]);

    const { leadDetail } = useSelector(({ lead, user }) => ({
        UploadedFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
        userInfo: user.user_information || {},
        leadDetail: lead && lead.leadDetail
    }));
    const adp_type = leadDetail?.lead_computation_details?.adp_type === "Yes";
    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);
    let accessCondition = !IS_USER_ACCESS_PAGE('doc_upload', 'doc_upload').is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setFilterFiles(props.filterFiles);
    }, [props.filterFiles])

    // Upload Image 
    const handleChangeImage = (event) => {
        let files = Array.from(event.target.files), awsRes = [], leadDocArr = [];
        if (files && files.length) {
            setIsLoading(true);
            var formData = new FormData();
            formData.append("upload_type", 'finance_docs');
            formData.append("lead_id", leadDetail.id);
            files.forEach((file, index) => {
                formData.append('images', file);
            });
            awsRes.push(GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
        } else {
            awsRes.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
        }

        Q.allSettled(awsRes).then((uploadOutput) => {
            uploadOutput.forEach((res, index) => {
                let response = res.value || [];
                if (res.state === 'fulfilled') {
                    if (response.data.data.length) {
                        let resData = response.data.data;
                        resData.forEach((row, i) => {
                            let loanDetailsSaveDoc = {
                                lead_id: leadDetail.id,
                                doc_path: row.file_url,
                                parent_doc_id: 0,
                                doc_id: 0,
                                doc_source: 'web',
                                user_id: authUser && (authUser.id || 0),
                            }
                            leadDocArr.push(loanDetailsSaveDoc);
                        });
                    }
                }
            });
            if (leadDocArr.length) {
                let variables = {
                    saveDocInput: {
                        lead_id: leadDetail.id,
                        lead_docs: leadDocArr
                    },
                    api_called_by: 'web'
                };

                let mutation = gql`mutation save_doc($saveDocInput: SaveDocInput!, $api_called_by: API_CALL_BY!){
                    save_doc(saveDocInput: $saveDocInput, api_called_by: $api_called_by) {
                        lead_id
                        doc_id
                        doc_path
                    }
                }`;

                executeGraphQLMutation(mutation, variables, client).then(result => {
                    if (result && result.data) {
                        setIsLoading(false);
                        document.getElementById("uploadForm").reset();
                        toast.success('Document uploaded successfully');
                        let newArrayfiles = filterFiles.concat(result.data.save_doc);
                        setFilterFiles(newArrayfiles);
                        // props.getDocumentlist();
                        dispatch(getLoanDetail(leadDetail.id, client))
                    } else {
                        setIsLoading(false);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                });
            } else {
                toast.error('Error in image upload!');
                setIsLoading(false);
                document.getElementById("uploadForm").reset();
            }
        })
    }

    // delete Image 

    const deleteDocument = (docId, fileId, index) => {
        let UploadedFilesArr = [...filterFiles];
        let required_doc_ids = LeadHelper.getRequiredDocIds({ id:docId }, filter_doc_list, true);
        setIsLoading(true);        
        let variables = {
            updateDocInput: {
                id: fileId,
                lead_id: leadDetail.id,
                status: '0',
                parent_doc_id: 0,
                doc_id: 0,
                doc_source: 'web',
                user_id: authUser && (authUser.id || 0),
                tab_name:props.activeTab,
                required_doc_ids: required_doc_ids || []
            }
        };

        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                id,
                lead_id
                doc_id
                }
            }
            `;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                toast.success("Success");
                if (result.data) {
                    dispatch(getLoanDetail(leadDetail.id, client))
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            toast.error(error.toString().replace('ApolloError:',''));
            setIsLoading(false);
        });
        if (index !== -1) {
            UploadedFilesArr.splice(index, 1);
        }
        setFilterFiles(UploadedFilesArr);
    }

    // markedChecked

    const markedChecked = (event) => {
        let inpvalue = event.target.value;
        let downloadListNew = [...downloadList];
        let itemIndex = downloadListNew.indexOf(inpvalue);
        if (event.target.checked && itemIndex === -1) {
            if (inpvalue === 'all') {
                downloadListNew = filterFiles
                .filter(file => file.doc_id && file.doc_status?.toString() !== '0')
                .map(file => file.id.toString());
            }
            downloadListNew.push(inpvalue);

        } else {
            if (itemIndex > -1) {
                if (inpvalue === 'all') {
                    downloadListNew = [];
                } else {
                    downloadListNew.splice(itemIndex, 1);
                    let allIndex = downloadListNew.indexOf('all');
                    if (allIndex > -1) downloadListNew.splice(allIndex, 1);
                }

            }
        }

        if (downloadListNew.length === filterFiles.length && !downloadListNew.includes('all')) downloadListNew.push('all');

        setDownloadFiles(downloadListNew);
    }

    // download All

    const DownloadAll = () => {
        let links = []
        if (filterFiles.length) {
            setIsLoading(true);
            links = filterFiles.filter(v => downloadList.includes(v.id.toString())).map(v => v.doc_path);
            var zip = new JSZip();
            var count = 0;
            let zipfoldername = (leadDetail.id) ? leadDetail.id : "docs"
            var zipFilename = zipfoldername + ".zip";
           // const proxyurl = ""//"https://api.allorigins.win/raw?url=";
            links.forEach((url, i) => {
                let image_url = url.split("?")[0];
                let base_name = image_url.split('/').pop()

                // var filename = links[i];
                // filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com", "");

                JSZipUtils.getBinaryContent(url, (err, data) => {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(base_name, data, { binary: true });
                    count++;
                    if (count === links.length) {
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                            saveAs(content, zipFilename);
                            setIsLoading(false);
                        });
                    }
                });
            });

        }
    }

    const markAllAsApproved = async () => {
        let leadDocs = [];
        if (filterFiles.length) {
            leadDocs = filterFiles.filter(v => downloadList.includes(v.id.toString()) && ![2, 0].includes(v.doc_status)).map(file => ({
                id: file.id,
                doc_id: file.doc_id,
                parent_doc_id: file.parent_doc_id
            }));
            /** If selected docs already 2 */
            if (leadDocs.length < 1) {
                return toast.error("Already Approved");
            }
            let data = {   
                lead_id: leadDetail.id,
                user_id: authUser.id,
                doc_status: "2",
                doc_source:"web",
                remark: '',
                update_action_type: 'file',
                lead_docs: leadDocs   
            }
            manageDocAction(data);
            setIsLoading(true);

        }
    }
        

    const manageDocAction = async (leadData) => {
        if (leadData && leadData.lead_docs && leadData.lead_docs.length) {
            setIsLoading(true);
            let variables = { approvedDocInput: leadData };
            const mutation = gql`
                mutation approved_document($approvedDocInput: ApprovedDocInput!) {
                    approved_document(approvedDocInput: $approvedDocInput) {
                    lead_id
                    }
                }`;
            executeGraphQLMutation(mutation, variables, client).then(result => {
                if (result && result.data) {
                    toast.success("Doc Approved Successfully");
                    if (result.data) {
                        setDownloadFiles([]);
                        dispatch(getLoanDetail(leadDetail.id, client))
                    }
                    setIsLoading(false);
                } else {
                    setDownloadFiles([]);
                    setIsLoading(false);
                }
            }).catch(error => {
                setIsLoading(false);
            });
        } else {
            toast.error("No image found.");
        }
    }

    const send4TagFile = (file_id) => {
        props.send4TagFile(file_id);
    }

    let isReadOnly = 0;
    let status_history=leadDetail && leadDetail.lead_status_history.filter((v)=>v.sub_status_id === SUB_STATUS_CODES.SENT_TO_FINANCIER);
    let status_historyci=leadDetail && leadDetail.lead_status_history.filter((v)=>v.sub_status_id === SUB_STATUS_CODES.PAYMENT_VERIFICATION_DONE);  //GIN-7597 changes to add more images in pre-disbursal section
    let add_more_disabled= 
    (props.activeTab==='first_submit' && status_history.length) || 
    (props.activeTab==='pre-disbursal' && status_historyci.length) || 
    (props.activeTab === 'car_transfer' && adp_type &&  leadDetail?.vt_sub_status_id === SUB_STATUS_CODES.ORCR_VERIFIED && status_historyci.length) || 
    (props?.activeTab === "post_disbursal") ||
    (!adp_type && leadDetail.status_id === STATUS_CODES.DISBURSEMENT) || 
    leadDetail.status_id === STATUS_CODES.LOST;
    // let removeAddmoreBtn = ([5].includes(leadDetail.status_id)) || ([1,9,10,11,27].includes(leadDetail.sub_status_id)) ? true :false;
    return (
        <div className="upload-document-view-bx" key={filterFiles.length}>
            {
                (filterFiles && filterFiles.length) ? 
                <div className="select-all-opt">
                    <div className="custom-control custom-checkbox check-box-small select-check-bx">
                        <input aria-label="select all" id="selectall" type="checkbox" className="custom-control-input " name="Buyer Type" checked={downloadList.includes('all') ? true : false} onClick={markedChecked} value="all" />
                        <label className="custom-control-label" For="selectall">Select All </label>
                    </div>
                </div> : null
            }
            <div className="row" key={filterFiles.length}>
                {
                    (filterFiles && filterFiles.length) ? filterFiles.map((file, index) => {
                        // file.ext = (file.doc_path) ? getFileExtensionFromURL(file.doc_path) : '';
                        return (<React.Fragment key={`${file.id}${index}`}>
                            <div className="col-md-3">
                                <div className="img-bx-select-opt">
                                    <div className="image-bx">
                                        {
                                            (file.ext === 'pdf' || file.ext === 'PDF') ?
                                                <a href={file.doc_path} target="_blank" rel="noopener noreferrer">
                                                    <i className="ic-pdf-file icons-normal"></i>
                                                </a> : <img src={file.doc_path} alt="images doc_id here" className="" style={{ "max-height": "135px" }} />

                                        }
                                        {
                                            (file.id) ? (<span className="imgTag" data-key="tag_img" data-id={file.id} data-document_id={file.id} onClick={() => send4TagFile(file.id)}>Tag</span>) : null
                                        }

                                        {props.activeTab === 'first_submit' ? (file && file.doc_id && file.doc_status?.toString() === '2') ? <span className="image-status">Approved</span> : (file && file.doc_id && file.doc_status?.toString() === '0') ? <span className="image-requested">Requested</span> : '':''}

                                    </div>
                                    {
                                        (add_more_disabled || isReadOnly || (file.doc_id && file.doc_status?.toString() !== '1' && ["first_submit", "pre_disbursal", "car_transfer"].includes(props.activeTab))) ? null : (<div className="close-icn" onClick={() => { if (window.confirm('Confirm, do you want to delete?')) deleteDocument(file.doc_id, file.id, index) }}>
                                            <i className="ic-clearclose"></i>
                                        </div>)
                                    }

                                    <div className="custom-control custom-checkbox check-box-small">
                                        {/* Checking if document is tagged */}
                                        {file.doc_id ? (
                                            <>
                                                <input aria-label="individual file" id={`Individual${file.id}`} type="checkbox" className="custom-control-input "
                                                    disabled={file && file.doc_status?.toString() === '0'} name="selectFile" checked={downloadList.includes(file.id.toString()) ? true : false} onClick={markedChecked} value={file.id} />
                                                {file.doc_id === 88 && file?.financier_id ? 
                                                <label className="custom-control-label" For={`Individual${file.id}`}>
                                                    {document_list.filter(v => v.id === file.parent_doc_id).map(v => v.doclang.name)[0] + ' | ' + leadDetail?.financier?.filter(v => v.financier_id === +file.financier_id)?.[0]?.financier?.financierlang?.financier_name}
                                                </label>
                                                :
                                                <label className="custom-control-label" For={`Individual${file.id}`}>
                                                    {(file.parent_doc_id) ? document_list.filter(v => v.id === file.parent_doc_id).map(v => v.doclang.name)[0] + ' | ' + filter_doc_child_list.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)[0] : filter_doc_child_list.filter(v => v.id === +file.doc_id).map(v => v.doclang.name)[0]}
                                                </label>}
                                                
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>)

                    }) : null
                }

                {((leadDetail.sub_status_id === SUB_STATUS_CODES.PAID_TO_DEALER && !adp_type) || props.markAsFreezed || add_more_disabled || accessCondition)   ? '': (<div className="col-md-3">
                    <div className="img-bx-select-opt">
                        <div className="image-bx more-file-upload">
                            <img alt="Upload more icon" src={UploadMoreICn} className="upload-icn" />
                            <form id="uploadForm" method="post" encType="multipart/form-data">
                                <input aria-label="upload form multiple" type="file" name="fileName[]" multiple id="fileupload" onChange={handleChangeImage} />
                            </form>
                            <p>Add More</p>
                        </div>

                    </div>
                </div>)}

                {(isLoading) ? (<Loader />) : null}
            </div>
            {
                (downloadList && downloadList.length) ? (<div className="btn-line-img">
                    <button className="btn-line" onClick={DownloadAll}>{`${downloadList.length > 1 ? 'Download All Photos' : 'Download Photo'}`}</button>
                   {(!["pre-disbursal","car_transfer","post_disbursal"].includes(props.activeTab)) && (accessCondition ? <button className="btn-line" disabled={true}>Mark as Approved</button> : <button className="btn-line" onClick={markAllAsApproved}>Mark as Approved</button>)}

                </div>) : null
            }

        </div>
    )
}
export default UploadedDocumentList;