
const leadReducer = (state = '', action) => {
	switch (action.type) {

		case "GET_LEAD_DETAIL":
			return {
				...state,
				leadDetail: action.payload,
			};
		case "GET_LEAD_REMARKS_HISTORY":
			return {
				...state,
				remarksHistory: action.payload,
			};
		case "LEAD_SORTING_INFO":
			return {
				...state,
				leadSorting: action.payload,
			};

		case "GET_UCRF_LEAD_DETAIL":
			return {
				...state,
				ucrfLeadDetail: action.payload,
			};

		case "GET_UCRF_MENU_LIST":
			return {
				...state,
				ucrf_menu_list: action.payload,
			};
		case "GET_UCRF_FORM_FILL_STATUS":
			return {
				...state,
				ucrf_form_fill_status: action.payload,
			};
		case "GET_LEAD_DOCS_UCRF":
			return {
				...state,
				ucrf_lead_docs: action.payload,
			};
		case "GET_LEAD_REMARKS_HISTORY_UCRF":
			return {
				...state,
				remarksHistory: action.payload,
			};  
		case "GET_DOCS_CONFIG_UCRF":
			return {
				...state,
				doc_config: action.payload,
			};  
		default:
			return state
	}
}

export default leadReducer;
