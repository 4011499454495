import secureStorage from "./encrypt";
import _ from "lodash";
export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	let loginData = secureStorage.getItem("user_information");
	let isSuperAdmin = loginData && loginData.data && loginData.data.user_data && (loginData.data.user_data.role_id === -1 || loginData.data.user_data.role_id===2  || loginData.data.user_data.assigned_role_ids.includes("2"));
	let is_super_admin = loginData && loginData.data && loginData.data.user_data && (loginData.data.user_data.role_id === -1)
	if (isSuperAdmin) {
		isUserHasRights = true;
		isEditAccess = true;
	} else {
		const userAccessFeatures = JSON.parse(localStorage.getItem("userAccess"));
		if (userAccessFeatures && userAccessFeatures.length) {
			let isKeyExist = _.findKey(userAccessFeatures, function (v) {
				if (controller && v.controller && action && v.action) {
					return (
						v.controller.toLowerCase() === controller.toLowerCase() && 
						v.action.toLowerCase() === action.toLowerCase()
					);
				}
			});
			isUserHasRights = isKeyExist ? true : false;
			isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
		}
	}
	if(controller === 'UpdateLead' && loginData && loginData.data && loginData.data.user_data && loginData.data.user_data.user_id === 16){
		isEditAccess = true
		isUserHasRights = true 
	} 

	if(controller === 'UpdateLead' && loginData && loginData.data && loginData.data.user_data && loginData.data.user_data.user_id === 16){
		isEditAccess = true
		isUserHasRights = true 
	}


	return {
		is_access_page: isEditAccess,
		is_edit_access: isUserHasRights,
		is_super_admin: is_super_admin
	};

	// return {
	// 	is_access_page: isUserHasRights,
	// 	is_edit_access: isEditAccess
	// };
};

export const IF_ROLES={
	'ops_verifier':7,
	'credit_appraisal':8,
	'credit_ops':9,
	'ops_user':10
};

export const API_TIMEOUT=12000;
export const DIF_VAT=1.11;

export const numberFormat = (str) => {
    str = str ? str.toString() : "0";
    return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');;
}


export const getDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();
    const differenceMillis = endMillis - startMillis;
    const differenceDays = differenceMillis / oneDay;
    return parseInt(differenceDays);
}

export const getInspectionScore = (masterData,leadDetail) => {
	let exteriorScore = 0;
    let equipmentAccessoriesScore = 0;
    let interiorScore = 0;
    let mechanicalScore = 0;
    let otherScore = 0;
    masterData?.inspection_attributes?.filter((v)=>v.id == 1)[0]?.child?.map((field) => {
        let score = leadDetail?.lead_inspection?.filter((v)=>v.attributes_id == field.id)[0]?.value
        exteriorScore += score
    })
    let exteriorScorePercent = (exteriorScore/22*20).toFixed(2)
    masterData?.inspection_attributes?.filter((v)=>v.id == 4)[0]?.child?.map((field) => {
        let score = leadDetail?.lead_inspection?.filter((v)=>v.attributes_id == field.id)[0]?.value
        equipmentAccessoriesScore += score
    })
    let equipmentAccessoriesPercent = (equipmentAccessoriesScore/26*10).toFixed(2)
    masterData?.inspection_attributes?.filter((v)=>v.id == 2)[0]?.child?.map((field) => {
        let score = leadDetail?.lead_inspection?.filter((v)=>v.attributes_id == field.id)[0]?.value
        interiorScore += score
    })
    let interiorScorePercent = (interiorScore/14*20).toFixed(2)
    masterData?.inspection_attributes?.filter((v)=>v.id == 3)[0]?.child?.map((field) => {
        let score = leadDetail?.lead_inspection?.filter((v)=>v.attributes_id == field.id)[0]?.value
        mechanicalScore += score
    })
    let mechanicalScorePercent = (mechanicalScore/10*30).toFixed(2)
    masterData?.inspection_attributes?.filter((v)=>v.id == 5)[0]?.child?.map((field) => {
        let score = leadDetail?.lead_inspection?.filter((v)=>v.attributes_id == field.id)[0]?.value
        otherScore += score
    })
    let otherScorePercent = (otherScore/22*20).toFixed(2)

	return {exteriorScorePercent,equipmentAccessoriesPercent,interiorScorePercent,mechanicalScorePercent,otherScorePercent}
}

export const AUTH_KEY="reflnaprv-e1b2519c-4648-4a24-b2dc-86fc7616d24a"