import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CI_INVESTIGATION_DATA } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Loader from '../../elements/Loader';
import { isDate } from 'moment/moment';
import FormsyTextAreaAutosize from '../../elements/FormsyTextAreaAutosize';
import { CI_SCHEDULED, CONTRACT_SIGNED, LOST_STATUS } from '../../../config/refinance/constant';

const ALL_OPTIONS = {
  schedule_status: [
    { value: "in_progress", label: "In Progress", id: 1 }
  ]
}
const RefinanceCIScheduleForm = (props) => {
  const [CIScheduleData, setCIScheduleData] = useState([]);
  const [basicDetails, setBasicDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedfinanciers, setSelectedfinanciers] = useState([]);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)

  const { master_data_ucrf, remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    lead,
    remarksHistory: lead.remarksHistory || [],
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (leadDetail?.basic_details) {
      if (leadDetail?.lead_template_history?.length) {
        let financier_data = [...leadDetail.lead_template_history];
        let ci_schedule_data = leadDetail?.ci_schedule_data || []
        if (master_data_ucrf?.financier_list?.length && financier_data?.length) {
          financier_data = financier_data.map((ob) => ({
            financier_id: ob.financier_id,
            ci_schedule_date: ci_schedule_data?.length && ci_schedule_data.filter(e => e.financier_id == ob.financier_id).length ? ci_schedule_data.filter(e => e.financier_id == ob.financier_id)[0]?.ci_schedule_date || "" : "",
            is_skipped: ci_schedule_data?.length && ci_schedule_data.filter(e => e.financier_id == ob.financier_id).length ? ci_schedule_data.filter(e => e.financier_id == ob.financier_id)[0]?.is_skipped || "" : "",
            report_status: ci_schedule_data?.length && ci_schedule_data.filter(e => e.financier_id == ob.financier_id).length ? ci_schedule_data.filter(e => e.financier_id == ob.financier_id)[0]?.report_status || "" : "",
            reference_notes: ci_schedule_data?.length && ci_schedule_data.filter(e => e.financier_id == ob.financier_id).length ? ci_schedule_data.filter(e => e.financier_id == ob.financier_id)[0]?.reference_notes || "" : "",
            financier_name: master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id).length ? master_data_ucrf.financier_list.filter(e => e.id == ob.financier_id)[0]?.financier_name : "",
            data_filled: ci_schedule_data?.length && ci_schedule_data.filter(e => e.financier_id == ob.financier_id).length ? 1 : 0
          }))
          financier_data = financier_data.filter((e, i) => financier_data.findIndex(a => a["financier_id"] === e["financier_id"]) === i);
          let data_exist = financier_data.filter(e => typeof e.is_skipped == "boolean").length
          setIsDataChanged(data_exist)
        }
        let selected_financiers = financier_data.length ? financier_data.map(e => e.financier_id) : []
        setSelectedfinanciers(selected_financiers)
        setCIScheduleData(financier_data);
        checkSaveEnable(leadDetail)
      }
      let basicDetails = { ...leadDetail.basic_details };
      setBasicDetails(basicDetails)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));

  }, [leadDetail]);

  const handleInputChange = (event, financier_id) => {
    if (event.target.name && financier_id) {
      let updatedSoftApprovalData = CIScheduleData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1[event.target.name] = event.target.value
        }
        return obj1;
      })
      setCIScheduleData(updatedSoftApprovalData)
      setIsDataChanged(true)
    }
  }

  const handleSelectChange = (data, field_name, financier_id) => {
    if (data && field_name && financier_id) {
      let updatedSoftApprovalData = CIScheduleData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1[field_name] = data.value
        }
        return obj1;
      })
      setCIScheduleData(updatedSoftApprovalData)
      setIsDataChanged(true)
    }
  }

  const handleDateChange = (date, field_name, financier_id) => {
    if (date && field_name) {
      let updatedSoftApprovalData = CIScheduleData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1[field_name] = date
        }
        return obj1;
      })
      setIsDataChanged(true)
      setCIScheduleData(updatedSoftApprovalData)
    }
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let reqBody = [...CIScheduleData]
    reqBody = reqBody.filter(e => typeof e.is_skipped == "boolean" || e.schedule_status)
    reqBody = reqBody.map((ob) => ({
      financier_id: ob.financier_id,
      is_skipped: ob.is_skipped || false,
      ci_schedule_date: ob.ci_schedule_date || "",
      schedule_status: ob.schedule_status || "",
      reference_notes: ob.reference_notes || ""
    }))
    let mutation = UCRF_UPDATE_CI_INVESTIGATION_DATA,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          lead_id,
          ci_schedule_data: reqBody
        },
      };
    setIsDataChanged(false)
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/ci_report/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const selectFinancer = (e) => {
    if (e.target.checked) {
      let selectedFinancerTemp = selectedfinanciers;
      selectedFinancerTemp.push({ financier_id: Number(e.target.value) });
      setSelectedfinanciers([...selectedfinanciers])
    } else {
      const findIndex = selectedfinanciers.findIndex((fn) => Number(fn.financier_id) === Number(e.target.value));
      findIndex !== -1 && selectedfinanciers.splice(findIndex, 1)
      setSelectedfinanciers([...selectedfinanciers])
    }
  }

  const handleSkip = (financier_id) => {
    if (financier_id) {
      let updatedSoftApprovalData = CIScheduleData?.map((obj) => {
        let obj1 = { ...obj };
        if (obj.financier_id === financier_id) {
          obj1["is_skipped"] = obj.is_skipped == true ? "" : true
          obj1.ci_schedule_date = ""
          obj1.schedule_status = ""
        }
        return obj1;
      })
      let reqBody = updatedSoftApprovalData.filter(e => typeof e.is_skipped == "boolean")
      if (reqBody.length) {
        setIsDataChanged(true)
      } else {
        setIsDataChanged(false)
      }
      setCIScheduleData(updatedSoftApprovalData)
    }
  }

  const sectionInfo = { section: "Credit Investigation", sub_section: "Credit Investigation Schedule" }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceCreditInvestigation", "ucrf-ci-schedule")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CI_SCHEDULED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className={" switch-toggle"}>
            <div className="lead-form-heading">
              <span className="main-heading">Credit Investigation</span>
              <h2>Credit Investigation Schedule</h2>
            </div>
            <Formsy
              className="lead-form-filed soft-approval-filled-outer"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field soft approval status"
            >
              {CIScheduleData?.map((tab) => (

                <Accordion allowZeroExpanded>
                  <div
                    className=""
                  >
                    <AccordionItem className='acc-item-financer'>
                      <AccordionItemHeading className='acc-heading-financer'>
                        <AccordionItemButton>
                          <div className=" custom-control custom-checkbox checkbox-radius">
                            <input
                              aria-label="financier checkbox"
                              id="pending"
                              type="checkbox" value={tab.financier_id}
                              className="custom-control-input"
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => selectFinancer(e)}
                              checked={tab.is_skipped || tab.ci_schedule_date}
                              disabled={tab.data_filled || isSaveDisable}
                            />
                            <label className="custom-control-label"></label>
                          </div>
                          <div className={'financer-name-no-date'} >
                            <h3 title={tab?.financier_name}>  {tab?.financier_name}</h3>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='acc-panel-finaner'>
                        <div className="soft-approvel-sec">
                          <div className="row">
                            <div className='d-flex col-md-12 credit-complete-date'>
                              <fieldset class="form-filed credit-date-picker">
                                <div className="material date-filed">
                                  <FormsyDatePicker
                                    name="ci_schedule_date"
                                    selectedDate={(tab?.["ci_schedule_date"] && new Date(tab["ci_schedule_date"])) || ""}
                                    placeholder={"Credit Investigation Scheduled Date"}
                                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                    minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                    value={tab.ci_schedule_date ? dateFormat(tab.ci_schedule_date, 'd mmm,yyyy, h:MM TT') : ""}
                                    onDateSelect={(date) => handleDateChange(date, "ci_schedule_date", tab.financier_id)}
                                    dateFormat="dd MMM, yyyy h:mm aa"
                                    showTimeSelect={true}
                                    validationError="Enter Valid Credit Investigation Scheduled Date"
                                    required={false}
                                    showAsterisk={false}
                                    readOnly={false}
                                    disabled={tab.is_skipped || tab.data_filled || isSaveDisable}
                                  />
                                </div>
                              </fieldset>

                              <fieldset class="form-filed d-flex m-sm-l">
                                <div className=" custom-control custom-checkbox checkbox-radius">
                                  <input
                                    aria-label="financier skipped checkbox"
                                    id="pending"
                                    type="checkbox" value={tab.is_skipped}
                                    className="custom-control-input"
                                    onClick={() => handleSkip(tab.financier_id)}
                                    checked={tab.is_skipped}
                                    disabled={tab.data_filled || isSaveDisable}
                                  />
                                  <label className="custom-control-label"></label>
                                </div>
                                <button aria-label="skip ci_schedule" type="button" className="btn-skip m-sm-l"
                                  onClick={() => handleSkip(tab.financier_id)}
                                  disabled={tab.data_filled || isSaveDisable}
                                >
                                  {tab.is_skipped ? "Skipped" : "Skip"}
                                </button>
                              </fieldset>
                            </div>
                            {tab.ci_schedule_date && isDate(tab.ci_schedule_date) &&
                              <fieldset class="single-select col-md-12">
                                <FormsySelect
                                  name={"schedule_status"}
                                  id={"schedule_status"}
                                  inputProps={{
                                    options: ALL_OPTIONS && ALL_OPTIONS["schedule_status"],
                                    placeholder: "Schedule Status",
                                    className: "react-select",
                                    classNamePrefix: "react-select",
                                    value: ALL_OPTIONS && ALL_OPTIONS["schedule_status"] && ALL_OPTIONS["schedule_status"].filter(({ value }) => value == tab["schedule_status"]?.toString()),
                                    isDisabled: (tab.data_filled || isSaveDisable)
                                  }}
                                  required={true}
                                  showAsterisk={true}
                                  value={tab?.["schedule_status"]}
                                  onChange={(data) => handleSelectChange(data, "schedule_status", tab.financier_id)}
                                />
                              </fieldset>
                            }
                            <fieldset className="form-filed text-area-filed col-md-12">
                              <div className="material">
                                <FormsyTextAreaAutosize
                                  name={"reference_notes"}
                                  value={tab?.reference_notes || ""}
                                  placeholder=" "
                                  label={"Reference Notes"}
                                  onChange={e => handleInputChange(e, tab.financier_id)}
                                  format={""}
                                  required={false}
                                  readOnly={false}
                                  disabled={tab.data_filled || isSaveDisable}
                                />
                                <label data-label={"Reference Notes"} className="form-label"></label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>

                  </div>
                </Accordion>
              ))}

              <div className="btn-save-remarks">
                {(!allowSubmit || isSaveDisable || !isDataChanged) ?
                  <>{isNextEnable ?
                    <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                    :
                    <span className="span-disabled">
                      Save
                    </span>
                  }
                  </>
                  :
                  <button aria-label="save naxt" type="submit" className="btn-primary">
                    Save & Next
                  </button>
                }
                <button aria-label="remarks history" type="button" className="btn-line"
                  onClick={showModalViewTimeLine}
                >
                  Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceCIScheduleForm;