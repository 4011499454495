import { gql } from '@apollo/client';
export const LEAD_DOCS_REMARKS_QUERY = (lead_id, doc_id) => {
    return gql`{
        doc_remark_history(lead_id: ${lead_id}, doc_id: ${doc_id}){
          remarks
          source
          action_type
          status
          created_date
          doc_name
          doc_parent_name
          created_by_name
      }
    }
  `};
