import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import dateFormat from "dateformat";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { useApolloClient } from '@apollo/client';
import { DOCUMENT_LIST, SEND_DOC_TO_FINANCEIR } from '../../services/leads.gql';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../elements/Modal';
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import { getLoanDetail } from '../../store/action/allAction';
import FormsyInputField from '../elements/FormsyInputField';
import Formsy from 'formsy-react';
import Loader from '../elements/Loader';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE} from '../../../src/config/constants';
import { useParams } from "react-router-dom";
import crypto from '../../config/crypto'



const SendDocORCR = (props) => {
  const { docList, markAsFreezed } = useOutletContext();
  const [ciname,setCiname] = useState("");
  const [cicontact,setCicontact] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  let {lead_id} = useParams();
  let navigate = useNavigate();
  const leadDetail = useSelector(state => state.lead.leadDetail);
  const adp_type = leadDetail?.lead_computation_details?.title_transfer_type === "Yes";
  let activeFinancier = leadDetail?.financier?.filter(fn => fn.is_proceed === "yes")?.[0] || {};
  let availabledoc = [],first_submit_doc=docList && docList.filter((v)=>v.tab_name==='first_submit');
  leadDetail?.leaddocs?.length>0 && leadDetail.leaddocs.forEach((leaddoc) => {
    availabledoc.push(leaddoc.doc_id)
  });

  docList && docList.forEach((doc,key)=>{
    docList[key]['child_ids']=doc.child.map((dc)=>{ return dc.id })
    docList[key]['child_ids_exists']=availabledoc.some((v)=> { return docList[key]['child_ids']?.indexOf(v) !== -1 })
  });

  const { remarksHistory } = useSelector(({ lead }) => ({
    remarksHistory: lead.remarksHistory || []
  }));

  const user_information = useSelector((state) => state.user.user_information);
  const bank_list = useSelector((state) => state.masterdata.data.bank_list);
  const UserInfo = JSON.parse(user_information)

  const [displayBlock, setDisplayBlock] = useState('DocumentList');
  // const [SendFinaner, setSendFinaner] = useState(false);
  // const [DocumentList, setDocumentList] = useState(false);
  // const [SelectDocList, setSelectDocList] = useState(false);
  // const [RepeatDocList, setRepeatDocList] = useState(false);
  const [FinancerEmail, SetFinancerEmail] = useState(false);
  const [FinancerWhatsApp, SetFinancerWhatsApp] = useState(false);
  const [FinancerViber, SetFinancerViber] = useState(false);

  const [isEmailTemplateEnable, setIsEmailTemplateEnable] = useState(false);
  const [isWhatsappTemplateEnable, setIsWhatsappTemplateEnable] = useState(false);
  const [isViberTemplateEnable, setIsViberTemplateEnable] = useState(false);
  // const [downloadEmailTemplate, SetDownlaodEmailTemplate] = useState(false);
  const [SendToFinancer, SetSendToFinancer] = useState({ 'send_mode_arr': [], 'document_category_ids': [],send_mode_disabled:{},send_mode_checked:{} });
  const [templateList, SetTemplateList] = useState([]);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  let accessCondition = !IS_USER_ACCESS_PAGE(props.template_for, props.template_for)?.is_edit_access;
  if(!accessCondition){
    accessCondition=CHECK_ASSIGN_USER(leadDetail?.assign_to);
  }

    const showModalFinancerEmail = () => {
      SetFinancerEmail(true);
      document.body.classList.add("overflow-hidden");
    }
    const showModalFinancerWhatsApp = () => {
      SetFinancerWhatsApp(true);
      document.body.classList.add("overflow-hidden");
    }

    const showModalFinancerViber = () => {
      SetFinancerViber(true);
      document.body.classList.add("overflow-hidden");
    }

    const hideModalFinancerEmail = () => {
      SetFinancerEmail(false);
      document.body.classList.remove("overflow-hidden");
    }
    const hideModalFinancerWhatsApp = () => {
      SetFinancerWhatsApp(false);
      document.body.classList.remove("overflow-hidden");
    }

    const hideModalFinancerViber = () => {
      SetFinancerViber(false);
      document.body.classList.remove("overflow-hidden");
    }


 
  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const client = useApolloClient();
  const dispatch = useDispatch();
  const selectdocument = (e) => {
    let SendToFinancerTemp = { ...SendToFinancer };
    let document_category_ids = [...SendToFinancerTemp.document_category_ids];
    if (e.target.getAttribute('parent_id')) {
      if (e.target.checked) {
        const index = document_category_ids.indexOf(e.target.value);
        if (index === -1) {
          document_category_ids.push(Number(e.target.value));
        }
      } else {
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }

      let parent_doc_id = Number(e.target.getAttribute('parent_id'));
      let rowdata = docList.filter((data) => data.id === parent_doc_id);
      let allFounded = rowdata[0].child.every(ai => document_category_ids.includes(ai.id))
      if (allFounded) {
        document_category_ids.push(parent_doc_id);
      } else {
        const index = document_category_ids.indexOf(parent_doc_id);
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }

      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      SendToFinancerTemp.document_category_ids = uniqueselectedTagImageIdsData;
      SetSendToFinancer(SendToFinancerTemp);
    }
    else {
      if (e.target.checked) {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        document_category_ids.push(Number(e.target.value));
        rowdata[0].child.forEach((ch, i) => {
          document_category_ids.push(ch.id);
        })
      } else {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        rowdata[0].child.forEach((ch, i) => {
          const index = document_category_ids.indexOf(ch.id);
          if (index > -1) {
            document_category_ids.splice(index, 1);
          }
        });
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }
      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      SendToFinancerTemp.document_category_ids = uniqueselectedTagImageIdsData;
      SetSendToFinancer(SendToFinancerTemp);
    }
  }

  const replaceAll=(str,mapObj)=>{
    var re = new RegExp(Object.keys(mapObj).join("|"),"gi");

    return str.replace(re, function(matched){
        return mapObj[matched];
    });
} 

  useEffect(() => {
    setCiname(leadDetail?.lead_details?.ci_name );
    setCicontact(leadDetail?.lead_details?.ci_contact);

    let financier_id=leadDetail?.financier?.filter((fn)=>fn?.is_proceed==="yes")?.[0]?.financier_id
  
    executeGraphQLQuery(DOCUMENT_LIST(financier_id, props.template_for), client).then((result) => {
      /*Check template type*/
      let templates = result.data.financierListbyID;
      let templatedata = {};
      let send_mode_arr = [],send_mode_checked={},send_mode_disabled={};
      let loandetailsfield={
        "customer_name":leadDetail?.customer?.name || "",
        "customer_mobile_number":leadDetail?.customer?.phone || "",
        "customer_alternative_mobile_number":leadDetail?.customer?.alt_phone || "",
        "customer_email_id":leadDetail?.customer?.email || "",
        "customer_dob":dateFormat(leadDetail?.customer?.dob , "dd mmm, yyyy") || "",
        "customer_gender":leadDetail?.customer?.gender || "",
        "customer_civil_status":leadDetail?.customer?.marital_status || "",
        "customer_nationality":leadDetail?.customer?.nationality || "",
        "customer_type":leadDetail?.customer?.customer_type || "",
        "customer_primary_doc_id_card_category":leadDetail?.customer?.primary_doc_id || "",
        "customer_primary_doc_id_card_number":leadDetail?.customer?.primary_card_no || "",
        "customer_secondary_doc_id_card_category":leadDetail?.customer?.secondary_doc_id || "",
        "customer_secondary_doc_id_card_number":leadDetail?.customer?.secondary_card_no || "",
        "customer_educational_attainment":leadDetail?.customer?.qualification || "",
        "number_of_dependents":leadDetail?.customer?.no_of_dependent || "",
        "customer_father_name":leadDetail?.customer?.father_name || "",
        "customer_mother_maiden_name":leadDetail?.customer?.mother_maiden_name || "",
        "customer_facebook_id":leadDetail?.customer?.facebook_url || "",
        "customer_ttn":leadDetail?.customer?.tin_number || "",
        "employee_type":"" || "",
        "company_name":leadDetail?.customer?.employment?.employer_name || "",
        "company_address":leadDetail?.customer?.employment?.business_address || "",
        "company_contact_no":leadDetail?.customer?.employment?.company_phone || "",
        "industry":leadDetail?.customer?.employment?.industry || "",
        "designation":leadDetail?.customer?.employment?.designation || "",
        "years_with_the_company":leadDetail?.customer?.employment?.year_with_company || "",
        "gross_monthly_income":"" || "",
        "bank_name":leadDetail?.customer?.bank_details?.bank_id ? bank_list?.filter((v)=>{return v.id === leadDetail?.customer?.bank_details?.bank_id})?.map(v=>v.label)?.[0] : "",
        "branch_name":leadDetail?.customer?.bank_details?.branch_name || "",
        "account_name":leadDetail?.customer?.bank_details?.account_name || "",
        "type_of_account":leadDetail?.customer?.bank_details?.account_type || "",
        "account_number":leadDetail?.customer?.bank_details?.account_number || "",
        "re_enter_account_number":leadDetail?.customer?.bank_details?.account_number || "",
        "house_number":leadDetail?.customer?.cra_house_number || "",
        "customer_street":leadDetail?.customer?.cra_street || "",
        "customer_barangay":leadDetail?.customer?.cra_barangay || "",
        "customer_city":leadDetail?.customer?.cra_city || "",
        "customer_region":leadDetail?.customer?.cra_region || "",
        "customer_postal_code":leadDetail?.customer?.cra_zipcode || "",
        "customer_residence_type":"" || "",
        "customer_duration_of_stay":leadDetail?.customer?.duration_of_stay || "",
        "customer_permanent_house_number":leadDetail?.customer?.pa_house_number || "",
        "customer_permanent_street":leadDetail?.customer?.pa_street || "",
        "customer_permanent_barangay":leadDetail?.customer?.pa_barangay || "",
        "customer_permanent_city":leadDetail?.customer?.pa_city || "",
        "customer_permanent_region":leadDetail?.customer?.pa_region || "",
        "customer_permanent_postal_code":leadDetail?.customer?.pa_zipcode || "",
        "customer_charref_full_name":leadDetail?.character_ref?.full_name || "",
        "customer_charref_mobile_number":leadDetail?.character_ref?.mobile_number || "",
        "customer_charref_address":leadDetail?.character_ref?.address || "",
        "customer_charref_relationship":leadDetail?.character_ref?.relationship || "",
        "relationship_with_customer":leadDetail?.co_borrower?.relationship_with_customer || "",
        "co-borrower_name":leadDetail?.co_borrower?.cb_name || "",
        "co-borrower_mobile_number":leadDetail?.co_borrower?.cb_phone || "",
        "co-borrower_alternative_mobile_number":leadDetail?.co_borrower?.cb_alt_phone || "",
        "co-borrower_email_id":leadDetail?.co_borrower?.cb_email || "",
        "co-borrower_dob":dateFormat(leadDetail?.co_borrower?.cb_dob , "dd mmm, yyyy")|| "",
        "co-borrower_gender":leadDetail?.co_borrower?.cb_gender || "",
        "co-borrower_civil_status":leadDetail?.co_borrower?.cb_marital_status || "",
        "co-borrower_nationality":leadDetail?.co_borrower?.cb_nationality || "",
        "co-borrower_type":leadDetail?.co_borrower?.cb_customer_type || "",
        "co-borrower_primary_doc_id_card_category":leadDetail?.co_borrower?.cb_primary_doc_id || "",
        "co-borrower_primary_doc_id_card_number":leadDetail?.co_borrower?.cb_primary_card_no || "",
        "co-borrower_secondary_doc_id_card_category":leadDetail?.co_borrower?.cb_secondary_doc_id || "",
        "co-borrower_secondary_doc_id_card_number":leadDetail?.co_borrower?.cb_secondary_card_no || "",
        "co-borrower_educational_attainment":leadDetail?.co_borrower?.cb_qualification || "",
        "co-borrower_of_dependents":leadDetail?.co_borrower?.cb_no_of_dependent || "",
        "co-borrower_father_name":leadDetail?.co_borrower?.cb_father_name || "",
        "co-borrower_mother_maiden_name":leadDetail?.co_borrower?.cb_mother_name || "",
        "co-borrower_facebook_id":leadDetail?.co_borrower?.cb_facebook_url || "",
        "co-borrower_ttn":leadDetail?.co_borrower?.cb_tin_number || "",
        "co-borrower_industry":leadDetail?.co_borrower?.employment_details?.cb_industry || "",
        "co-borrower_designation":leadDetail?.co_borrower?.employment_details?.cb_designation || "",
        "co-borrower_years_with_the_company":leadDetail?.co_borrower?.employment_details?.cb_year_with_company || "",
        "co-borrower_gross_monthly_income":leadDetail?.co_borrower?.employment_details?.cb_gross_monthly_income || "",
        "co-borrower_bank_name":leadDetail?.co_borrower?.bank_details?.cb_branch_name || "",
        "co-borrower_branch_name":leadDetail?.co_borrower?.bank_details?.cb_branch_name || "",
        "co-borrower_account_name":leadDetail?.co_borrower?.bank_details?.cb_account_name || "",
        "co-borrower_type_of_account":leadDetail?.co_borrower?.bank_details?.cb_account_type || "",
        "co-borrower_account_number":leadDetail?.co_borrower?.bank_details?.cb_account_number || "",
        "co-borrower_re_enter_account_number":leadDetail?.co_borrower?.bank_details?.cb_account_number || "",
        "co-borrower_house_number":leadDetail?.co_borrower?.cb_pa_house_number || "",
        "co-borrower_street":leadDetail?.co_borrower?.cb_cra_street || "",
        "co-borrower_barangay":leadDetail?.co_borrower?.cb_cra_barangay || "",
        "co-borrower_city":leadDetail?.co_borrower?.cb_cra_city || "",
        "co-borrower_region":leadDetail?.co_borrower?.cb_cra_region || "",
        "co-borrower_postal_code":leadDetail?.co_borrower?.cb_cra_zipcode || "",
        "co_borrower_residence_type":"" || "",
        "co_borrower_duration_of_stay":leadDetail?.co_borrower?.cb_duration_of_stay || "",
        "co_borrower_permanent_house_number":leadDetail?.co_borrower?.cb_pa_house_number || "",
        "co_borrower_permanent_street":leadDetail?.co_borrower?.cb_pa_street || "",
        "co_borrower_permanent_barangay":leadDetail?.co_borrower?.cb_pa_barangay || "",
        "co-borrower_permannent_city":leadDetail?.co_borrower?.cb_pa_city || "",
        "co_borrower_permanent_region":leadDetail?.co_borrower?.cb_pa_region || "",
        "co-borrower_permanaet_postal_code":leadDetail?.co_borrower?.cb_pa_zipcode || "",
        "brand":leadDetail?.lead_details?.make_name || "",
        "model":leadDetail?.lead_details?.modal_name || "",
        "variant":leadDetail?.lead_details?.variant_name || "",
        "make_year":leadDetail?.lead_details?.make_year || "",
        "color":leadDetail?.lead_details?.color || "",
        "plate_number":leadDetail?.lead_details?.plate_number || "",
        "fmv":leadDetail?.quote_data?.car_fmv || "",
        "down_payment":leadDetail?.quote_data?.dp_amount || "",
        "tenure_in_months":leadDetail?.quote_data?.tenure_in_month || "",
        "interest_rate":leadDetail?.quote_data?.interest_rate || "",
        "emi":leadDetail?.quote_data?.installment_amount || "",
        "loan_value":leadDetail?.quote_data?.loan_value || "",//ask
        "chattel_fee":leadDetail?.quote_data?.chattel_fee || "",
        "chattel_percentage":leadDetail?.quote_data?.chattel_percent || "",
        "out_of_town_charges":leadDetail?.quote_data?.out_of_town_charges || "",
        "total_payment_by_customer":leadDetail?.quote_data?.total_payment_by_customer || "",//ask
        "dealers_incentive_percenatge":leadDetail?.quote_data?.dealer_incentive_percent || "",
        "carmudi_top_up_percenatge":leadDetail?.quote_data?.carmudi_top_up_percent || "",//quote
        "dealer_incentive_from_carmudi":leadDetail?.quote_data?.dealer_incentive_from_carmudi || "",//ask
        "total_dealer_incentive":leadDetail?.quote_data?.total_dealer_incentive || "",//ask
        "dealer_incentive_tax_percenatage":leadDetail?.quote_data?.dealer_incentive_tax_percentage || "",//ask
        "total_payment_to_dealer":leadDetail?.quote_data?.total_payment_to_dealer || "",
        "ci_contact_person":leadDetail?.lead_details?.ci_name || "",
        "ci_contact_number":leadDetail?.lead_details?.ci_contact || "",
        "ci_notes":"" || "",
        "promo_ongoing":"" || "",
        "computation_fmv":leadDetail?.lead_computation_details?.fmv || "",
        "computation_tenure_in_months":leadDetail?.lead_computation_details?.tenure_in_months || "",
        "computation_downpayment":leadDetail?.lead_computation_details?.down_payment_amount || "",
        "compuatation_emi":leadDetail?.lead_computation_details?.installment || "",
        "compuatation_interest_rate":leadDetail?.lead_computation_details?.interest_rate || "",
        "computation_loan_value":leadDetail?.lead_computation_details?.loan_value || "",
        "computation_chattel_percentage":leadDetail?.lead_computation_details?.chattel_percentage || "",
        "computation_chattel_fee":leadDetail?.lead_computation_details?.chattel_fee || "",
        "computation_out_of_town_changes":leadDetail?.lead_computation_details?.out_of_town_charges || "",
        "computation_total_payment_by_customer":leadDetail?.lead_computation_details?.total_payment_by_customer || "",
        "computation_dealers_incentive_percentage":leadDetail?.lead_computation_details?.dealer_incentive_percentage || "",
        "computation_dealer_incentive_from_financier":leadDetail?.lead_computation_details?.dealer_incentive_from_financier || "",
        "computation_car_mudi_top_up_percentage":leadDetail?.lead_computation_details?.carmudi_top_up_percentage || "",
        "computation_dealer_incentive_from_carmudi":leadDetail?.lead_computation_details?.dealer_incentive_from_carmudi || "",
        "computation_total_dealer_incentive":leadDetail?.lead_computation_details?.total_dealer_incentive || "",
        "computation_dealer_incentive_tax_percentage":leadDetail?.lead_computation_details?.dealer_incentive_tax_percentage || "",
        // "computation_total_dealer_incentive_net_of_tax":leadDetail?.lead_computation_details?.total_dealer_incentive_net_of_tax || "",
        "computation_total_payment_to_dealer":leadDetail?.lead_computation_details?.total_dealer_payment || "",
        "contract_sign_date_time":leadDetail?.lead_status_dates?.custom_contract_signed_date || "",
        "financier_name":leadDetail?.financier?.financierlang?.financier_name || "",
        "dealer_tin":"",
        "dealer_address":"",
        "dealer_longitude":"",
        "dealer_latitude":"",
        "dealer_phone_number":leadDetail?.dealerData?.[0]?.dealership_contact || "",
        "dealer_email":leadDetail?.dealerData?.[0]?.dealership_email || "",
        "dealer_bank_name":leadDetail?.dealerData?.[0]?.bank_name || "",
        "dealer_bank_branch":leadDetail?.dealerData?.[0]?.branch_name || "",
        "dealer_account_name":leadDetail?.dealerData?.[0]?.account_holder_name || "",
        "dealer_account_number":leadDetail?.dealerData?.[0]?.account_number || "",
        "dealer_id_code":leadDetail?.dealerData?.[0]?.gcd_code || "",
        "soft_approval_notes":leadDetail?.lead_details?.soft_approval_reference_notes || "",
        "soft_approval_ref_number":leadDetail?.lead_details?.soft_approval_reference_number || "",
        "soft_approval_received_date_time":leadDetail?.lead_status_dates?.soft_approval_received || "",
        "credit_advise_notes":leadDetail?.lead_details?.credit_advice_reference_notes || "",
        "credit_advice_ref_number":leadDetail?.lead_details?.credit_advice_reference_number || "",
        "signed_contract_ref_number":leadDetail?.lead_details?.contract_signing_reference_number || "",
        "credit_advice_received_date_time":leadDetail?.lead_status_dates?.credit_advice_received || "",
        "car_handover_notes":leadDetail?.lead_details?.car_handover_reference_notes || "",
        "car_handover_date_time":leadDetail?.lead_status_dates?.car_handover || "",
        "orcr_transferred_date_time":leadDetail?.lead_status_dates?.orcr_verified_by_financier || "",
        "accreditation_date":leadDetail?.lead_details?.accreditation_date || "",
        "dealer_incentive_from_financier":leadDetail?.quote_data?.incentive_amount_received || "",
        "total_dealer_incentive_net_of_tax":leadDetail?.quote_data?.total_dealer_incentive_net_of_tax || "",
      };

      let mapObjeConstant = {}
            for (const property in loandetailsfield) {
              if(loandetailsfield[property]===undefined || loandetailsfield[property]===null){
                mapObjeConstant[`{{${property}}}`]="";
              }else{
                mapObjeConstant[`{{${property}}}`] = loandetailsfield[property];
              }
            }

            let status_history=leadDetail?.lead_status_history?.filter((tsh)=>(tsh.sub_status_id===3 && "send_to_financier"===props.template_for) || (tsh.sub_status_id===5  && "credit_investigation"===props.template_for) || (tsh.sub_status_id===14 && "orcr_submission_to_financier"===props.template_for))?.length;
      templates?.forEach((element) => {
        if (element.template_type === 'email') {
          setIsEmailTemplateEnable(true);
          let pasttemplate=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='email' && th.page_type===props.template_for);
          if(pasttemplate && pasttemplate.length>0)
            element.template_body=pasttemplate?.[0]?.send_html_template;
          else
            element.template_body = replaceAll(element.template_body, mapObjeConstant);
          templatedata['email'] = element;
          
          let temphistory=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='email' && th.status==='1' && th.page_type===props.template_for)?.length>0

          console.log(temphistory,"thhhhhhhhhhhhhhhhhhh");
          
          if(temphistory && status_history){
            send_mode_checked.email=true;
            send_mode_disabled.email=true;
            send_mode_arr.push("EMAIL");
          }else{
            send_mode_checked.email=false;
            send_mode_disabled.email=false;
          }
          setDisplayBlock("DocumentList");
          
        } else if (element.template_type === 'whatsapp') {
          element.template_body = replaceAll(element.template_body, mapObjeConstant);
          setIsWhatsappTemplateEnable(true);
          let pasttemplate=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='whatsapp' && th.page_type===props.template_for);
          if(pasttemplate && pasttemplate.length>0)
            element.template_body=pasttemplate?.[0]?.send_html_template;
          else
            element.template_body = replaceAll(element.template_body, mapObjeConstant);

          templatedata['whatsapp'] = element;
          let temphistory=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='whatsapp' && th.status==='1' && th.page_type===props.template_for)?.length>0

          if(temphistory && status_history){
            send_mode_checked.whatsapp=true;
            send_mode_disabled.whatsapp=true;
            send_mode_arr.push("WHATSAPP");
          }else{
            send_mode_checked.whatsapp=false;
            send_mode_disabled.whatsapp=false;
          }

          setDisplayBlock("DocumentList")
        }
        else if (element.template_type === 'viber') {
          element.template_body = replaceAll(element.template_body, mapObjeConstant);
          setIsViberTemplateEnable(true);

          let pasttemplate=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='viber' && th.page_type===props.template_for);
          if(pasttemplate && pasttemplate.length>0)
            element.template_body=pasttemplate?.[0]?.send_html_template;
          else
            element.template_body = replaceAll(element.template_body, mapObjeConstant);

          templatedata['viber'] = element;
  
          let temphistory=leadDetail?.lead_template_history?.filter((th)=>th.template_type==='viber' && th.status==='1' && th.page_type===props.template_for)?.length>0

          if(temphistory && status_history ){
            send_mode_checked.viber=true;
            send_mode_disabled.viber=true;
            send_mode_arr.push("VIBER");
          }else{
            send_mode_checked.viber=false;
            send_mode_disabled.viber=false;
          }

          setDisplayBlock("DocumentList")
        }
        SetSendToFinancer(prev => ({ ...prev, send_mode_arr: [...send_mode_arr],send_mode_checked:{...send_mode_checked},send_mode_disabled:{...send_mode_disabled} }))
        
      })
      SetTemplateList(templatedata);
      /*End check template type*/
      

    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }, [leadDetail])

  const toggleswitch = (valueMode, event) => {
    let SendToFinancerTemp = { ...SendToFinancer };
    let tempsSendModeName = [...SendToFinancerTemp.send_mode_arr];
    let tempsSendModeChecked = {...SendToFinancerTemp.send_mode_checked};
    if (event.target.checked) tempsSendModeName.push(valueMode);
    else tempsSendModeName.splice(tempsSendModeName.indexOf(valueMode), 1)
    SendToFinancerTemp.send_mode_arr = tempsSendModeName;
    SetSendToFinancer(SendToFinancerTemp);
    if (event.target.checked) tempsSendModeChecked[valueMode.toLowerCase()]=true;
    else tempsSendModeChecked[valueMode.toLowerCase()]=false;
    SendToFinancerTemp.send_mode_checked = tempsSendModeChecked;
     SetSendToFinancer(SendToFinancerTemp);
    setDisplayBlock("DocumentList")
  };

  const verifyuploadeddocument=()=>{
  
    let checkuploaddoc=true;
    let doc=[];
    if("credit_investigation"===props.template_for){
      doc=[90];
    }else if("send_to_financier"===props.template_for){
      first_submit_doc && first_submit_doc.forEach((v)=>  {
        let childdoc=v.child.filter((ch)=>ch.tab_name==='first_submit' && ch.is_required==='1');
        let childids=childdoc.map((chid)=> chid.id)
       doc=doc.concat(childids);
      } );
    }
    else{
      doc=[102,100];
    }
    checkuploaddoc=doc.every(v => availabledoc.includes(v));
    return checkuploaddoc;
  }

  const senddocumenttofinanceir=()=>{
    if(!verifyuploadeddocument()){
      toast.warning("Please upload all required documents.");
      return;
    }
    const filteredArray = SendToFinancer.document_category_ids.filter(value => availabledoc.includes(value));

      let links='';
      leadDetail?.leaddocs.forEach((v)=>{
        if(filteredArray.includes(v.doc_id))
          links+=v.doc_path+" ";
      })

    // let docdata={lead_id:leadDetail.id,user_email:"",page_type:props.template_for,user_id:UserInfo?.id,"template_type":[],"template_id":[],"send_html_template":[],"doc_ids":filteredArray.join(),remarks:"test",status:"1",created_by:UserInfo?.id,updated_by:UserInfo?.id};

    let docdata = { lead_id: leadDetail.id, user_email: [], page_type: props.template_for, user_id: UserInfo?.id, "template_type": [], "template_id": [],"financier_id":[leadDetail?.financier?.filter((fn)=>fn?.is_proceed==="yes")?.[0]?.financier_id], "send_html_template": [], "doc_ids": [filteredArray.join()], remarks: "test", status: "1", created_by: UserInfo?.id, updated_by: UserInfo?.id };

    if(props.template_for==="credit_investigation"){
      if(ciname==='' && cicontact===''){
        toast.warning("Fill required fields");
        return;
      }
      docdata.ci_name=ciname;
      docdata.ci_contact=cicontact;
    }else{
      docdata.ci_name="";
      docdata.ci_contact="";
    }
    let whatsapplink="",viberlink="";
    if(SendToFinancer.send_mode_arr.length>0){
      setLoading(true);
      if(SendToFinancer.send_mode_arr.includes("EMAIL")){
        docdata.template_type.push("email");
        docdata.send_html_template.push(`${templateList['email'].template_body.replaceAll("\n", "<br>")}`);
        docdata.template_id.push(`${templateList['email'].id}`);
        docdata.user_email.push(templateList['email'].financier_group_email);
      }
       if(SendToFinancer.send_mode_arr.includes("WHATSAPP")){
        docdata.template_type.push("whatsapp");
        docdata.template_id.push(`${templateList['whatsapp'].id}`);
        docdata.send_html_template.push(`${templateList['whatsapp'].template_body.replaceAll("\n", "<br>")}`);
        whatsapplink="https://wa.me?text="+encodeURIComponent(templateList['whatsapp'].template_body.replaceAll( "<br>","\n")+" "+links);
        docdata.user_email.push(templateList['whatsapp'].financier_group_email);
        // {WHATSAPP_URL + encodeURIComponent(templateData.whatsappTemplate.template_body.replaceAll("<br>", "\n") + whatsapp_image_message)}
      }
      if(SendToFinancer.send_mode_arr.includes("VIBER")){
        docdata.template_type.push("viber");
        docdata.template_id.push(`${templateList['viber'].id}`);
        docdata.send_html_template.push(`${templateList['viber'].template_body.replaceAll("\n", "<br>")}`);
         docdata.user_email.push(templateList['viber']?.financier_group_mobile);
        let viberphone =templateList['viber']?.financier_group_mobile;

        viberphone=viberphone==="" ? "9178096510" : viberphone;
        viberlink=`https://msng.link/o/?+63${viberphone}=vi`;
      }
     // docdata.send_html_template=JSON.stringify(docdata.send_html_template);
    }else{
      docdata.template_type=[];
      docdata.send_html_template=[];
      docdata.template_id=[];
    }
      
    let variables = {
      sendTemplateNotificationinput: {...docdata, adp_type}
    };
    executeGraphQLMutation(SEND_DOC_TO_FINANCEIR, variables, client).then((result) => {
      toast.success("Success");
        dispatch(getLoanDetail(leadDetail.id,client));
        let redirecturl=''
        if(props.template_for==='send_to_financier'){
          redirecturl=`/lead-detail/soft-approval/${lead_id}`; 
        }else if(props.template_for==='credit_investigation'){
          redirecturl=`/lead-detail/credit-document-received/${lead_id}`;
        }else{
          redirecturl=`/lead-detail/orcr-verfied/${lead_id}`;
        }

        if(whatsapplink) window.open(whatsapplink);
        if(viberlink) window.open(viberlink);
        setLoading(false);
          if(redirecturl!=='')
            navigate(redirecturl)
    }).catch((err)=>{
      setLoading(false);
      toast.error(err.toString().replace('ApolloError:','')
      );
    });
  
  }

  const sendRepeatnotification=(type)=>{

    if(!verifyuploadeddocument()){
      toast.warning("Please upload all required documents.");
      return;
    }
    
    let links='';
    let docdata={lead_id:leadDetail.id,user_email:"",page_type:props.template_for,user_id:UserInfo?.id,"template_type":[],"template_id":[],"financier_id":[leadDetail?.financier?.filter((fn)=>fn?.is_proceed==="yes")?.[0]?.financier_id],"send_html_template":[],"doc_ids":"",remarks:"test",status:"1",created_by:UserInfo?.id,updated_by:UserInfo?.id};

    let oldhistory=leadDetail?.lead_template_history?.filter((th)=>th.template_type===type && th.page_type===props.template_for);

    oldhistory.sort(function(a,b){
      return new Date(b.created_date) - new Date(a.created_date);
    });

    if(oldhistory && oldhistory.length>0){
      docdata.template_type.push(`${type}`);
        docdata.send_html_template.push(`${oldhistory?.[0]?.send_html_template}`);
        docdata.template_id.push(`${oldhistory?.[0]?.template_id}`);
        docdata.user_email=oldhistory?.[0]?.user_email;
        docdata.doc_ids=oldhistory?.[0]?.doc_ids;
      const filteredArray = docdata.doc_ids.split(',').filter(value => availabledoc.includes(Number(value)));
      
      leadDetail?.leaddocs.forEach((v)=>{
        if(filteredArray.includes(String(v.doc_id)))
          links+=v.doc_path+" ";
      })
    }
    let variables = {
      sendTemplateNotificationinput: {...docdata, adp_type}
    };

    
  setLoading(true);
    executeGraphQLMutation(SEND_DOC_TO_FINANCEIR, variables, client).then((result) => {
      toast.success("Success");
        dispatch(getLoanDetail(leadDetail.id,client));
        let redirecturl=''
        if(props.template_for==='send_to_financier'){
          redirecturl=`/lead-detail/soft-approval/${crypto.encode(leadDetail.id)}`; 
        }else if(props.template_for==='credit_investigation'){
          redirecturl=`/lead-detail/credit-document-received/${crypto.encode(leadDetail.id)}`;
        }else{
          redirecturl=`/lead-detail/orcr-verfied/${crypto.encode(leadDetail.id)}`;
        }

        if(type==='whatsapp'){
          window.open("https://wa.me?text="+encodeURIComponent(oldhistory?.[0]?.send_html_template.replaceAll( "<br>","\n")+" "+links));
        }
        if(type==='viber'){
          docdata.user_email=docdata.user_email==="" ? "9178096510" : docdata.user_email;
          window.open(`https://msng.link/o/?+63${docdata.user_email}=vi`);
        }
        
        setLoading(false);
          if(redirecturl!=='')
            navigate(redirecturl)
    }).catch((err)=>{
      setLoading(false);
      toast.error(err.toString().replace('ApolloError:',''));
    });
  }

 

  const handleChange=(e,name)=>{
    if(name==="ci_name"){
      setCiname(e.target.value)
    }else{
      if(e?.value)
      setCicontact(e.value);
    }
  }

  return (
    <div className="image-form-outer">
      <div className="image-tab-panel emailer-outer">
        <div className="email-templete-sec p-lg-t">
          <span className="sent_to_finance_title_left">
            {SendToFinancer.send_mode_arr.length>0 && `Email | WhatsApp | Viber - ${props.template_title}` }
            
          </span>

          <Accordion allowZeroExpanded>
            {SendToFinancer.send_mode_checked.email ? (
              <div
                className="email-templete-sec emailtemplate"
                style={{ width: "100%" }}
              >
                <AccordionItem >
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="heading-icn">
                        <h3>Email</h3>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>

                  <div dangerouslySetInnerHTML={{ __html: (templateList && templateList.email && templateList.email.template_body) ? templateList.email.template_body.replaceAll("\n", "<br>") : '' }} />
                  
                   
                  </AccordionItemPanel>
                </AccordionItem>

                <br />
              </div>
            ) : null}

            {SendToFinancer.send_mode_checked.whatsapp ? (
              <div
                className="email-templete-sec emailtemplate"
                style={{ width: "100%" }}
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="heading-icn">
                        <h3>Whatsapp</h3>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  <div dangerouslySetInnerHTML={{ __html: (templateList && templateList.whatsapp && templateList.whatsapp.template_body) ? templateList.whatsapp.template_body.replaceAll("\n", "<br>") : '' }} />
                  </AccordionItemPanel>
                </AccordionItem>

                <br />
              </div>
            ) : null}

            {SendToFinancer.send_mode_checked.viber ? (
              <div
                className="email-templete-sec emailtemplate"
                style={{ width: "100%" }}
              >
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="heading-icn">
                        <h3>Viber</h3>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                  <div dangerouslySetInnerHTML={{ __html: (templateList && templateList.viber && templateList.viber.template_body) ? templateList.viber.template_body.replaceAll("\n", "<br>") : '' }} />
                  </AccordionItemPanel>
                </AccordionItem>

                <br />
              </div>
            ) : null}
            
          </Accordion>
          {SendToFinancer.send_mode_arr.length===0 ? (<p><br></br><br></br>
            No Templates have been selected for the Financier</p>) : null }
        </div>
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className={" switch-toggle"}>
            <div className="lead-form-heading send-financer-heading">
              <h2>{props.template_title}</h2>
              {
                activeFinancier?.financier ? 
                <span><b>{activeFinancier?.financier?.financierlang?.financier_name || ""}</b></span> : null
              }
            </div>
            <div className="lead-form-filed send-financer m-md-t">
              {displayBlock === "SelectMode" ? (
                <div>
                  <fieldset className="from-group">
                    {isEmailTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className="custom-control custom-checkbox">
                          <input
                            id="CallVerified"
                            type="checkbox"
                            aria-label="call verified"
                            className="custom-control-input "
                            onClick={(e) => toggleswitch("EMAIL", e)}
                            checked={
                              SendToFinancer.send_mode_arr.includes("EMAIL")
                                ? true
                                : false
                            }
                          />
                          <label className="custom-control-label">Email</label>
                        </div>

                        
                        
                      </div>
                    ) : null}
                    {isWhatsappTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className=" custom-control custom-checkbox">
                          <input
                            aria-label="whatsapp"
                            id="pending"
                            type="checkbox"
                            name=""
                            value=""
                            className="custom-control-input"
                            onClick={(e) => toggleswitch("WHATSAPP", e)}
                            checked={
                              SendToFinancer.send_mode_arr.includes("WHATSAPP")
                                ? true
                                : false
                            }
                          />
                          <label className="custom-control-label">WhatsApp</label>
                        </div>
                      </div>
                    ) : null}
                    {isViberTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className=" custom-control custom-checkbox">
                          <input
                            aria-label="for viber"
                            id="pending"
                            type="checkbox"
                            name=""
                            value=""
                            className="custom-control-input"
                            onClick={(e) => toggleswitch("VIBER", e)}
                            checked={
                              SendToFinancer.send_mode_arr.includes("VIBER")
                                ? true
                                : false
                            }
                          />
                          <label className="custom-control-label">Viber</label>
                        </div>
                      </div>
                    ) : null}
                  </fieldset>

                  <div className="btn-save-remarks m-md-t">
                    <button type="button" className="btn-primary" disabled>
                      Save & Next
                    </button>
                    <button type="button" className="btn-line" onClick={showModalViewTimeLine}>
                      Remarks <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                    </button>
                  </div>
                </div>
              ) : null}

              {displayBlock === "DocumentList" ? (
                <div>
                  <fieldset className="from-group">
                    {isEmailTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className="custom-control custom-checkbox">
                          <input
                            aria-label="for email"
                            id="CallVerified"
                            type="checkbox"
                            className="custom-control-input "
                            onClick={(e) => toggleswitch("EMAIL", e)}
                            checked={
                              SendToFinancer.send_mode_checked.email
                            }
                            disabled={
                              SendToFinancer.send_mode_disabled.email
                            }
                          />
                          <label className="custom-control-label">Email</label>
                        </div>

{ leadDetail?.lead_template_history?.filter((th)=>th.template_type==='email' && th.page_type===props.template_for)?.length>0 ? 
                          <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th)=>th.template_type==='email' && th.page_type===props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={showModalFinancerEmail}>View</span>{!props.is_completed ? <span onClick={()=>sendRepeatnotification('email')}>Repeat</span> : ''}</div> :''}




                          
                      </div>
                    ) : null}
                    {isWhatsappTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className=" custom-control custom-checkbox">
                          <input
                            aria-label="whatsapp"
                            id="pending"
                            type="checkbox"
                            name=""
                            value=""
                            className="custom-control-input"
                            onClick={(e) => toggleswitch("WHATSAPP", e)}
                            checked={
                              SendToFinancer.send_mode_checked.whatsapp
                            }
                            disabled={
                              SendToFinancer.send_mode_disabled.whatsapp
                            }
                          />
                          <label className="custom-control-label">WhatsApp</label>
                        </div>
                          { leadDetail?.lead_template_history?.filter((th)=>th.template_type==='whatsapp' && th.page_type===props.template_for)?.length>0 ? 
                          <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th)=>th.template_type==='whatsapp' && th.page_type===props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={showModalFinancerWhatsApp}>View</span>{!props.is_completed ? <span onClick={()=>sendRepeatnotification('whatsapp')}>Repeat</span>: ''}</div> :''}

                        
                      </div>
                    ) : null}
                    {isViberTemplateEnable ? (
                      <div className="select-mode-list">
                        <div className=" custom-control custom-checkbox">
                          <input
                            aria-label="viber"
                            id="pending"
                            type="checkbox"
                            name=""
                            value=""
                            className="custom-control-input"
                            onClick={(e) => toggleswitch("VIBER", e)}
                            checked={
                              SendToFinancer.send_mode_checked.viber
                            }
                            disabled={
                              SendToFinancer.send_mode_disabled.viber
                            }
                          />
                          <label className="custom-control-label">Viber</label>
                          </div>

                          { leadDetail?.lead_template_history?.filter((th)=>th.template_type==='viber' && th.page_type===props.template_for)?.length>0 ? 
                          <div class="select-list-doc"><span class="date-time-txt">Send On {dateFormat(leadDetail?.lead_template_history?.filter((th)=>th.template_type==='viber' && th.page_type===props.template_for)?.at(-1).created_date, 'd mmm yyyy h:MM TT')}</span><span onClick={showModalFinancerViber}>View</span>{!props.is_completed ? <span onClick={()=>sendRepeatnotification('viber')}>Repeat</span> : '' } </div> :''}
                        </div>
                    ) : null}
                  </fieldset>

                  {(isEmailTemplateEnable || isWhatsappTemplateEnable || isViberTemplateEnable) ? "" : "No templates has been configured" }

                  { props.template_for==='credit_investigation' && 
                  <>

                    <div className='row'>
                    <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={()=>{}}
            >
                        <fieldset class="form-filed col-md-12 ">
                          <div class="material ">
            

                        <FormsyInputField
                        id={"ci_name"}
                        name={"ci_name"}
                        type={"text"}
                        value={ciname}
                        placeholder=" "
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={"CI Contact Person"}
                        onChange={ (data)=>{
                          handleChange(data,'ci_name')
                        }
                        }
                        validations={"isWords"}
                        validationError={
                          ciname ?
                        "Must only contain alphabets"  :""
                        }
                        required={true}
                    />

                          </div>
                        </fieldset>
                        <fieldset class="form-filed col-md-12 ">
                          <div class="material ">


                          <FormsyInputField
                        id={"ci_contact"}
                        name={"ci_contact"}
                        type={"pattern-format"}
                        value={cicontact}
                        placeholder=" "
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={"CI Contact Number"}
                        onChange={(data)=>
                          handleChange(data,'ci_contact')}
                        format={ "+63 ### ### ####" }
                        validations={"isLength:10,isValidPhoneNumber"}
                        validationError={
                          cicontact ? 
                          "Number must be of 10 digits" :""
                        }
                        required={true}
                    />

                          </div>
                          </fieldset>
                              </Formsy>        
                    </div>
                  </>
                }
{ SendToFinancer.send_mode_arr.length>0 ? 
                    <>
                  <h3>Doc Attached</h3>
                  <div className="financer-doc-list ">
                    <Tab.Container
                      id="customer_coborrower-tabs-example"
                      defaultActiveKey="borrower"
                    >
                      <Nav variant="pills" className="flex-column tab-line m-lg-b doc-attach-tab">
                        <div className="tab-list">
                          {props.pagetab.map((pg,key) => (
                            <Nav.Item key={key}>
                              <Nav.Link eventKey={pg.type}>
                                {pg.key}
                              </Nav.Link>
                            </Nav.Item>
                          ))}

                        </div>
                      </Nav>
                      <Tab.Content>
                        {props.pagetab.map((pg,key) => (
                          <Tab.Pane eventKey={pg.type} key={key}>

                            <Accordion allowMultipleExpanded="true" allowZeroExpanded>
                              {docList &&
                                docList.length > 0 && availabledoc.length>0 &&
                                docList.map((element) => pg.doc.split(',').includes(String(element.id)) && element.child_ids_exists  ?
                                  (
                                    <AccordionItem key={element.id}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <div className=" custom-control custom-checkbox">
                                            <input
                                              aria-label="doc category"
                                              id="pending"
                                              type="checkbox" value={element.id}
                                              className="custom-control-input" onChange={(e) => selectdocument(e)}
                                              checked={SendToFinancer.document_category_ids.includes(element.id) ? true : false}
                                            />
                                            <label className="custom-control-label">
                                              {element.doclang.name}
                                            </label>
                                          </div>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <div className="sub-list">
                                          <ul className="">
                                            {element.child && element.child.length > 0 &&
                                              element.child.map((subdoc) =>
                                                subdoc.parent_id === element.id && availabledoc.includes(subdoc.id) ? (
                                                  <li key={subdoc.id}>
                                                    <div className=" custom-control custom-checkbox">
                                                      <input
                                                        aria-label="document select"
                                                        id="pending"
                                                        type="checkbox"
                                                        className="custom-control-input" value={subdoc.id}
                                                        parent_id={element.id}
                                                        onChange={(e) => selectdocument(e)}
                                                        checked={SendToFinancer.document_category_ids.includes(subdoc.id) ? true : false}
                                                      />
                                                      <label className="custom-control-label">
                                                        {subdoc.doclang.name}
                                                      </label>
                                                    </div>
                                                  </li>
                                                ) : null
                                              )}
                                          </ul>
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                  ) : null)}
                            </Accordion>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div></>
                  :''}

                  { loading ? <Loader/> : ''} 
                 
                  <div className="btn-save-remarks m-md-t">
                  { (markAsFreezed || accessCondition || ( props.template_for==='credit_investigation' && !allowSubmit ? true :  (SendToFinancer.send_mode_arr.length>0 && SendToFinancer.document_category_ids.length === 0) ? true : props.is_completed ? true : false)) ? <span className="span-disabled">Save & Next</span> : 
                    <button  disabled={ markAsFreezed || accessCondition || ( props.template_for==='credit_investigation' && !allowSubmit ? true :  (SendToFinancer.send_mode_arr.length>0 && SendToFinancer.document_category_ids.length === 0) ? true : props.is_completed ? true : false) } type="button" onClick={(e)=>{senddocumenttofinanceir()}} className="btn-primary">
                      Save & Next
                    </button>
                    }
                    <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                      <span className="m-xs-l">({remarksHistory?.length || 0})</span>
                    </button>
                  </div>

                  <div className="view-timeline-popup">
                    <div className="view-timeline-popup">
                      <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
                        <div className="modal-header">
                          <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={markAsFreezed} accessCondition={accessCondition} sectionInfo={props.sectionInfo}/>
                      </Modal>
                    </div>
                  </div>


                  <div className="view-timeline-popup image-timeline-popup financer-email-popup">
                <Modal show={FinancerEmail} handleClose={hideModalFinancerEmail} >
                    <div className="modal-header">
                        <h2>Last Email Sent Details</h2>
                        <span className="sub-heading"></span>
                    </div>
                    <div className="modal-body">
                        <Accordion allowZeroExpanded>
                            {
                                leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => ( temp.template_type==='email' && temp.page_type===props.template_for && 
                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />
                                            
                                            <div className="financer-email-data">
                                                <div className="email-attech-doc-list">
                                                    <h3>Attached Documents</h3>
                                                    <ul>
                                                        {temp?.doc_ids && temp?.doc_ids?.split(',')?.length>0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                          <li>
                                                           { leadDetail?.leaddocs?.filter((ld)=>ld.doc_id===Number(tag_image))?.[0]?.docmaster?.doclang?.name }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                </Modal>
                <Modal show={FinancerWhatsApp} handleClose={hideModalFinancerWhatsApp} >
                    <div className="modal-header">
                        <h2>Last WhatsApp Sent Details</h2>
                        <span className="sub-heading"></span>
                    </div>
                    <div className="modal-body">
                        <Accordion allowZeroExpanded>
                        {
                                leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => ( temp.template_type==='whatsapp' && temp.page_type===props.template_for && 
                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />
                                            
                                            <div className="financer-email-data">
                                                <div className="email-attech-doc-list">
                                                    <h3>Attached Documents</h3>
                                                    <ul>
                                                        {temp?.doc_ids && temp?.doc_ids?.split(',')?.length>0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                          <li>
                                                           { leadDetail?.leaddocs?.filter((ld)=>ld.doc_id===Number(tag_image))?.[0]?.docmaster?.doclang?.name }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                </Modal>
                <Modal show={FinancerViber} handleClose={hideModalFinancerViber} >
                    <div className="modal-header">
                        <h2>Last Viber Sent Details</h2>
                        <span className="sub-heading"></span>
                    </div>
                    <div className="modal-body">
                        <Accordion allowZeroExpanded>
                        {
                                leadDetail?.lead_template_history?.length && leadDetail?.lead_template_history?.map((temp, index) => ( temp.template_type==='viber' && temp.page_type===props.template_for && 
                                    <AccordionItem >
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Sent on {dateFormat(temp?.created_date, 'd mmm yyyy h:MM TT')}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div dangerouslySetInnerHTML={{ __html: (temp && temp.send_html_template) ? temp.send_html_template.replaceAll("\n", "<br>") : '' }} />
                                            
                                            <div className="financer-email-data">
                                                <div className="email-attech-doc-list">
                                                    <h3>Attached Documents</h3>
                                                    <ul>
                                                        {temp?.doc_ids && temp?.doc_ids?.split(',')?.length>0 && temp?.doc_ids?.split(',').map((tag_image, key) => (
                                                          <li>
                                                           { leadDetail?.leaddocs?.filter((ld)=>ld.doc_id===Number(tag_image))?.[0]?.docmaster?.doclang?.name }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))
                            }
                        </Accordion>
                    </div>
                </Modal>
            </div>

                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}


export default SendDocORCR;