import { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import UploadDocumentView from './UploadDocumentView'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CONTRACT_SIGNED, SENT_TO_FINANCIER } from '../../../config/refinance/constant';
import { executeGraphQLQuery } from '../../../services/refinance/executeGraphQLQuery';
import { GET_SAVED_DOCUMENTS, WEB_DOC_CONFIG_LIST_REFINANCE } from '../../../services/refinance/leads.gql';
import { useApolloClient } from '@apollo/client';
import Loader from '../../elements/Loader';
// import { PAYMENT_PROOF_DOCS} from '../../../../src/config/constants';

function UCRFDocumentUpload(props) {
    let params = useParams();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [activeTab, setActiveTab] = useState('first_submit');
    const [isLoading, setLoading] = useState(false);
    const [docCategoryList, setDocumentCategoryList] = useState([]);
    // let docCategoryList = props.docCategoryList || []

    

    const { leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
        leadDetail: lead?.ucrfLeadDetail || {}
    }));

    useEffect(()=>{
        getDocCategoryList(leadDetail.lead_id)
    },[leadDetail])

    const getDocCategoryList = async (lead_id) => {
        try {
            setLoading(true)
            const result = await executeGraphQLQuery(WEB_DOC_CONFIG_LIST_REFINANCE(lead_id), client);
            if (result?.data?.webDocumentConfig) {
                setDocumentCategoryList(result.data.webDocumentConfig);
            } else {
                setDocumentCategoryList([]);
            }
            savedDocumentList(lead_id)
        } catch (error) {
            setDocumentCategoryList([]);
        }
    }

    const savedDocumentList = async (lead_id) => {
        try {
            let payload = []
            const result = await executeGraphQLQuery(GET_SAVED_DOCUMENTS(lead_id), client);
            if (result?.data?.getSavedDocuments?.length) {
                payload = result.data.getSavedDocuments
                dispatch({ type: "GET_LEAD_DOCS_UCRF", payload });
            } else {
                dispatch({ type: "GET_LEAD_DOCS_UCRF", payload });
            }
            setLoading(false)
        } catch (error) {
        }
    }

    let subStatusMarkedTillnow = true
    let isPaymentProofPresent = true
    useEffect(() => {
        setActiveTab('first_submit');
    }, [params])

    const handleSelectTab = (eventKey) => {
        setActiveTab(eventKey);
    }
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    return (
        <div className="document-upload-tabs" key={activeTab}>
            {isLoading ? <Loader /> : ''}
            <div className="doument-upload-left-panel">
                <div className="heading-txt">
                    <h2>Upload Documents</h2>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} onSelect={handleSelectTab}>
                    <Nav variant="pills" className="flex-column tab-line">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'first_submit'} eventKey="first_submit" disabled={false}>First Submit</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'disbursal'} eventKey="disbursal" disabled={!subStatusMarkedTillnow}>Pre Disbursal</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeTab === 'post_disbursal'} eventKey="post_disbursal" disabled={!isPaymentProofPresent}>Post Disbursal</Nav.Link>
                            </Nav.Item>
                        </div>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane active={activeTab} eventKey={activeTab}>
                            <div className="file-upload-bx">
                                <UploadDocumentView activeDocTab={'upload'} activeTab={activeTab} key={activeTab} docCategoryList={docCategoryList} markAsFreezed={markAsFreezed} />
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}
export default UCRFDocumentUpload;