export const saveStateToLocalStorage = (state) => {
  try {
    // Deep copy the state to avoid sharing references
    const stateCopy = JSON.parse(JSON.stringify(state));
    const serializedState = JSON.stringify(stateCopy);
    localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    console.error('Error saving state to localStorage:', error);
  }
};

  
  export const loadStateFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      console.error('Error loading state from localStorage:', error);
      return undefined;
    }
  };
  