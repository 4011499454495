import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_CUSTOMER_CHARACTER_REF_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, SUB_STATUS_CODES} from '../../../config/constants';
import AiIcon from '../../elements/ImageAI';
import { useAiIcon } from '../../hooks/useAiIcon';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const FORM_FIELDS = FormFields["CHARACTER_REFERENCE"];

const CharacterReference = (props) => {
    const [characterRefDetails, setCharacterRefDetails] = useState({});
    const [orgcharacterRefDetails, setOrgCharacterRefDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [isSkipped, setIsSkipped] = useState("0");
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const { masterdata, lead, remarksHistory, leadFiles } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    const viberPersonalData = getContext?.viberData?.get_viber_column?.personal_details || [];
    const { showAiIcon } = useAiIcon(characterRefDetails, viberPersonalData);

    let navigate = useNavigate();
    let { lead_id} = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-character-reference")?.is_edit_access;
    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.character_ref) {
        let originalData = { ...lead.leadDetail.character_ref };
        let updatedData = { ...originalData };

        if (viberPersonalData) {
          if (viberPersonalData) {
            Object.keys(viberPersonalData).forEach(key => {
              if (!updatedData[key] && viberPersonalData[key]) {
                updatedData[key] = viberPersonalData[key];
              }
            });
          }
        }
        setOrgCharacterRefDetails(originalData);
        setCharacterRefDetails(updatedData);
      }
    }, [lead]);

    useEffect(() => {
      if(isSkipped === "1") handleSubmit();
    }, [isSkipped]);// eslint-disable-line react-hooks/exhaustive-deps
    
    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      return options;
    }, [masterdata]);

    const handleInputChange = event => {
        if (event.target.id) {
            setCharacterRefDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setCharacterRefDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setCharacterRefDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody={};
      if(isSkipped === "0"){
        reqBody = {
          lead_id: lead_id,
          full_name: characterRefDetails.full_name || null,
          mobile_number: characterRefDetails.mobile_number || null,
          address: characterRefDetails.address || null,
          relationship: characterRefDetails.relationship || null,
          is_skipped: isSkipped,
          updated_by: user_id || 0
        }
        let viberReqBody = {
          lead_id: lead_id,
          parent_object_name: 'personal_details',
          changed_object: {
            full_name: characterRefDetails.full_name?.toString() || null,
            mobile_number: characterRefDetails.mobile_number?.toString() || null,
            address: characterRefDetails.address?.toString() || null,
            relationship: characterRefDetails.relationship?.toString() || null
          }
        };

        let viberMutation = SAVE_VIBER_DATA;
        executeGraphQLMutation(viberMutation, viberReqBody, client)
          .then(resp => {
            console.log(resp);
          })
          .catch(err => console.log(err));
      } else {
        reqBody = {
          lead_id: lead_id,
          is_skipped: isSkipped,
          updated_by: user_id || 0
        }
      }

      let mutation = SAVE_CUSTOMER_CHARACTER_REF_DETAILS, 
          variables = { characterRefInput: reqBody };

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_customer_character_ref_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTimeout(() => {
          dispatch(getLoanDetail(lead_id,client))
        }, 2000);
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }
    
    const navigateToNext = () => {
      navigate(`/lead-detail/co-borrower-details/basic-details/${lead_id}`);
    }

    let CharactedRefDetailsForm = FORM_FIELDS;

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const skippedStatusSubmit = () => {
      setIsSkipped("1");
      setCharacterRefDetails({});
      setAllowSubmit(true);
    };

    const sectionInfo = {section : "Customer details" , sub_section : "Character reference"}

    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;
    return (
      <div className="image-form-outer">
        <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Customer Details</span>
              <h2>Character Reference</h2>
              {((lead?.leadDetail?.character_ref == null || (lead?.leadDetail?.character_ref?.full_name == null && lead?.leadDetail?.character_ref?.address == null && lead?.leadDetail?.character_ref?.mobile_number == null && lead?.leadDetail?.character_ref?.relationship == null)) && !getContext.markAsFreezedNewFlow) ? (
                <span
                  className="skip-txt"
                  // onClick={onSubmit.bind(this, "skip_status")}
                  onClick={skippedStatusSubmit}
                >
                  Skip
                </span>
              ) : null}
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field character"
            >
              <div className="row">
                {CharactedRefDetailsForm.map((field) =>
                  ["text", "pattern-format", "number-format"].includes(
                    field.type
                  ) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(characterRefDetails && characterRefDetails[field.name]) || ''}
                        placeholder=" "
                        thousandsGroupStyle="thousand"
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        label={field.label}
                        onChange={
                          ["pattern-format", "number-format"].includes(
                            field.type
                          )
                            ? (data) =>
                                handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          characterRefDetails[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                        disabled={characterRefDetails['is_skipped'] === "1"}
                        readOnly={orgcharacterRefDetails?.[field.name] && isSubStatus3Marked}
                      />
                      {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                  ) : field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value:
                            ALL_OPTIONS &&
                            ALL_OPTIONS[field.optionsKey] &&
                            ALL_OPTIONS[field.optionsKey].filter(
                              ({ value }) => value === (characterRefDetails[field.name])
                            ),
                          isDisabled: field.readOnly || characterRefDetails['is_skipped'] === "1" || (orgcharacterRefDetails?.[field.name] && isSubStatus3Marked) ? true : false,
                        }}
                        required={field.required}
                        value={characterRefDetails[field.name]}
                        onChange={(data) =>
                          handleSelectChange(data, field.name)
                        }
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                  ) : null
                )}
              </div>

              <div className="btn-save-remarks">
              {isSubmitDisabled || !allowSubmit || getContext.markAsFreezedNewFlow || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span>
                :
                <button aria-label="save next" type="submit" className="btn-primary">
                  Save & Next
                </button>}
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
          </Modal>
        </div>
      </div>
    );
}
export default CharacterReference;