import { useState, useEffect } from 'react';
import React from 'react';
import dateFormat from 'dateformat';
import { useSelector, useDispatch } from 'react-redux';
import { getLeadRemarkUCRF, saveLeadRemarksUCRF } from '../../../../store/action/allAction';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import Loader from '../../../elements/Loader';
import DOMPurify from 'dompurify';

const LeadRemarkHistory = ({ showremarkform, isLoading, isRemarkDisable, sectionInfo }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [SubmitDisable, setSubmitDisable] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch();

  const { remarksHistory, leadDetail, userInfo } = useSelector(({ lead, user }) => {
    return {
      remarksHistory: lead.remarksHistory || [],
      leadDetail: lead.ucrfLeadDetail,
      userInfo: user.user_information,
    };
  });
  let userDetail = userInfo && JSON.parse(userInfo);
  let user_id = userDetail && (userDetail.id || 0);
  let user_name = userDetail && (userDetail.name || '');

  useEffect(() => {
    setFormData({});
  }, []);
  const changeHandler = (key, e) => {
    setFormData({ ...formData, [key]: e.target.value });
    setError({ ...error, [key]: '' });
  };
  const saveRemarks = async () => {
    if (!formData.remarks || /^\s*$/.test(formData.remarks)) {
      setError({ remarks: 'This is a Required Field' });
      return false;
    }
    setLoading(true);
    setSubmitDisable(true)
    const LeadData = {
      lead_id: String(leadDetail.lead_id),
      status_id: String(0),
      remark: formData.remarks,
      user_id: String(user_id),
      user_name: user_name,
      section: sectionInfo?.section || '',
      sub_section: sectionInfo?.sub_section || '',
    };
    let { status } = await dispatch(saveLeadRemarksUCRF({ LeadData: LeadData }, client));
    if (status) {
      setFormData((formData) => ({
        ...formData,
        remarks: '',
      }));
      setLoading(false);
      toast.success('Success');
      setFormData({});
      dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));
      setSubmitDisable(false)
    } else {
      toast.error('Error');
      setLoading(false);
      setSubmitDisable(false)
    }
  };
  const blurHandler = (key, event) => {
    const { value } = event.target;
    event.target.value = DOMPurify.sanitize(value);
    setFormData({ ...formData, [key]: event.target.value });
  };
  return (
    <div className="comment-wrap">
      <div className="modal-body">
        <div className="timeline-history-tabel">
          <table>
            <tbody>
              {remarksHistory &&
                remarksHistory.map((remark) => (
                  <tr>
                    <td>
                      <span className="date">{dateFormat(remark.created_date, 'd mmm')}</span>
                      <span className="time">{dateFormat(remark.created_date, 'hh:MM TT')}</span>
                    </td>
                    <td>
                      <div className="remarkdiv">
                        <span className="heading" title={remark.remark}>
                          {remark.remark}
                        </span>
                        <span className="Subheading">By {remark.created_by_name}</span>
                      </div>
                      <div className="sectiondiv">
                        <span className="heading" title={remark.section}>
                          {remark.section}
                        </span>
                        <span className="Subheading">{remark && remark.sub_section ? '(' + remark.sub_section + ')' : ''}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              {remarksHistory && remarksHistory.length === 0 ? (
                <tr>
                    <td>
                      <h4>
                        <center className="t-danger">{!isLoading && 'No remark found'}</center>
                      </h4>
                    </td>
                  </tr>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
        {loading ? <Loader /> : null}
        {showremarkform ? (
          <div>
            <fieldset className="form-filed m-md-b">
              <span className="remark-heading-txt">Add Remark</span>
              <div className="material">
                <textarea
                  name="remarks"
                  id="remarks"
                  placeholder=" "
                  className="form-input"
                  rows="3"
                  onChange={(e) => changeHandler('remarks', e)}
                  onBlur={(e) => blurHandler('remarks', e)}
                  value={formData.remarks || ''}
                  aria-label="remarks"
                ></textarea>
                <label data-label="Add Remark" className="form-label"></label>
                <span className="error-msg"></span>
              </div>
              <span className="error-msg">{error.remarks ? error.remarks : ''}</span>
            </fieldset>

            {isRemarkDisable ? (
              <span className="span-disabled ">Submit</span>
            ) : (
              <button aria-label="save remarks" disabled={SubmitDisable} class="btn-primary" onClick={saveRemarks}>
                Submit
              </button>
            )}
          </div>
        ) : (
          ''
        )}

        {/* {markAsFreezed || accessCondition ? (
              <span className="span-disabled ">Submit</span>
            ) : (
              <button aria-label="save remarks" className="btn-primary" onClick={saveRemarks} disabled={markAsFreezed || accessCondition}>
                Submit
              </button>
            )}
          </div>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
};

export default LeadRemarkHistory;
