import React from 'react';
import Modal from './../../elements/Modal';
import DateFormate from "dateformat";

const DocumentHistory = ({ documentHistoryList, documentHistoryModelShowFlag, hideModalDocumentHistory }) => {
    return (
        <Modal show={documentHistoryModelShowFlag} handleClose={hideModalDocumentHistory} >
            <div className="modal-header">
                <h2>Document History</h2>
            </div>
            <div className="modal-body" >
                <table>
                    <tbody>
                        {
                            documentHistoryList && documentHistoryList.length ? documentHistoryList.map((history, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="date">{history && history.created_date ? DateFormate(
                                            history.created_date, "dd mmm") : ""}</span>
                                        <span className="time"> {history && history.created_date ? DateFormate(history.created_date, "h:MM TT") : ""}</span>
                                    </td>
                                    <td>
                                        <span className="heading">{history?.doc_name + "(" + history?.doc_parent_name + ")"}</span>
                                        <span className="Subheading">{history?.remarks}</span>
                                    </td>
                                    <td>{history?.created_by_name}</td>
                                    <td>
                                    </td>
                                </tr>
                            )) : "No Records Found"}

                    </tbody>
                </table>
            </div>
        </Modal>
    )
}
export default DocumentHistory;