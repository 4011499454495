import React from 'react'

function DocIcon({ goToPrevSlide, goToNextSlide }) {
    return (
        <ul>
            <li className="icn-pre-slide">
                <button onClick={goToPrevSlide}>
                    <i className="ic-keyboard_arrow_left"></i>
                </button>
            </li>
            <li className="icn-next-slide">
                <button onClick={goToNextSlide}>
                    <i className="ic-keyboard_arrow_right"></i>
                </button>
            </li>
        </ul>
    )
}

export default DocIcon