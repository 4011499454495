import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import {
  selectFirstPaymentList,
} from "./utils";
import Formsy from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";

const LoanExpectedQuoteSummary = (props) => {
  const [activeFinancier, setActiveFinancier] = useState("finance-0");
  const [activeFinancierQuote, setActiveFinancierQuote] = useState([]);
  const [activeFinancierId, setActiveFinancierId] = useState(0);
//   const client = useApolloClient();
  const onTabChange = async (tab, event) => {
    if (event.type === "click") {
        setActiveFinancier(tab);
        const quote = quotesArr?.filter(
          (quote) =>
            quote.financier_id ===
            Number(event?.target?.attributes?.financeid?.value)
        );
        setActiveFinancierQuote(quote);
        setActiveFinancierId(quote[0]?.financier_id || 0);
    }
  };
  const [selectedFinanciers, setSelectedFinanciers] = useState([]);
  const [quotesArr, setQuotesArr] = useState([]);
  const enableEditQuote = () => {
    props.editQuote();
  }
  useEffect(() => {
    let quotes = selectFirstPaymentList(props.selectionquotes);
    setQuotesArr(quotes);
    setSelectedFinanciers(quotes.map((ele) => ele.financier_id));
    setActiveFinancierQuote([quotes[0]]);
  }, [props.lead_id]);
  // console.log("=========activeFinancierQuote=======", activeFinancierQuote);
  return (
    <div className="">
      <div className="doument-gallery-tabs">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeFinancier}
          onSelect={(tab, e) => onTabChange(tab, e)}
          defaultActiveKey="finance-0"
        >
          <Nav variant="pills" className="flex-column financier-pills">
            <div className="tab-list">
              {selectedFinanciers?.length
                ? selectedFinanciers?.map((ele, key) => (
                    <Nav.Item>
                      <Nav.Link
                        eventKey={"finance-" + key}
                        financeId={parseInt(ele)}
                      >
                        {quotesArr?.find(
                          (quotes) => quotes.financier_id === ele
                        )?.financier_name || ""}
                      </Nav.Link>
                    </Nav.Item>
                  ))
                : null}
            </div>
          </Nav>
        </Tab.Container>

        <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    // onValid={() => setAllowSubmit(true)}
                    // onInvalid={() => setAllowSubmit(false)}
                    // onValidSubmit={(e) => {calculateLoan('calculate','',1);toggleswitch('SUMMARY', 'calculate_loan')}}
                >
        <div className="loan-form">
          <div className="heading-top">
            <span>Carmudi Calculator</span>
            <div className="action-icn">
              <ul>
                <li
                //   style={{
                //     display:
                //       !EditView && !editButtonCondition && !props.markAsFreezed
                //         ? "block"
                //         : "none",
                //   }}
                //   onClick={() => toggleswitch("EDITVIEW", "edit_btn")}
                >
                  <i className="ic-createmode_editedit" onClick={enableEditQuote}></i>
                </li>
              </ul>
            </div>
          </div>

          <div className="loan-form-filed-scroll">
            <div className="loan-filed-sec">
              <div className="heading-dp-txt">
                <span>Summary Of Loan</span>
              </div>
              <div className="form-group-bx">
                <h3>Basic Details</h3>
                <div className="row">
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="amount_financed"
                        name="amount_financed"
                        type="text"
                        value={activeFinancierQuote[0]?.payment_list[0]?.amount_financed ?? 0}
                        placeholder=""
                        label={"Amount Financed"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>

                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="interest_rate"
                        name="interest_rate"
                        type="text"
                        value={activeFinancierQuote[0]?.payment_list[0]?.instalment_amount ?? 0}
                        placeholder=""
                        label={"Installment Amount"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>

                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="tenure"
                        name="tenure"
                        type="text"
                        value={activeFinancierQuote[0]?.payment_list[0]?.tenure ?? 0}
                        placeholder=""
                        label={"Tenure (In Months)"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="interest_rate"
                        name="interest_rate"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.interest_rate ?? 0) + "%"}
                        placeholder=""
                        label={"Interest Rate"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>

                   
                    
                </div>
              </div>
              <div className="form-group-bx">
                <h3>For Customer</h3>
                <div className="row">
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="chattel_fee_age"
                        name="chattel_fee_age"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.chattel_fee_age ?? 0) + "%"}
                        placeholder=""
                        label={"Chattel Fee %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="chattel_fee_amount"
                        name="chattel_fee_amount"
                        type="text"
                        value={activeFinancierQuote[0]?.payment_list[0]?.chattel_fee ?? 0}
                        placeholder=""
                        label={"Chattel Fee Amount"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    
                    
                </div>
              </div>
              <div className="form-group-bx">
                <h3>For Dealer/Agent</h3>
                <div className="row">
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="carmudi_incentive_age"
                        name="carmudi_incentive_age"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.carmudi_incentive_age ?? 0) + '%'}
                        placeholder=""
                        label={"Carmudi Incentive %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="carmudi_incentive_amount"
                        name="carmudi_incentive_amount"
                        type="text"
                        value={activeFinancierQuote[0]?.payment_list[0]?.carmudi_incentive_amount ?? 0}
                        placeholder=""
                        label={"Carmudi Incentive Amount"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="carmudi_top_up_age"
                        name="carmudi_top_up_age"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.carmudi_top_up_age ?? 0) + '%'}
                        placeholder=""
                        label={"Carmudi Top Up %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="carmudi_top_up_amount"
                        name="carmudi_top_up_amount"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.carmudi_top_up_amount ?? 0)}
                        placeholder=""
                        label={"Carmudi Top Up Amount"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="total_carmudi_incentive"
                        name="total_carmudi_incentive"
                        type="text"
                        value={(activeFinancierQuote[0]?.payment_list[0]?.total_carmudi_incentive_age ?? 0) + '%'}
                        placeholder=""
                        label={"Total Carmudi Incentive %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="total_carmudi_incentive_amount"
                        name="total_carmudi_incentive_amount"
                        type="text"
                        value={"Rp 135000"}
                        placeholder=""
                        label={"Total Carmudi Incentive Amount"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="incentiv_tax_age"
                        name="incentiv_tax_age"
                        type="text"
                        value={"10%"}
                        placeholder=""
                        label={"Incentive Tax %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="total_carmudi_incentive_net_of_tax"
                        name="total_carmudi_incentive_net_of_tax"
                        type="text"
                        value={"Rp 135000"}
                        placeholder=""
                        label={"Total Carmudi Incentive Net of Tax"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    
                    
                </div>
              </div>
              <div className="form-group-bx">
                <h3>For Carmudi</h3>
                <div className="row">
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="incentive_age_from_financier"
                        name="incentive_age_from_financier"
                        type="text"
                        value={"12%"}
                        placeholder=""
                        label={"Incentive %age from Financier"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="incentive_tax_age"
                        name="incentive_tax_age"
                        type="text"
                        value={"35000"}
                        placeholder=""
                        label={"Incentive Tax %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id="incentive_amount_from_financier_net_tax"
                        name="chattel_fee_age"
                        type="text"
                        value={"5%"}
                        placeholder=""
                        label={"Chattel Fee %age"}
                        // validationError=""
                        //   required={true}
                        //   showAsterisk={true}
                        disabled
                      />
                    </fieldset>
                   
                    
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        </Formsy>
      </div>
    </div>
  );
};

export default LoanExpectedQuoteSummary;
// const areEqual = (prevProps, nextProps) => {
//   return prevProps.carDetails === nextProps.carDetails;
// };

// export default React.memo(LoanQuoteSelection, areEqual);
