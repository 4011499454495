import { gql } from "@apollo/client";

export const UCRF_GET_ALL_DEALER_LIST = (vertical_id, user_id, search_text = "") => gql`
  query{
    getAllDealerList(vertical_id: ${vertical_id}, user_id: ${user_id}, search_text: "${search_text}"){ 
      value: dealer_id
      label: dealerName
      mapped_dealer_id
      dealerCode
    }
  }
`

export const UCRF_GET_INVENTORY_LIST = (dealer_id) => gql`
  query{
    getInventoryList(dealer_ids: ${dealer_id}){ 
      value
      dealer_id
      make_year
      make_id
      model_id
      version_id
      make_name
      model_name
      version_name
      label
      color
    }
  }
`