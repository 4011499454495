/**
 * This is a Helper file dedicatedly created for Lead related Helper methods
 */

import { COBORROWER_PRIMARY_DOC_ID, CUSTOMER_PRIMARY_DOC_ID } from "../../config/constants";

//doc ids to be added or removed from required tag
const addRemoveDocIdsMap = {
    33: [34],
    34: [33],
    39: [40, 41],
    40: [39, 41],
    41: [39, 40],
    37: [38],
    38: [37],
    75: [76],
    76: [75],
    81: [82, 83],
    82: [81, 83],
    83: [81, 82],
    79: [80],
    80: [79]
};

const LeadHelper = {
    getFilterDocumentList: (UploadedFiles, filter_doc_list, filter_doc_child_list, leadDetail, customer_data = null, coborrower_data = null) => {
        let filterFilesArr = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));

        const datawithCount = [...filterFilesArr.reduce((mp, o) => {
            if (!mp.has(o.doc_id)) mp.set(o.doc_id, { ...o, count: 0 });
            mp.get(o.doc_id).count++;
            return mp;
        }, new Map()).values()];
    
        let temp = [];
        let customerDetails = customer_data?.customer_type ? customer_data : leadDetail?.customer || {};
        let coborrowerDetails = coborrower_data?.cb_customer_type ? coborrower_data : leadDetail?.co_borrower || {};
        let currCustomerType = customerDetails?.customer_type || 0,iswaterBillUploaded=0,iselectricBillUploaded=0,isbarangybusinsspermitUploaded=0,ismayorpermitUploaded=0,isstipermitUploaded=0,ispaySlipUploaded=0,isBankStatementUploaded=0,
        currCBCustomerType = coborrowerDetails?.cb_customer_type || 0,
        iscbwaterBillUploaded=0,iscbelectricBillUploaded=0,iscbbarangybusinsspermitUploaded=0,iscbmayorpermitUploaded=0,iscbstipermitUploaded=0,iscbpaySlipUploaded=0,iscbBankStatementUploaded=0;
        filter_doc_list.forEach((element, i) => {
            if (element.parent_id === 0) {
                element.count = datawithCount.filter(data => data.doc_id === element.id).map(v => v.count)[0] || 0;
                iswaterBillUploaded = datawithCount.filter(data => data.doc_id === 33).map(v => v.count)[0] || 0;
                iselectricBillUploaded = datawithCount.filter(data => data.doc_id === 34).map(v => v.count)[0] || 0;
    
                isstipermitUploaded = datawithCount.filter(data => data.doc_id === 39).map(v => v.count)[0] || 0;
                ismayorpermitUploaded = datawithCount.filter(data => data.doc_id === 40).map(v => v.count)[0] || 0;
                isbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 41).map(v => v.count)[0] || 0;
    
    
                ispaySlipUploaded = datawithCount.filter(data => data.doc_id === 37).map(v => v.count)[0] || 0;
                isBankStatementUploaded = datawithCount.filter(data => data.doc_id === 38).map(v => v.count)[0] || 0;
    
                iscbwaterBillUploaded = datawithCount.filter(data => data.doc_id === 75).map(v => v.count)[0] || 0;
                iscbelectricBillUploaded = datawithCount.filter(data => data.doc_id === 76).map(v => v.count)[0] || 0;
                iscbstipermitUploaded = datawithCount.filter(data => data.doc_id === 81).map(v => v.count)[0] || 0;
                iscbmayorpermitUploaded = datawithCount.filter(data => data.doc_id === 82).map(v => v.count)[0] || 0;
                iscbbarangybusinsspermitUploaded = datawithCount.filter(data => data.doc_id === 83).map(v => v.count)[0] || 0;
                iscbpaySlipUploaded = datawithCount.filter(data => data.doc_id === 79).map(v => v.count)[0] || 0;
                iscbBankStatementUploaded = datawithCount.filter(data => data.doc_id === 80).map(v => v.count)[0] || 0;
    
                element["child"] = filter_doc_child_list.filter(data => data.parent_id === element.id).map(v=>{
                    let docObj=Object.assign({},v);
                    let is_required;
                        if(docObj.doc_section === "customer_details"){
                            is_required = v.customer_type && v.customer_type.includes(currCustomerType) ? "1" : '0';
                            // console.log(is_required)
                            if(is_required==='1'){
        
                                if(v.id===33 && iselectricBillUploaded && !iswaterBillUploaded) is_required='0';
                                if(v.id===34){
                                    is_required='1';
                                    if(iswaterBillUploaded && !iselectricBillUploaded) is_required='0'
                                }
        
                                if(v.id===39 && (ismayorpermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                                if(v.id===40 && (isstipermitUploaded || isbarangybusinsspermitUploaded)) is_required='0';
                                if(v.id===41){
                                    is_required='1'; 
                                    if(ismayorpermitUploaded || isstipermitUploaded) is_required='0'; 
                                }
                                
        
                                if(v.id===37 && isBankStatementUploaded && !ispaySlipUploaded) is_required='0';  
                                if(v.id===38){
                                    is_required='1';
                                    if(ispaySlipUploaded && !isBankStatementUploaded)  is_required='0';
                                }
                            }
                        }
                        else if(docObj.doc_section === "co_borrower_details"){
                            is_required = v.customer_type && v.customer_type.includes(currCBCustomerType) ? "1" : '0';
                            // console.log(docObj)
                            if(is_required==='1'){
        
                                if(v.id===75 && iscbelectricBillUploaded && !iscbwaterBillUploaded) is_required='0';
                                if(v.id===76){
                                    is_required='1';
                                    if(iscbwaterBillUploaded && !iscbelectricBillUploaded) is_required='0'
                                }
        
                                if(v.id===81 && (iscbmayorpermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                                if(v.id===82 && (iscbstipermitUploaded || iscbbarangybusinsspermitUploaded)) is_required='0';
                                if(v.id===83){
                                    is_required='1'; 
                                    if(iscbmayorpermitUploaded || iscbstipermitUploaded) is_required='0'; 
                                }
                                
        
                                if(v.id===79 && iscbBankStatementUploaded && !iscbpaySlipUploaded) is_required='0';  
                                if(v.id===80){
                                    is_required='1';
                                    if(iscbpaySlipUploaded && !iscbBankStatementUploaded)  is_required='0';
                                }
                            }
                        }
    
                        // console.log("doc_id ", v.id, is_required)
                    let uploaddocsCount = datawithCount.filter(data => data.doc_id === v.id).map(v => v.count)[0] || 0;
                    docObj.is_required = is_required;
                    docObj.count = uploaddocsCount     
                    return docObj;
                }) || [];
                temp.push(element);
            }
        });

        const updatedDocs = LeadHelper.getFinancierSoftApprovalDoc(temp, "pre-disbursal", "soft_approval_recieved", filterFilesArr, leadDetail?.financier);
        
        temp = temp?.filter(obj => obj.id !== 87) || [];
        temp = [...updatedDocs, ...temp]
        return { temp, docList: filter_doc_list };
    },
    updateChildDocList: (doc_list, doc_id, customer_type,sec_doc_id) => {
        return doc_list?.map(v => {
            if (doc_id && (v.id === doc_id || v.id === sec_doc_id)) {
                v["customer_type"] = `${customer_type}`;
                v.is_required='1';
            } else {
                v["customer_type"] = "";
            }
            return v;
        });
    },
    checkIsRequiredDocsUploaded: function (leadFiles, documentCategoryList, leadDetail, basicDetails, cbBasicDetails = null) {
        let filter_doc_list = documentCategoryList.filter(data => data.tab_name === "first_submit");
        let filter_doc_child_list = [];

        basicDetails = basicDetails || leadDetail?.customer || {};
        cbBasicDetails = cbBasicDetails || leadDetail?.co_borrower || {};

        filter_doc_list && filter_doc_list.forEach(data => {
            switch(data.id) {
                case CUSTOMER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, basicDetails?.primary_doc_id, basicDetails?.customer_type || 0,basicDetails?.secondary_doc_id);
                    break;
                }
                case COBORROWER_PRIMARY_DOC_ID: {
                    data.child = this.updateChildDocList(data.child, cbBasicDetails?.cb_primary_doc_id, cbBasicDetails?.cb_customer_type || 0, cbBasicDetails?.cb_secondary_doc_id);
                    break;
                }
                default:
            }
            filter_doc_child_list = [...filter_doc_child_list, ...data.child]
        })
        if (leadDetail && (basicDetails?.customer_type || cbBasicDetails?.cb_customer_type)) {
            let { docList } = this.getFilterDocumentList(leadFiles, filter_doc_list, filter_doc_child_list, leadDetail, basicDetails, cbBasicDetails);
            let required_doc_ids = [];
              docList?.forEach(doc => {
                if (["customer_details","co_borrower_details"].includes(doc.doc_section)) {
                  doc.child.forEach(child_doc => { if(child_doc.is_required === "1") required_doc_ids.push(child_doc.id) });
                }
            });
            
            let checkRequiredDocs = required_doc_ids.every(id => leadFiles.some(obj => obj.doc_id === id));
            return checkRequiredDocs;
        }
        return false;
    },
    getRequiredDocIds: function(obj, filter_doc_list, is_deleted) {
        let required_doc_ids = [];
        filter_doc_list?.forEach(doc => {
            doc.child.forEach(child_doc => { if(child_doc.is_required === "1") required_doc_ids.push(child_doc.id) });
        });
        const idsToAddRemove = obj?.id ? addRemoveDocIdsMap[obj.id] || [] : [];
        required_doc_ids = is_deleted ? [...required_doc_ids, ...idsToAddRemove] : required_doc_ids.filter((id) => !idsToAddRemove?.includes(id));
        return required_doc_ids;
    },
    getFinancierSoftApprovalDoc: function(temp, tabName, docSection, filterFilesArr, financiers) {
        let docs = temp.filter((doc) => doc.tab_name === tabName && doc.doc_section === docSection);

        const updatedDocs = docs.map(doc => {
            const updatedChild = doc.child.map(child => {
                const updatedChildWithFinanciers = financiers.map(financier => ({
                    ...child,
                    financier_id: financier?.financier_id || 0,
                    financier_name: financier?.financier?.financierlang?.financier_name || "",
                    count: filterFilesArr.filter(data => data.doc_id === child.id && data.financier_id === financier?.financier_id)?.length || 0
                }));
                return updatedChildWithFinanciers;
            });
            const flattenedUpdatedChild = updatedChild.flat();
            return {
                ...doc,
                child: flattenedUpdatedChild
            };
        });
        return updatedDocs;
    }
}

export default LeadHelper;