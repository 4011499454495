import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocsUpload/DocumentGallery';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import LeadRemarkHistory from '../lead/component/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../services/refinance/executeGraphQLQuery';
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import FormsyCheckBox from '../../elements/FormsyCheckBox';
import { GET_LOCALITY_LIST_REFINANCE } from '../../../services/refinance/leads.gql';
import Loader from '../../elements/Loader';
import { CONTRACT_SIGNED, CUSTOMER_PERSONAL_DETAIL_FILLED, LOST_STATUS, SENT_TO_FINANCIER } from '../../../config/refinance/constant';

const RefinanceResidentialDetailsForm = (props) => {
  const [residentialDetails, setResidentialDetails] = useState({});
  const [originalDetails, setOriginalDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [localityList, setLocalityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [craCityList, setCraCityList] = useState([]);
  const [currentLocalityList, setCurrentLocalityList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)
  const [isRemarkDisable, setIsRemarkDisabled] = useState(true)
  const [isNextEnable, setIsNextEnabled] = useState(false)
  const { master_data_ucrf, leadDetail, ucrfCityList, ucrfStateList, remarksHistory } = useSelector(({ lead, masterdataucrf }) => ({
    master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
    ucrfCityList: masterdataucrf?.ucrfCityList || [],
    ucrfStateList: masterdataucrf?.ucrfStateList || [],
    remarksHistory: lead.remarksHistory || [],
    lead,
    leadDetail: lead?.ucrfLeadDetail || {},
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { lead_id } = useParams();

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  useEffect(() => {
    if (ucrfCityList?.length) {
      setCityList(ucrfCityList)
      setCraCityList(ucrfCityList)
    }
  }, [ucrfCityList])

  useEffect(() => {
    if (ucrfStateList?.length) {
      setStateList(ucrfStateList)
    }
  }, [ucrfStateList])

  useEffect(() => {
    if (leadDetail?.basic_details) {
      let residentialDetails = {}
      if (leadDetail?.permanent_address && Object.keys(leadDetail?.permanent_address).length) {
        residentialDetails = { ...residentialDetails, ...leadDetail.permanent_address };
      }
      if (leadDetail?.same_as_permanent_address && Object.keys(leadDetail?.same_as_permanent_address).length) {
        residentialDetails = { ...residentialDetails, ...leadDetail.same_as_permanent_address };
      }
      if (leadDetail?.cra_residential_details && Object.keys(leadDetail?.cra_residential_details).length) {
        residentialDetails = { ...residentialDetails, ...leadDetail.cra_residential_details };
      }
      setResidentialDetails(residentialDetails);
      setOriginalDetails(residentialDetails)
      setLoading(true)
      if (residentialDetails.pa_city) {
        getLocalityList(residentialDetails, residentialDetails.pa_city, 'permanent')
      }
      if (residentialDetails.cra_city) {
        getLocalityList(residentialDetails, residentialDetails.cra_city, 'current')
      }
      setLoading(false)
      checkSaveEnable(leadDetail)
    }
    dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: 'WEB' }, client));

  }, [leadDetail]);

  let ALL_OPTIONS = useMemo(() => {
    let options = master_data_ucrf ? { ...master_data_ucrf } : {};
    return options;
  }, [master_data_ucrf]);

  const getLocalityList = (tempResidentialDetails, city_id, type) => {
    executeGraphQLQuery(GET_LOCALITY_LIST_REFINANCE(city_id), client)
      .then(resp => {
        if (resp && resp.data && resp.data.getLocalityList) {
          let locality_list = resp.data.getLocalityList || [];
          if (type == 'current') {
            setCurrentLocalityList(locality_list)
            if (tempResidentialDetails.cra_zipcode == "") {
              let selected_barangay = locality_list.filter(e => e.value == tempResidentialDetails.cra_barangay)
              tempResidentialDetails.cra_zipcode = selected_barangay.length ? selected_barangay[0].zipcode : ""
            }
          } else {
            setLocalityList(locality_list)
            if (tempResidentialDetails.pa_zipcode == "") {
              let selected_barangay = locality_list.filter(e => e.value == tempResidentialDetails.pa_barangay)
              tempResidentialDetails.pa_zipcode = selected_barangay.length ? selected_barangay[0].zipcode : ""
            }
            setResidentialDetails(tempResidentialDetails)
          }

        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false)
      })
  }

  const handleInputChange = event => {
    if (event.target.name) {
      let tempResidentialDetails = { ...residentialDetails }
      tempResidentialDetails[event.target.name] = event.target.value
      if (tempResidentialDetails.pa_sameas_cra == 1) {
        setSameAsPermanentAddress(tempResidentialDetails, '1')
      } else {
        setResidentialDetails(tempResidentialDetails)
      }
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setLoading(true)
      let tempResidentialDetails = { ...residentialDetails }
      if (field_name == "pa_barangay") {
        let selected_barangay = localityList.filter(e => e.value == data.value)
        tempResidentialDetails.pa_zipcode = selected_barangay.length ? selected_barangay[0].zipcode : ""
      }
      if (field_name == "cra_barangay") {
        let selected_barangay = currentLocalityList.filter(e => e.value == data.value)
        tempResidentialDetails.cra_zipcode = selected_barangay.length ? selected_barangay[0].zipcode : ""
      }
      if (field_name == "pa_region") {
        let city_list = [...ucrfCityList]
        city_list = city_list.filter(e => e.state_id == data.value)
        setCityList(city_list)
        if (tempResidentialDetails.pa_sameas_cra == 1) {
          setCraCityList(city_list)
        }
      }
      if (field_name == "cra_region") {
        let cra_city_list = [...ucrfCityList]
        cra_city_list = cra_city_list.filter(e => e.state_id == data.value)
        setCraCityList(cra_city_list)
      }
      tempResidentialDetails[field_name] = data.value
      if (tempResidentialDetails.pa_sameas_cra == 1) {
        setSameAsPermanentAddress(tempResidentialDetails, '1')
      } else {
        if (field_name == "pa_city") {
          getLocalityList(tempResidentialDetails, data.value, 'permanent')
        }
        if (field_name == "cra_city") {
          getLocalityList(tempResidentialDetails, data.value, 'current')
        }
        setResidentialDetails(tempResidentialDetails)
      }
      setLoading(false)
    }
  }

  const handleCheckboxChange = (event) => {
    let formDetails = { ...residentialDetails }
    let inputval = event.target.value;
    if (event.target.checked) {
      setSameAsPermanentAddress(formDetails, inputval)
    } else {
      formDetails = {
        ...formDetails,
        "pa_sameas_cra": '0',
        "cra_house_number": originalDetails.cra_house_number || "",
        "cra_street": originalDetails.cra_street || "",
        "cra_barangay": originalDetails.cra_barangay || "",
        "cra_city": originalDetails.cra_city || "",
        "cra_region": originalDetails.cra_region || "",
        "cra_zipcode": originalDetails.cra_zipcode || ""
      }
      setResidentialDetails({ ...formDetails });
    }
  }

  const setSameAsPermanentAddress = (formDetails, inputval) => {
    formDetails = {
      ...formDetails,
      "pa_sameas_cra": inputval,
      "cra_house_number": formDetails.pa_house_number || "",
      "cra_street": formDetails.pa_street || "",
      "cra_barangay": formDetails?.pa_barangay?.toString() || "",
      "cra_city": formDetails?.pa_city?.toString() || "",
      "cra_region": formDetails?.pa_region?.toString() || "",
      "cra_zipcode": formDetails.pa_zipcode || ""
    }
    let cra_city_list = [...cityList]
    setCraCityList(cra_city_list)
    if (formDetails["pa_city"]) {
      getLocalityList(formDetails, formDetails["pa_city"], 'permanent')
    }
    if (formDetails["cra_city"]) {
      getLocalityList(formDetails, formDetails["cra_city"], 'current')
    }
    setResidentialDetails({ ...formDetails });
  }

  const handleSubmit = () => {
    let lead_id = leadDetail?.lead_id || "";

    let permanentAddressReqBody = {
      pa_house_number: residentialDetails.pa_house_number || "",
      pa_street: residentialDetails.pa_street || "",
      pa_region: residentialDetails?.pa_region ? residentialDetails?.pa_region?.toString() : "",
      pa_city: residentialDetails?.pa_city ? residentialDetails?.pa_city?.toString() : "",
      pa_barangay: residentialDetails?.pa_barangay ? residentialDetails?.pa_barangay?.toString() : "",
      pa_zipcode: residentialDetails.pa_zipcode || "",
      house_type: residentialDetails.house_type || "",
      duration_of_stay: residentialDetails.duration_of_stay || "",
    }
    let sameAsPermanentAddressReqBody = {
      pa_sameas_cra: residentialDetails.pa_sameas_cra == 1 ? true : false
    }
    let currentAddressReqBody = {
      cra_house_number: residentialDetails.cra_house_number || "",
      cra_street: residentialDetails.cra_street || "",
      cra_region: residentialDetails?.cra_region ? residentialDetails?.cra_region.toString() : "",
      cra_city: residentialDetails?.cra_city ? residentialDetails?.cra_city.toString() : "",
      cra_barangay: residentialDetails?.cra_barangay ? residentialDetails?.cra_barangay.toString() : "",
      cra_zipcode: residentialDetails.cra_zipcode || "",
    }
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS,
      variables = {
        lead_data: {
          created_by: logged_user_id || 0,
          updated_by: logged_user_id || 0,
          tab_id: 4,
          sub_tab_id: 0,
          lead_id,
          auto_save: 0,
          permanent_address: permanentAddressReqBody,
          same_as_permanent_address: sameAsPermanentAddressReqBody,
          cra_residential_details: currentAddressReqBody
        },
      };
    setLoading(true)
    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.updateLeadDetails || null;
      if (response && !response.lead_id) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getUCRFLeadDetail(lead_id, client))
      dispatch(getUCRFFormFillStatus({ lead_id }, client))
      setLoading(false)
      navigateToNext();
    })
      .catch(err => {
        setLoading(false)
        toast.error(err.message);
      })
  }

  const navigateToNext = () => {
    navigate(`/refinance/lead-detail/employee-details/${lead_id}`);
  }

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const sectionInfo = { section: "Customer Details", sub_section: "Customer Residential Details" }

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER || e == CONTRACT_SIGNED) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-customer-residential-details")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    let nextEnable = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == CUSTOMER_PERSONAL_DETAIL_FILLED) > -1 ? true : false
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
      setIsNextEnabled(false)
    } else if (nextEnable) {
      setIsNextEnabled(true)
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(true)
      setIsNextEnabled(false)
    }
    if (!markAsFreezed && !user_edit_access && user_page_access) {
      setIsRemarkDisabled(false)
    }
  }

  return (
    <div className="image-form-outer" >
      {isLoading ? <Loader /> : ''}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Customer Details</span>
            <h2>Customer Residential Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
            aria-label="lead form field residential details"
          >
            <div className="row">
              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="pa_house_number"
                  name="pa_house_number"
                  type="tb"
                  value={residentialDetails?.["pa_house_number"] || ""}
                  placeholder=" "
                  label="Permanent House Number"
                  onChange={handleInputChange}
                  validationError="Enter Valid Permanent House Number"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="pa_street"
                  name="pa_street"
                  type="tb"
                  value={residentialDetails?.["pa_street"] || ""}
                  placeholder=" "
                  label="Permanent Street"
                  onChange={handleInputChange}
                  validationError="Enter Valid Permanent Street"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"pa_region"}
                  id={"pa_region"}
                  inputProps={{
                    options: stateList,
                    placeholder: "Permanent Region",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: stateList.length && stateList.filter(({ value }) => value == residentialDetails["pa_region"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["pa_region"]}
                  onChange={(data) => handleSelectChange(data, "pa_region")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"pa_city"}
                  id={"pa_city"}
                  inputProps={{
                    options: cityList,
                    placeholder: "Permanent City",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: cityList.length && cityList.filter(({ value }) => value == residentialDetails["pa_city"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["pa_city"]}
                  onChange={(data) => handleSelectChange(data, "pa_city")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"pa_barangay"}
                  id={"pa_barangay"}
                  inputProps={{
                    options: localityList,
                    placeholder: "Permanent Barangay",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: localityList && localityList.filter(({ value }) => value == residentialDetails["pa_barangay"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["pa_barangay"]}
                  onChange={(data) => handleSelectChange(data, "pa_barangay")}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="pa_zipcode"
                  name="pa_zipcode"
                  type="tb"
                  value={residentialDetails?.["pa_zipcode"] || ""}
                  placeholder=" "
                  label="Permanent Postal Code"
                  onChange={handleInputChange}
                  validationError="Enter Valid Permanent Postal Code"
                  readOnly={(isSaveDisable) ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"house_type"}
                  id={"house_type"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["house_type"],
                    placeholder: "Residence Type",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS?.["house_type"] && ALL_OPTIONS["house_type"].filter(({ value }) => value == residentialDetails["house_type"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["house_type"]}
                  onChange={(data) => handleSelectChange(data, "house_type")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"duration_of_stay"}
                  id={"duration_of_stay"}
                  inputProps={{
                    options: ALL_OPTIONS && ALL_OPTIONS["duration_of_stay"],
                    placeholder: "Duration of Stay",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: ALL_OPTIONS?.["duration_of_stay"] && ALL_OPTIONS["duration_of_stay"].filter(({ value }) => value == residentialDetails["duration_of_stay"]?.toString()),
                    isDisabled: (isSaveDisable)
                  }}
                  required={true}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["duration_of_stay"]}
                  onChange={(data) => handleSelectChange(data, "duration_of_stay")}
                />
              </fieldset>
              {/* field for current address */}
              <fieldset className="form-filed col-md-12" style={{ marginTop: "10px" }}>
                <div className="material">
                  <FormsyCheckBox
                    id="pa_sameas_cra"
                    name="pa_sameas_cra"
                    type="checkbox"
                    className="custom-control-input"
                    value="1"
                    label={"Same as Permanent Address"}
                    checked={residentialDetails['pa_sameas_cra'] == "1" ? true : false}
                    onChange={handleCheckboxChange}
                    disabled={isSaveDisable ? true : false}
                  />

                </div>
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="cra_house_number"
                  name="cra_house_number"
                  type="tb"
                  value={residentialDetails?.["cra_house_number"] || ""}
                  placeholder=" "
                  label="Current House Number"
                  onChange={handleInputChange}
                  validationError="Enter Valid Current House Number"
                  readOnly={(isSaveDisable || residentialDetails['pa_sameas_cra'] == "1") ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="cra_street"
                  name="cra_street"
                  type="tb"
                  value={residentialDetails?.["cra_street"] || ""}
                  placeholder=" "
                  label="Current Street"
                  onChange={handleInputChange}
                  validationError="Enter Valid Current Street"
                  readOnly={(isSaveDisable || residentialDetails['pa_sameas_cra'] == "1") ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"cra_region"}
                  id={"cra_region"}
                  inputProps={{
                    options: stateList,
                    placeholder: "Current Region",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: stateList.length && stateList.filter(({ value }) => value == residentialDetails["cra_region"]?.toString()),
                    isDisabled: (isSaveDisable || residentialDetails['pa_sameas_cra'] == "1")
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["cra_region"]}
                  onChange={(data) => handleSelectChange(data, "cra_region")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"cra_city"}
                  id={"cra_city"}
                  inputProps={{
                    options: craCityList,
                    placeholder: "Current City",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: craCityList.length && craCityList.filter(({ value }) => value == residentialDetails["cra_city"]?.toString()),
                    isDisabled: (isSaveDisable || residentialDetails['pa_sameas_cra'] == "1")
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["cra_city"]}
                  onChange={(data) => handleSelectChange(data, "cra_city")}
                />
              </fieldset>

              <fieldset class="single-select col-md-6">
                <FormsySelect
                  name={"cra_barangay"}
                  id={"cra_barangay"}
                  inputProps={{
                    options: currentLocalityList,
                    placeholder: "Current Barangay",
                    className: "react-select",
                    classNamePrefix: "react-select",
                    value: currentLocalityList.length && currentLocalityList.filter(({ value }) => value == residentialDetails["cra_barangay"]?.toString()),
                    isDisabled: (isSaveDisable || residentialDetails['pa_sameas_cra'] == "1")
                  }}
                  required={false}
                  showAsterisk={true}
                  value={residentialDetails && residentialDetails["cra_barangay"]}
                  onChange={(data) => handleSelectChange(data, "cra_barangay")}
                />
              </fieldset>

              <fieldset class="form-filed col-md-6 ">
                <FormsyInputField
                  id="cra_zipcode"
                  name="cra_zipcode"
                  type="tb"
                  value={residentialDetails?.["cra_zipcode"] || ""}
                  placeholder=" "
                  label="Current Postal Code"
                  onChange={handleInputChange}
                  validationError="Enter Valid Current Postal Code"
                  readOnly={(isSaveDisable || residentialDetails['pa_sameas_cra'] == "1") ? true : false}
                  required={false}
                  showAsterisk={true}
                />
              </fieldset>
            </div>

            <div className="btn-save-remarks">
              {(!allowSubmit || isSaveDisable) ?
                <>{isNextEnable ?
                  <button type="button" className="btn-primary" onClick={() => navigateToNext()}>Next</button>
                  :
                  <span className="span-disabled">
                    Save
                  </span>
                }
                </>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>
              }
              <button aria-label="remarks history" type="button" className="btn-line"
                onClick={showModalViewTimeLine}
              >
                Remarks
                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
              </button>
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow}
            isRemarkDisable={isRemarkDisable} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default RefinanceResidentialDetailsForm;