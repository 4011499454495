import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { SAVE_CUSTOMER_BASIC_DETAILS } from '../../../services/customer.gql';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getLoanDetail } from '../../../store/action/allAction';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, NAME_WITH_LATIN_CHARACTER_REGEX, SUB_STATUS_CODES} from '../../../config/constants';
import { GET_REGION_CITY_LIST } from '../../../services/config.gql';
import { useAiIcon } from '../../hooks/useAiIcon';
import AiIcon from '../../elements/ImageAI';
import { SAVE_VIBER_DATA } from '../../../services/leads.gql';

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");

const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" }
]

const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const BasicDetailsForm = (props) => {
    const [basicDetails, setBasicDetails] = useState({});
    const [orgbasicDetails, setOrgBasicDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [cityList, setCityList] = useState([]);
    const { masterdata, lead, remarksHistory, leadFiles } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadFiles: lead && (lead.leadDetail && (lead.leadDetail.leaddocs || [])),
    }));
    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;

    if(!accessCondition){
      accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = (UserInfo && UserInfo.id) ? UserInfo.id : 0;

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || []
    const viberPersonalData = getContext?.viberData?.get_viber_column?.personal_details || [];
    const { showAiIcon } = useAiIcon(basicDetails, viberPersonalData);
    
    useEffect(() => {
      if (lead && lead.leadDetail && lead.leadDetail.customer) {
        let originalData = { ...lead.leadDetail.customer };
        let updatedData = { ...originalData };

        originalData["dob"] = originalData["dob"] ? dateFormat(originalData["dob"], "dd mmm, yyyy") : "";
        updatedData["dob"] = updatedData["dob"] ? dateFormat(updatedData["dob"], "dd mmm, yyyy") : "";

        if (viberPersonalData) {
          Object.keys(viberPersonalData).forEach(key => {
            if (!updatedData[key] && viberPersonalData[key]) {
              if (key === "dob") {
                updatedData[key] = dateFormat(viberPersonalData[key], "dd mmm, yyyy");
              } else {
                updatedData[key] = viberPersonalData[key];
              }
            }
          });
        }
        setOrgBasicDetails(originalData);
        setBasicDetails(updatedData);
      }
    }, [lead]);

    useEffect(() => {
      getCityList()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    let ALL_OPTIONS = useMemo(() => {
      let options = masterdata ? {...masterdata.data} : {};
      options["gender"] = GENDER_OPTIONS;
      options["birthplace"] = cityList || [];
      return options;
    }, [masterdata,cityList]);

    const getCityList = () => {
      executeGraphQLQuery(GET_REGION_CITY_LIST(), client)
      .then(resp => {
        if (resp && resp.data && resp.data.get_region_city_list) {
          let { city_list } = resp.data.get_region_city_list || [];
          setCityList(city_list);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  
    const handleInputChange = event => {
        if (event.target.id) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [event.target.id]: event.target.value
            }))
        }
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value!== undefined && field_name) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: data.value
            }))
        }
    }

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setBasicDetails(currentValue => ({
                ...currentValue,
                [field_name]: date
            }))
        }
    }

    const handleSelectChange = (data, field_name) => {
      if (data && field_name) {
        setBasicDetails(currentValue => ({
          ...currentValue,
          [field_name]: data.value
        }))
      }
    }

    const handleSubmit = () => {
      let lead_id = lead?.leadDetail?.id || "";
      let reqBody = {
        lead_id: lead_id,
        name: basicDetails.name || null,
        phone: basicDetails.phone,
        alt_phone: basicDetails.alt_phone || null,
        email: basicDetails.email || null,
        dob: basicDetails?.dob ? dateFormat(basicDetails.dob , "yyyy-mm-dd") : null,
        birthplace: Number(basicDetails.birthplace) || null,
        marital_status: basicDetails.marital_status || null,
        gender: basicDetails.gender || null,
        nationality: basicDetails.nationality || null,
        customer_type: Number(basicDetails.customer_type) || null,
        updated_by: user_id || 0
      }
      if (basicDetails && basicDetails.nationality === "other" && basicDetails.other_nationality ) {
        reqBody["other_nationality"] = basicDetails["other_nationality"] || "";
      } else {
        reqBody["other_nationality"] = null;
      }
      let mutation = SAVE_CUSTOMER_BASIC_DETAILS, 
          variables = { basicDetailsInput: reqBody };

      let viberReqBody = {
        lead_id: lead_id,
        parent_object_name: 'personal_details',
        changed_object: {
          name: basicDetails.name?.toString() || null,
          phone: basicDetails.phone?.toString() || null,
          alt_phone: basicDetails.alt_phone?.toString() || null,
          email: basicDetails.email?.toString() || null,
          dob: basicDetails?.dob ? dateFormat(basicDetails.dob, "yyyy-mm-dd") : null,
          birthplace: basicDetails.birthplace?.toString() || null,
          marital_status: basicDetails.marital_status?.toString() || null,
          gender: basicDetails.gender?.toString() || null,
          nationality: basicDetails.nationality?.toString() || null,
          customer_type: basicDetails.customer_type?.toString() || null
        }
      };

      if (basicDetails.nationality === "other" && basicDetails.other_nationality) {
        viberReqBody.changed_object.other_nationality = basicDetails.other_nationality.toString();
      } else {
        viberReqBody.changed_object.other_nationality = null;
      }
      
      let viberMutation = SAVE_VIBER_DATA;
      executeGraphQLMutation(viberMutation, viberReqBody, client)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err));

      executeGraphQLMutation(mutation, variables, client).then(resp => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id,client))
        navigateToNext();
      })
      .catch(err => {
        toast.error(err.message);
      })
    }

    const navigateToNext = () => {
      navigate(`/lead-detail/customer-details/additional-details/${lead_id}`);
    }


    let BasicDetailsForm = useMemo(() => {
      return FORM_FIELDS.map(field => {
        // field.readOnly = basicDetails && basicDetails[field.name] ? true :false
        if (field.name === "dob") {
            field["maxDate"] = DOB_MAX_DATE;
            field["minDate"] = DOB_MIN_DATE;
        }
        return field;
      })
    }, []);

    BasicDetailsForm = useMemo(() => {
      if (basicDetails) {
        // To Show nationality input text box in case of non phillipines
        if (basicDetails.nationality !== "other") {
          return FORM_FIELDS.filter(v => v.id !== "other_nationality");
        } 
      }
      return FORM_FIELDS;
      
    }, [basicDetails]);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
      return lead.leadDetail.lead_status_history.filter(v=>v.sub_status_id===6).length ? true : false;
    }, [lead.leadDetail]);

    const showModalViewTimeLine = () => {
      setViewTimeLine(true);
      setShowremarkform(true);
      document.body.classList.add("overflow-hidden");
    };
  
    const hideModalViewTimeLine = () => {
      setViewTimeLine(false);
      document.body.classList.remove("overflow-hidden");
    };

    const nameWithLatinCharacterValidation = (rule, value) => {
      return NAME_WITH_LATIN_CHARACTER_REGEX.test(value);
    }

    const sectionInfo = {section : "Customer details" , sub_section : "Basic details"}
    
    let { BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED} = SUB_STATUS_CODES
    let isSubStatus3Marked = [BASIC_DETAILS_FILLED, LOAN_QUOTE_SELECTED, CUSTOMER_DETAIL_FILLED, APPLICATION_CREATED].includes(lead.leadDetail?.sub_status_id) ? false : true;
    // console.log(isSubmitDisabled , !allowSubmit , getContext.markAsFreezedNewFlow,"tttttttttttttttttttttt")
    return (
      <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
        <div className="image-tab-panel">
          <DocumentGallery documentCategoryList={documentCategoryList}  />
        </div>
        <div className="form-tab-right-panel">
          <div className="lead-detail-from-outer">
            <div className="lead-form-heading">
              <span className="main-heading">Customer Details</span>
              <h2>Basic Details</h2>
            </div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => setAllowSubmit(true)}
              onInvalid={() => setAllowSubmit(false)}
              onValidSubmit={handleSubmit}
              aria-label="lead form field basic"
            >
              <div className="row">

                {BasicDetailsForm.map((field) => (
                    ["text", "pattern-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={(basicDetails && basicDetails[field.name])}
                        placeholder=" "
                        label={field.label}
                        onChange={
                        field.type === "pattern-format" 
                        ? (data) => handlePatternFormatChange(data, field.name)
                        : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={ field.name === 'name' && { nameWithLatinCharacterValidation } || field.validations}
                        validationError={
                        basicDetails[field.name] ? field.validationError : ""
                        }
                        readOnly={field.readOnly || field.name == "phone" ? true : (orgbasicDetails?.[field.name] && isSubStatus3Marked) ? true : false}
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                    />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                  </fieldset>
                    ) : 
                    field.type === "date-picker" ? (
                    <fieldset class="form-filed col-md-6 ">
                    {/* {console.log(field.name, basicDetails[field.name])} */}
                      <FormsyDatePicker
                          name="dob"
                          selectedDate={(basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])) || ""}
                          placeholder={field.label}
                          maxDate={field.maxDate}
                          value={(basicDetails && basicDetails[field.name] && new Date(basicDetails[field.name])) || ''}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                              basicDetails[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          showAsterisk={field.showAsterisk}
                          hasError={(basicDetails.hasOwnProperty(field.name) && !basicDetails[field.name]) ? true : false}
                          readOnly={(orgbasicDetails?.[field.name] && isSubStatus3Marked)}
                      />
                    {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) :
                    field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) =>  value === (basicDetails[field.name]?.toString())),
                            isDisabled: field.readOnly || (orgbasicDetails?.[field.name] && isSubStatus3Marked)
                        }}
                        required={field.required}
                        showAsterisk={field.showAsterisk}
                        value={basicDetails && basicDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                      {showAiIcon(field.name) ? <AiIcon /> : null}
                    </fieldset>
                    ) : null 
                ))}
                
              </div>

              <div className="btn-save-remarks">
                {isSubmitDisabled || !allowSubmit || getContext.markAsFreezedNewFlow || accessCondition ?
                <span className="span-disabled">
                  Save & Next
                </span>
                :
                <button aria-label="save naxt" type="submit" className="btn-primary">
                  Save & Next
                </button>}
                <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                  <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                </button>
              </div>
            </Formsy>
          </div>
        </div>
        <div className="view-timeline-popup">
            <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
              <div className="modal-header">
                <h2>Remark History</h2>
              </div>
              <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezedNewFlow} 
              accessCondition={accessCondition} sectionInfo = {sectionInfo}/>
            </Modal>
          </div>
        </div>
    );
}
export default BasicDetailsForm;