import React, { useState } from 'react';
import FormFields from './formFields/loanExpected.json';
import Loader from '../elements/Loader';
import FormsySelect from '../elements/FormsySelect';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';

const vehicleFormFields = FormFields.loan_expected_edit_view;

const LoanVehicleDetails = (props) => {
    let { allOptions, getMake, getModel, getVersion, addValidationRule, loanVehicleDetails, setLoanVehicleDetails, getQuotes, resetTabData } = props;
    const [loading, setLoading] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);   

    const handleInputChangeNew = (target_id, event) => {
        let value = event.target.value;
        setLoanVehicleDetails(currentValue => ({
            ...currentValue,
            [target_id]: value
        })) 
        resetTabData();               
    }

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setLoanVehicleDetails(currentValue => ({
                ...currentValue,
                [field_name]: parseInt(data.value || 0)
            }))
        }
        if(field_name !== "out_of_town_charges") resetTabData();
    }

    const handleSelectChange = async(data, field_name, type) => {
        setLoanVehicleDetails(currentValue => ({
            ...currentValue,
            [field_name]: parseInt(data.value)
        }))
        resetTabData();
    }

    const handleMakeChange = (sname, ovalue) => {
        loanVehicleDetails[sname] = ovalue.value;
        if (sname === 'brand') { 
            getModel(ovalue.value); 
            loanVehicleDetails['model'] = null; 
            loanVehicleDetails['variant'] = null;
        } 
        else if (sname === 'model') {
             getVersion(ovalue.value); 
             loanVehicleDetails['variant'] = null;}
        else if(sname === 'vehicle_type'){
            getMake(ovalue.value);
            if(Number(ovalue.value)===6){
                loanVehicleDetails['model'] =  ''; 
            loanVehicleDetails['brand'] =  ''; 
            loanVehicleDetails['variant'] =  '';
            loanVehicleDetails['other_vehicle_type'] =  '';
            }else{
            loanVehicleDetails['model'] =  null; 
            loanVehicleDetails['brand'] =  null; 
            loanVehicleDetails['variant'] =  null;
            }
            loanVehicleDetails[sname] = parseInt(ovalue.value);
        } 
        else if(sname === 'vehicle_usage'){
            props.setAllOptions(prev=>({...prev,vehicle_type: ovalue?.vehicle_type || [] }))
            loanVehicleDetails['vehicle_type'] = 0;
            if(Number(ovalue.value)===6){
                loanVehicleDetails['model'] =  ''; 
            loanVehicleDetails['brand'] =  ''; 
            loanVehicleDetails['variant'] =  '';
            loanVehicleDetails['other_vehicle_type'] =  '';
            }else{
            loanVehicleDetails['model'] =  null; 
            loanVehicleDetails['brand'] =  null; 
            loanVehicleDetails['variant'] =  null;
            }
            loanVehicleDetails[sname] = parseInt(ovalue.value);
        } 
        setLoanVehicleDetails({ ...loanVehicleDetails });
        resetTabData();
    }

  return (
    <div className="image-form-outer">
      {loading ? <Loader /> : null}
      <div className="loan-form">
            <div className="heading-top">
                <span>Vehicle Details</span>
            </div>

            <Formsy
                className="lead-form-filed"
                autoComplete="off"
                onValid={() => setAllowSubmit(true)}
                onInvalid={() => setAllowSubmit(false)}
            >
            <div className="loan-form-filed-scroll">
                <div className="loan-filed-sec">
                    <div className="form-group-bx">
                        <div className="row">
                        {vehicleFormFields?.filter(data => !['dp_amount', 'chattel_percent', 'interest_rate'].includes(data.id))?.map((field) => (
                                ((field?.dependentOn && field?.dependentValue.includes(loanVehicleDetails[field?.dependentOn])) || !field?.dependentOn) && ["text", "number-format"].includes(field.type) ? (
                            <fieldset class="form-filed col-md-6">
                                <FormsyInputField
                                    id={field.id}
                                    name={field.name}
                                    type={field.type}
                                    value={loanVehicleDetails && loanVehicleDetails[field.name]}
                                    placeholder=" "
                                    {...(field.type === "number-format") ? {
                                        thousandsGroupStyle:"thousand",
                                        thousandSeparator: ',',
                                        decimalSeparator: '.'
                                    }: {}}
                                    label={field.label}
                                    onChange={(e) => field.type === "number-format" ? handlePatternFormatChange(e, field.name) : handleInputChangeNew(field.id, e)}
                                    format={field.ApplyNumberformat ? field.format : ""}
                                    validations={field.type === "number-format" && field.name === 'car_fmv' ? 'isNegativeValue' :
                                    field.type === "number-format" && field.name !== 'car_fmv' ? "isGreaterThanFMVValue" : field.validations}
                                    validationError={field.validationError}
                                    required={field.required}
                                    disabled={field.readOnly}
                                />
                            </fieldset>
                                ) : 
                                field.type === "dropdown" ? (
                                    <fieldset class="single-select col-md-6">
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: allOptions?.[field.optionsKey] || [],
                                            placeholder: field.label,
                                            className: "react-select",
                                            classNamePrefix: "react-select",
                                            value: allOptions && allOptions[field.optionsKey] && allOptions[field.optionsKey].filter(({ value }) => Number(value) === loanVehicleDetails?.[field.name]),
                                            isDisabled: field.readOnly
                                        }}
                                        required={field.required}
                                        value={loanVehicleDetails && loanVehicleDetails[field.name]}
                                        onChange={(data) => ["brand", "model", "vehicle_usage","vehicle_type"].includes(field.name) ? handleMakeChange(field.name, data) : handleSelectChange(data, field.name, "edit_view")}
                                    />
                                    </fieldset>
                                ) : null
                            ))}
                        </div>
                    </div>
                    <div class="btn-save-remarks">
                        <button type="button" class="btn-primary" disabled={!allowSubmit} onClick={() => getQuotes()}>Save & Continue</button>
                    </div>
                </div>
            </div>
            </Formsy>
        </div>
    </div>
  );
}
export default LoanVehicleDetails;