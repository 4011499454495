
const masterDataReducer = (state = '', action) => {
    switch (action.type) {
        
        case "GET_MASTER_DATA":
            // console.log(action.payload)
			return {
				...state,
				data: action.payload,
			};
        case "GET_MASTER_DATA_MMV":
            return {
                ...state,
                masterdatammv: action.payload,
            };
        case "GET_MASTER_DATA_MMV_COMPUTATION":
            return {
                ...state,
                masterdatammvcomp: action.payload,
            };
		default:
            return state
    }
}

export default masterDataReducer;
